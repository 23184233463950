<template>
  <div class="number-grow-warp">
    <span
    style="font-size: 79px; font-family: tccm; color: #CC6633; font-weight: 900;"
      ref="numberGrow"
      :data-time="time"
      :class="{ 'number-grow': isShowClass }"
      >{{ value }}</span
    >
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  props: {
    isShowClass: {
      type: Boolean,
      default: true
    },
    time: {
      type: Number,
      default: 2
    },
    value: {
      type: Number,
      default: 0
    },
    type:Number
  },
    data(){
        return{
            numup:true,
        }
    },
  methods: {
    numberGrow(ele) {
      let _this = this
      let step = Math.ceil((_this.value * 10) / (_this.time * 1000))
      //   let step = 10
      let current = 0
      let start = 0
      let t = setInterval(() => {
        start += step
        if (start > _this.value) {
          clearInterval(t)
          start = _this.value
          t = null
        }
        if (current === start) {
          return
        }
        current = start
        // ele.innerHTML = this.numberPutComma(current)
        ele.innerHTML = current
      }, 20)
    },
    numberPutComma(value) {
      if (value === 0) {
        return 0
      }
      if (value.toString().length >= 13) {
        const moneys = value / 1000000000000
        const realVal = parseFloat(moneys).toFixed(2)
        return realVal + '万亿'
      } else if (value.toString().length >= 9) {
        const moneys = value / 100000000
        const realVal = parseFloat(moneys).toFixed(2)
        return realVal + '亿'
      } else if (value.toString().length >= 5) {
        const moneys = value / 10000
        const realVal = parseFloat(moneys).toFixed(2)
        return realVal + '万'
      } else if (Number(value)) {
        let intPartFormat = value
          .toString()
          .replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') // 将整数部分逢三一断
        return intPartFormat
      }
    },
    isInViewPortOfOne (el) {
        const viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight ;//窗口可视高度
        const scrollTop = document.documentElement.scrollTop;//滚动距离
        //判断是否在工作窗口内
    },
    handleScroll(){
        var _this = this;
        let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
        if(_this.numup){
            if(_this.type == 1){
                if(scrollTop>=0){
                    this.numberGrow(this.$refs.numberGrow)
                    _this.numup = false
                }
            }else{
                if(scrollTop>=500){
                    this.numberGrow(this.$refs.numberGrow)
                    _this.numup = false
                }
            }
        }
    },
  },
  mounted() {
    window.addEventListener('scroll',this.handleScroll,true)
  },
  watch: {
    value: {
      deep: true,
      handler(newVal, oldVal) {
        this.numberGrow(this.$refs.numberGrow)
      }
    }
  }
}
</script>

<style>
.number-grow-warp {
  transform: translateZ(0);
  display: inline-block;
}
.number-grow {
  color: rgba(0, 0, 0, 0.85);
  font: 0.25rem/38px HelveticaNeue;
}
</style>