<template>
	<div class="prodsc">
		<Nav></Nav>
		<div class="banner">
			<img src="../assets/images/2.png" class="wow animate__animated animate__slideInDown animate__delay-0.5s">
		</div>
		<!-- 导航和搜索框 -->
		<div class="pro-nav">
			<el-breadcrumb separator-class="el-icon-arrow-right" style="margin-top: 10px;">
				<img src="../assets/images/zhiy.png" style="width: 16px;height: 16px;margin-right: 5px;margin-top: -1.5px;">
				<span style="color: #666;">您的位置是：</span>
			  <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
			  <el-breadcrumb-item :to="{ path: '/product' }">产品中心</el-breadcrumb-item>
			  <el-breadcrumb-item>{{detail.name}}</el-breadcrumb-item>
			</el-breadcrumb>
			<div>
				<!-- <input type="text" value="" placeholder="请输入产品关键字"/>
				<img src="../assets/images/ss.png" class="ss">
				 -->
				 
				<input class="inputinput" type="text" v-model="value" placeholder="请输入产品关键字" @input="input" @keydown="keyss($event)"/>
				<img src="../assets/images/ss.png" class="ss" @click="onConfirm1">
			</div>
		</div>
		
		<!-- 商品详情页 -->
		<div class="goodslist">
			<div class="goodslist-l">
				<Zoom :imageList="imageList"></Zoom>
			</div>
			<div class="goodslist-r">
				<p>{{detail.name}}</p>
				<p><b>{{detail.product}}</b><br>产品型号</p>
				<p><b>{{detail.caliber}}</b><br>口径范围</p>
				<p><b>{{detail.pressure}}</b><br>压力等级</p>
				<p><b>{{detail.hot}}</b><br>工作温度</p>
				<p>主体材料：{{detail.subject}}<br>
				连接方式：{{detail.link}}<br>
				操作方式：{{detail.do}}<br>
				应用行业：{{detail.app}}
				</p>
				<div class="btn">
					<button type="button" @click="goBottom"><img src="../assets/images/order.png">立即获取报价</button>
					<button type="button"><img style="width: 37px;height: 37px;" src="../assets/images/tel.png" ><p><small style="color: #194e7a;margin-bottom: -5px;">咨询热线：</small><span>{{detail.hotline}}</span></p></button>
				</div>
			</div>
			
		</div>
		
		<!-- 商品详细参数 -->
		<div class="goodsdsc">
			<div class="goods-l">
				<div class="nav" style="border-left: 1px solid #EDEDED;">
					<div :class="active == 0?'nav-1 active':'nav-1'" @click="goodsTab(0)"><b>产品详情</b></div>
					<div :class="active == 1?'nav-1 active':'nav-1'" @click="goodsTab(1)"><b>资料下载</b></div>
				</div>
				<div style="border: 1px solid #EDEDED;border-top: none;">
					<div class="txt1">
						<p v-html="detail.content">{{detail.content}}</p>
					</div>
					<!-- <div class="txt2">
						<img src="../assets/images/jishucanshu.png" >
						<img src="../assets/images/jishu2.png" >
					</div> -->
				</div>
				<!-- 切换产品 -->
				<div class="nextprev">
					<div style="text-align: left;" v-if="detail.up" @click="qiehuan(detail.up.id)"><span>上一个产品：</span>{{detail.up.name}}</div>
					<div style="text-align: left;" v-else>暂无上一条</div>
					<div class="xian"></div>
					<div style="text-align: right;" v-if="detail.down" @click="qiehuan(detail.down.id)"><span>下一个产品：</span>{{detail.down.name}}</div>
					<div style="text-align: right;" v-else>暂无下一条</div>
				</div>
			</div>
			
			<div class="goods-r">
				<!-- 产品推荐 -->
				<div class="news-r">
					<div class="news-r-nav">
						<p>
							<img src="../assets/images/heng.png" >
							&ensp;产品推荐&ensp;
							<img src="../assets/images/heng.png" >
						</p>
					</div>
					
					<!-- 推荐商品列表 -->
					<div class="news-item" v-for="(item,index) in hotList" :key="index" @click="go(item.id)">
						<img :src="hotList[index].image" >
						<p align="center">{{hotList[index].name}} {{hotList[index].product}}</p>
					</div>
					
					<!-- 联系我们 -->
					<div class="news-r-nav" style="margin-top: 20px;background-color: #266193;">
						<p>
							<img src="../assets/images/heng.png" >
							&ensp;联系我们&ensp;
							<img src="../assets/images/heng.png" >
						</p>
					</div>
					
					<div class="lianxi">
						<p :title="index.steamAddress">地址：{{index.steamAddress}}</p>
						<p>电话：<span>{{index.steamHotline}}</span></p>
						<p>传真：{{index.steamFax}}</p>
						<p>邮编：{{index.steamPostcode}}</p>
						<p>网址：{{index.steamUrl}}</p>
						<p>邮箱：{{index.steamEmail}}</p>
					</div>
				</div>
			</div>
		</div>
				
		
		<free></free>
		<advantage style="margin-bottom: 3rem;"></advantage>
		<Footer></Footer>
	</div>
</template>

<script>
import $ from "jquery"
import Nav from "../components/nav.vue"
import Footer from "../components/footer.vue"
import free from "../components/free.vue"
import advantage from "../components/advantage.vue"
import Zoom from "../components/Zoom.vue"
export default{
	data(){
		return{
			hotList:[],// 推荐产品列表
			detail:[],//详情页数据
			imageList:[],// 详情页图片
			value:'',// 搜索内容
			active:0,
			index:'',
		}
	},
	components:{
		Nav,
		Footer,
		free,
		advantage,
		Zoom
	},
	created() {
		this.getHot();
		this.getDetail();
		this.getIndex();
	},
	methods:{
		getIndex(){
			this.$http.get("https://admin.ruikefamen.com/api/index/index")
			.then(res=>{
				if(res.data.code == 1){
					this.index = res.data.data
				}
			})
		},
		goodsTab(i){
			this.active = i
		},
		go(id){
			this.getDetail(id)
			$("html,body").animate({"scrollTop":"0"},800)
		},
		qiehuan(id){
			this.getDetail(id)
			$("html,body").animate({"scrollTop":"0"},800)
		},
		goBottom(){
			$("html,body").animate({"scrollTop":"3300"},800)
		},
		keyss(e){//键盘事件
		   if(e.keyCode == 13){
				let value = sessionStorage.getItem("value")
				 this.$router.push({path:'/product',query:{value:value}});
				// this.producList(value)
		   }
		},
		onConfirm1() { // 点击事件
			let value = sessionStorage.getItem("value")
			this.$router.push({path:'/product',query:{value:value}});
			// this.producList(value)
		},
		input(){
			sessionStorage.setItem('value',this.value)
		},
		getHot(){
			this.$http.get('https://admin.ruikefamen.com/api/products/hot_list')
			.then(res=>{
				this.hotList = res.data.data
			})
		},
		getDetail(id){
			var _this = this;
			_this.$apis.get(_this.$apis.detail,
				{params:{id:id?id:_this.$route.query.id}}
			).then(res=>{
				this.detail = res.data.data
				if(res.data.data.video_file){
					res.data.data.images.unshift(res.data.data.video_file)
				}
				this.imageList = res.data.data.images
			})
		}
	}
}
</script>

<style lang="less" scoped="scoped">
	.prodsc{
		width: 100%;
		.banner{
			position: relative;
			width: 100%;
			height: 500px;
			text-align: center;
			background: url(../assets/images/ProductCenter.png) no-repeat center center / cover;
			img{
				height: 90px;
				width: auto;
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				top: 100px;
				margin: auto;
			}
		}
		// 导航和搜索框
		.pro-nav{
			width: 1400px;
			margin: 0 auto;
			margin-top: 23px;
			display: flex;
			justify-content: space-between;
			padding-bottom: 32px;
			div{
				display: flex;
				input{
					width: 340px;
					height: 40px;
					border: none;
					outline-style: none;
					border: 1px solid #999;
					text-indent: 1em;
				}
				.ss{
					width: 45px;
					background-color: #194e7a;
					display: block;
					padding: 0.281112rem 0.337335rem;
					cursor: pointer;
				}	
			}
		}
	
		// 商品详情页
		.goodslist{
			width: 1400px;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			.goodslist-l{
				width: 534px;
				height: 100%;
				/deep/ .list{
					width: 100%;
					display: flex;
					// justify-content: flex-start;
					li{
						background-color: #fff;
					}	
					
				}
				.goodslist-l-top{
					width: 100%;
					height: 9.5rem;
					overflow: hidden;
					border: 1px solid #ccc;
					img{
						width: 100%;
						height: auto;
					}
				}
			}
			.goodslist-l-bop{
				height: 1.9rem;
				// display: flex;
				// justify-content: space-between;
				img{
					margin-left: 2px;
					width: auto;
					height: 1.9rem;
					background-color: red;
					// margin-right: 5px;
					margin-top: 8px;
					border: 1px solid #ccc;
				}
				img:nth-child(1){
					border: 1px solid #cc6633;
				}
			}
			.goodslist-r{
				margin-left: 59px;
				flex: 1;
				display: flex;
				flex-wrap: wrap;
				align-content: flex-start;
				p:nth-child(1){
					width: 100%;
					margin: 23px 0;
					font-size: 35px;
					color: #333;
				}
				p:nth-child(2),p:nth-child(3),p:nth-child(4),p:nth-child(5){
					color: #333;
					width: 25%;
					height: 127px;
					font-size: 18px;
					text-align: center;
					display: inline-block;
					display: flex;
					flex-direction: column;
					justify-content: center;
					border: 1px solid #e6e6e6;
					border-left:none ;
				}
				p:nth-child(5){
					border-right: none;
				}
				p:nth-child(6){
					width: 100%;
					margin-top: 0.5rem;
					font-size: 16px;
					line-height: 48px;
					color: #333;
				}
				.btn{
					display: flex;
					justify-content: space-between;
					button{
						height: 91px;
						padding: 0 41px;
						border: none;
						display: flex;
						justify-content: center;
						align-items: center;
						outline-style: none;
						cursor: pointer;
						overflow: hidden;
						img{
							width: 32px;
							margin-right: 14px;
						}
					}
					button:nth-child(1){
						padding: 0 20px;
						background-color: #cc6633;
						font-size: 30px;
						color: #fff;
						margin-top: 44px;
					}
					button:nth-child(2){
						height: 91px;
						padding: 0 35px;
						margin-left: 20px;
						border: 3px solid #194e7a;
						margin-top: 44px;
						background: #fff;
						p{
							font-size: 30px;
							color: #194e7a;
							text-align: left;
							font-weight: 900;
							border: none;
							width: 88%;
							small{font-weight: normal;font-size: 18px;}
							span{
								font-family: ar;
							}
						}
					}
				}
				
			}
		}
	
		// 商品详细参数
		.goodsdsc{
			width: 1400px;
			margin: 0 auto;
			margin-top: 88px;
			display: flex;
			justify-content: space-between;
			.goods-l{
				width: 1031px;
				margin-right: 34px;
				.nav{
					width: 100%;
					background-color: #ededed;
					display: flex;
					.nav-1{
						width: 250px;
						height: 100%;
						background-color: #fff;
						border-top: 2.5px solid #ededed;
						border-right: 2.5px solid #ededed;
						font-size: 22px;
						line-height: 64px;
						text-align: center;
						cursor: pointer;
					}
					.active{
						border: none;
						border-top: 2.5px solid #cc6633;
						border-right: 2.5px solid #ededed;
					}
				}
				.txt1{
					font-size: 18px;
					line-height: 36px;
					padding: 55px 25px;
				}
				.txt2{
					width: 100%;
					img{
						width: 100%;
						height: auto;
					}
				}
				.nextprev{
					height: 50px;
					display: flex;
					margin: 50px 0;
					font-size: 16px;
					justify-content: space-between;
					border-top: 1px solid #e6e6e6;
					border-bottom: 1px solid #e6e6e6;
					line-height: 50px;
					color: #333;
					div{
						width: 600px;
						cursor:pointer;
						border: none;
					}
					.xian{
						width: 1px;
						height: 100%;
						background: #e6e6e6;
					}
					div span{
						color: #999;
					}
				}
			}
			.goods-r{
				flex: 1;
		.news-r{
			width: 335px;
			.news-r-nav{
				height: 64px;
				background-color: #194E7A;
				display: flex;
				justify-content: center;
				p{
					font-size: 18px;
					color: #fff;
					font-weight: 900;
					line-height: 64px;
				}
				img{
					width: 27px;
					vertical-align: middle;
				}
			}
			// 右侧推荐商品
			.news-item{
				width: 335px;
				height: 301px;
				margin-top: 20px;
				background-color: #fff;
				position: relative;
				overflow: hidden;
				border : 1px solid #E6E6E6;
				cursor:pointer;
				img{
					display: block;
					width: 285px;
					height: 252px;
					margin: 0 auto;
				}
				p:nth-child(2){
					width: 335px;
					padding: 0 30px;
					font-size: 18px;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}
			.lianxi{
				width: 100%;
				background-color: #fff;
				border : 1px solid #E3E3E3;
				line-height: 0.843338rem;
				padding: 0.618448rem;
				margin-top: 0.421669rem;
				color: #666;
				p{
					font-size: 14px;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				}
				p span{
					font-size: 30px;
					color: #666;
				}
			}
			
		}
				
			}
		}
	}
	.inputinput{
		color: #333;
	}
</style>
