<template>
  <baidu-map class="map" :center="{lng: 114.172381, lat: 34.396526}" :zoom="15" :scroll-wheel-zoom="true">
    <bm-marker :position="{lng: 114.172381, lat: 34.402244}" :dragging="true" @click="infoWindowOpen">
      <bm-info-window class="mapDiv" :show="show" @close="infoWindowClose" @open="infoWindowOpen">开封瑞科阀门有限公司</bm-info-window>
    </bm-marker>
  </baidu-map>
</template>

<style type="text/css">
	.map{
		width: 100%;
		height: 500px;
	}
  .mapDiv{
    width: 241px;
    height: 55px;
    line-height: 55px;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
  }
  .BMap_bubble_content div:nth-child(1){
    padding: 0 !important;
  }
  #detailDiv{
    height: 35px !important;
    margin-top: -15px;
  }
  .BMap_Marker{
    width: 10px !important;
    height: 13px !important;
    position: relative;
  }
  .BMap_Marker:nth-child(1)::after{
    content: '';
    display: block;
    width: 100px;
    height: 60px;
    white-space: nowrap;
    background: #f5f3f0;
    background-image: url(../assets/images/dingweishop.png);
    background-position: center;
    background-repeat: no-repeat;
    font-size: 12px;
    position: absolute;
    left:-35px;

  }
</style>
<script>
export default {
  data () {
    return {
      show: true
    }
  },
  methods: {
    infoWindowClose () {
      this.show = false
    },
    infoWindowOpen () {
      this.show = true
    }
  }
}
</script>