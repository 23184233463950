<template>
  <div>
      <div class="box">
          <video-player class="video-player vjs-custom-skin"
              v-if="imgurl.indexOf('.mp4')>0"
							ref='videoPlayer'
							:playsinline="true"
							:options="vadioData"
							@play="onPlayerPlay($event)"
							@pause="onPlayerPause($event)"
							>
						</video-player>
          <pic-zoom v-if="imgurl.indexOf('.mp4')<0" :url="imgurl?imgurl:image[0]" :scale="3" class="bigurl"></pic-zoom>
      </div>

      <div class="list">
        <!-- <div class="leftBtn" @click="swiper('left')"></div>
        <div class="rightBtn" @click="swiper('right')"></div> -->
        <div class="listDiv">
          <div v-for="(item,index) in image" :key="index" class="itemDiv" :class="{active:index==imgIndex}" @mouseenter="changeImage(index)">
            <video id="videoPlay" v-if="item.indexOf('.mp4')>0" :src="item"></video>
            <img class="imgHover" v-else :src="item" />
          </div>
        </div>
      </div>
  </div>
</template>
<script>
import PicZoom from "vue-piczoom"
import $ from 'jquery'
  export default {
    name: "FooterShow",
    data(){
      return{
          imgIndex:0,
          imgurl:'',
          imagesList:[],
          vadioUrl:'',
          vadioImage:'',
          vadioData:'',

          listLength:'',
        }
      },
		props:{
			imageList: {
			  type: Array
      }
		},
    components:{
      PicZoom
    },
		computed:{
			image(){
        var that = this
        console.log('更新')
        if(this.imageList[0] && this.imageList[0].indexOf('.mp4')>0){
          this.imgurl = this.imageList[0]
        }
        that.vadioData = {
          //播放速度
          playbackRates: [0.5, 1.0, 1.5, 2.0], 
          //如果true,浏览器准备好时开始回放。
          autoplay: false, 
          // 默认情况下将会消除任何音频。
          muted: false, 
          // 导致视频一结束就重新开始。
          loop: false, 
          // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          preload: 'auto', 
          language: 'zh-CN',
          // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          aspectRatio: '4:3',
          // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          fluid: true,
          sources: [{
            //类型
            type: "video/mp4",
            //url地址
            src: this.imageList[0]
          }],
          //你的封面地址
          poster: '', 
          //允许覆盖Video.js无法播放媒体源时显示的默认信息。
          notSupportedMessage: '此视频暂无法播放，请稍后再试',
          controlBar: {
            timeDivider: true,
            durationDisplay: true,
            remainingTimeDisplay: false,
            //全屏按钮
            fullscreenToggle: true  
          }
        }
        this.listLength = this.imageList.length
				return this.imageList 
			},
      player(){
				return this.$refs.videoPlayer.player; 
			}
		},
		mounted() {
			this.imagesList = this.imageList
    },
    methods:{
      swiper(val){
        if(val == 'left'){
          console.log(Math.abs($('.listDiv').position().left),(this.listLength-6)*95+30)
          if(Math.abs($('.listDiv').position().left)<=((this.listLength-6)*95+30)){
            $('.listDiv').animate({
              left:'-=95px'
            })
          }
        }else if(val == 'right'){
          if($('.listDiv').position().left<30){
            $('.listDiv').animate({
              left:'+=95px'
            })
          }
        }
      },
      changeImage:function (index) {  
        this.imgurl=this.imageList[index];
        this.imgIndex = index;
        console.log(this.imgurl)
        if(this.imgurl.indexOf('.mp4')<0){
          this.$refs.videoPlayer.player.pause()
        }
      }
    }
  }
</script>

<style scoped lang="less">
.imgHover:hover{
  cursor: pointer;
}
#videoPlay{
  width: 100%;
  height: 100%;
}
.box{
  width: 536px;
  height: 481px;
  overflow: hidden;
  border: 1px solid #eee;
  position: relative;
  .bigurl{
    width: 536px;
    height: 481px;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 899;
  }
}
.list{
  width: 400px;
  height: 80px;
  margin-top: 10px;
  overflow: hidden;
  position: relative;
  .listDiv{
    white-space: nowrap;
    position: absolute;
  }
  .leftBtn{
    width: 20px;
    height: 80px;
    background: seagreen;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 9;
  }
  .rightBtn{
    width: 20px;
    height: 80px;
    background: seagreen;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: 9;
  }
  .itemDiv{
    width: 85px;
    height: 77px;
    display: inline-block;
    border: 1px solid #fff;
    img{
      width: 83px;
      height: 75px;
      display: inline-block;
    }
  }
}
.active{
  border: 1px solid #cc6633 !important;
}
/deep/ .vjs-tech,/deep/ .video-js{
  height: 481px !important;
}
</style>
