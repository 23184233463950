<template>
	<div>
		<Nav></Nav>
		<SwiPer></SwiPer>
		<div class="gork">
			<div class="wow animate__animated animate__slideInUp animate__delay-0.5s">
				<el-row type="flex" justify="center">
					<el-col>
						<div class="gork-title">
							<span class="wow slideInLeft">走进</span>
							<span>瑞科</span>
							<p class="fontFZ">ABOUT RUKE</p>
						</div>
					</el-col>
				</el-row>
				<div type="flex" justify="center">
					<div class="gork-dsc">
						<div @click="gojeishao" style="cursor:pointer;">
							<p style="text-indent:2em;" v-html="index.content" align="left">{{index.content}}&emsp;&empty;</p>
							<div> <span> ...</span> [详情]</div>
						</div>
					</div>
				</div>
				<div class="numList1">
					<div class="gork-info" v-for="(item,index) in rukeData" :key="index">
						<div class="gork-info-box">
							<p><num-up :value="item.num"></num-up><span>+</span></p>
							<p>{{item.txt1}}</p>
							<p>{{item.dsc}}</p>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 产品中心 -->
		<div class="product">
			<!-- 标题 -->
			<div class="wow animate__animated animate__slideInUp animate__delay-0.5s">
				<div type="flex" justify="center">
					<div>
						<div class="product-title">
							<span>产品</span>
							<span>中心</span>
							<p class="fontFZ">PRODUCT CENTER</p>
						</div>
					</div>
				</div>
				<div class="proList">
					<div class="proListTitle">
						<div :class="{active:index==cartIndex}" class="proListItem" v-for="(item,index) in cartList"
							@click="cartShow(index)" :key='index'>
							<p><img :src="index==cartIndex ? item.onimage : item.image" alt="球阀logo"></p>
							<p>{{item.name}}</p>
							<p class="p2">{{item.dsc}}</p>
							<p class="p3">{{item.nickname}}</p>
							<span v-if="cartList[index+1]" class="rightBorder"></span>
						</div>
					</div>
				</div>
				<!-- 产品列表 -->
				<div class="product-list wow animate__animated animate__slideInUp animate__delay-0.5s">
					<div class="listDiv" v-if="cartList.length>0">
						<div class="product-list-info" v-for="(item,index) in cartList[cartIndex].productlist" :key="index">
							<router-link tag="div" :to="{path:'/prodsc',query: {id:item.id}}" class="pro-zzc">
								<div style="line-height: 35px;">
									<p>{{item.name}}</p>
									<p></p>	
								</div>
								<div class="chakan">
									<p>查看详情<span>&gt;</span></p>
								</div>
							</router-link>
							<router-link :to="{path:'/prodsc',query:{id:item.id}}" tag="div" class="product-list-box">
								<img :src="item.image"/>
								<p> {{item.name}}</p>
							</router-link>
						</div>
					</div>
				</div>

				<!-- 进入产品中心 -->
				<div class="goproCenter wow animate__animated animate__slideInUp animate__delay-0.5s">
					<router-link to="/product">
						<!-- <span>进入产品中心</span>
						<img src="../assets/images/youyou.png" alt=""> -->
						<img class="gocpzx" src="../assets/images/go.png" alt="">
					</router-link>
				</div>
			</div>

		</div>

		<!-- 资质专利 -->
		<div class="patent">
			<el-row type="flex" justify="center">
				<div>
					<div class="patent-title wow animate__animated animate__slideInUp animate__delay-0.5s">
						<span>资质</span>
						<span>专利</span>
						<p class="fontFZ">QUALIFICATION PATENT</p>
					</div>
				</div>
			</el-row>
			<div v-if="zizhiArr" class="patent-main">
				<!-- 轮播图 -->
				<div class="chakanbox" v-if="pupopImg">
					<div class="chakanzz">
						<img :src="pupopImg" style="width: 100%;height: 100%;">
						<i class="el-icon-close" @click="closeBig"></i>
					</div>	
				</div>
				<el-carousel class="swiper-container" v-if="zizhiArr" interval="5000" arrow="never" ref="swiper1" indicator-position="none">
					<div class="leftBtn" @click="swiperNext('left')"></div>
					<div class="rightBtn" @click="swiperNext('right')"></div>
					<el-carousel-item v-for="(item,index) in zizhiArr" :key="index" class="swiperItem">
						<div class="swiper-slide" @click='openBig(index,itemm.image)' v-for="(itemm,indexx) in item" :key="indexx" style="position: relative;">
							<div class="lbzzc">
								<p>{{itemm.title}}</p>
								<router-link to="" class="chakan">
									<p>查看详情<span>&gt;</span></p>
								</router-link>
							</div>
							<img :src="itemm.slimage" style="width: 100%;height: 100%;">
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
		</div>
		
		<!-- 新闻资讯 -->
		<div class="news">
			<div>
				<el-row type="flex" justify="center">
					<!-- gutter栅格距离  默认为0 -->
					<div>
						<div class="news-title wow animate__animated animate__slideInUp animate__delay-0.5s">
							<span>新闻</span>
							<span>资讯</span>
							<p class="fontFZ">NEWS</p>
						</div>
					</div>
				</el-row>
			</div>

			<div class="news-nav wow animate__animated animate__slideInUp animate__delay-0.5s">
				<div v-for="(item,index) in news_list" :key="index" :class="{active:index == newsIndex}" style="cursor: pointer;" @click="newsId(index)">{{item.name}}</div>
			</div>

			<!-- 新闻资讯内容 -->
			<div class="news-main" v-if="news_list.length>0">
				<div class="news-main-l">
					<div class="left" @click="falst(news_list[newsIndex].newslist[0].id)" v-if="news_list[newsIndex].newslist.length>0">
						<img v-if="news_list[newsIndex].newslist.length>0" :src="news_list[newsIndex].newslist[0].eximage" alt="新闻资讯图" class="wow animate__animated animate__fadeInLeft animate__delay-0.5s">
						<router-link v-if="news_list[newsIndex].newslist.length>0" :to="{path:'/newsdsc',query:{id:news_list[newsIndex].newslist[0].id}}" tag="div" class="wow animate__animated animate__fadeInLeft animate__delay-0.5s" style="cursor: pointer;">
							<div class="leftItem">
								<div class="num">
									<span>{{news_list[newsIndex].newslist[0].dataTime[2]}}</span>
									<p>{{news_list[newsIndex].newslist[0].dataTime[0]}}.{{news_list[newsIndex].newslist[0].dataTime[1]}}</p>
								</div>
								<div class="txt">
									<p>{{news_list[newsIndex].newslist[0].title}}</p>
									<p v-html="news_list[newsIndex].newslist[0].content1"></p>
									<div class="xiangqing"><span>...</span>[详情]</div>
								</div>
							</div>
						</router-link>
					</div>
					<div style="width: 30px;" v-if="news_list[newsIndex].newslist.length>0"></div>
					<div class="zanwu" v-if="news_list[newsIndex].newslist.length<=0">暂无新闻动态...</div>
					<div class="right" v-if="news_list[newsIndex].newslist.length>1">
						<router-link v-for="(item,index) in news_list[newsIndex].newslist" :key="index" :to="{path:'/newsdsc',query:{id:item.id}}" tag="div" class="wow animate__animated animate__fadeInLeft animate__delay-0.5s" style="cursor: pointer;">
							<div class="rightItem" v-if="index!=0">
								<div class="num">
									<span>{{item.dataTime[2]}}</span>
									<p>{{item.dataTime[0]}}.{{(item.dataTime[1])}}</p>
								</div>
								<div class="txt">
									<p>{{item.title}}</p>
									<p v-html="item.content">{{item.content}}</p>
									<div class="xiangqing"><span>...</span>[详情]</div>
								</div>
							</div>
						</router-link>
						<div v-if="news_list[newsIndex].newslist.length<=0" style="line-height: 40px; color: #999;padding-left: 50px;font-size: 20px;">暂无新闻动态</div>
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="goproCenter wow animate__animated animate__slideInUp animate__delay-0.5s">
			<router-link to="/about?i=4" class="gonews">
				<span>更多资讯</span>
				<img src="../assets/images/youyou.png" alt="">
			</router-link>
		</div> -->

		<!-- 企业优势 -->
		<Advant class="wow animate__animated animate__slideInUp animate__delay-0.5s"></Advant>
		
		
		<!-- 工程案例 -->
		<div class="case wow animate__animated animate__slideInUp animate__delay-0.5s">
			<div>
				<div type="flex" justify="center">
					<div>
						<div class="case-title">
							<span>工程</span>
							<span>案例</span>
							<p class="fontFZ">ENGINEERING CASE</p>
						</div>
					</div>
				</div>
			</div>
			<div class="case-main">
				<div class="case-main-l">
					<img :src="casetype[0].image">
					<router-link :to="{path:'/case',query:{id:casetype[0].id}}" tag="div" class="txt wow animate__animated animate__fadeInLeft animate__delay-0.5s">
						<p><b style="color: #fff;">{{casetype[0].name}}</b></p>
						<p v-html="casetype[0].content">{{casetype[0].content}}</p>
					</router-link>
				</div>
				<div class="case-main-r">
					<router-link :to="{path:'/case',query:{id:casetype[1].id}}" tag="div" >
						<img :src="casetype[1].image">
						<div class="txt wow animate__animated animate__fadeInRight animate__delay-0.5s">
							<p>{{casetype[1].name}}</p>
							<p v-html="casetype[1].content">{{casetype[1].content}}</p>
						</div>
					</router-link>
					<router-link to="/case" tag="div">
						<div class="txt wow animate__animated animate__fadeInRight animate__delay-0.5s">
							<p>VIEW ALL CASES</p>
							<p>查看全部案例</p>
							<p><router-link to="/case" tag="button" type="button" >MORE<span>&gt;</span></router-link></p>
						</div>
					</router-link>
					<router-link :to="{path:'/case',query:{id:casetype[2].id}}" tag="div">
						<div class="txt wow animate__animated animate__fadeInRight animate__delay-0.5s" style="">
							<p>{{casetype[2].name}}</p>
							<p v-html="casetype[2].content">{{casetype[1].content}}</p>
						</div>
					</router-link>
					<router-link :to="{path:'/case',query:{id:casetype[3].id}}" tag="div">
						<img :src="casetype[3].image">
						<div class="txt wow animate__animated animate__fadeInRight animate__delay-0.5s">
							<p>{{casetype[3].name}}</p>
							<p v-html="casetype[3].content">{{casetype[1].content}}</p>
						</div>
					</router-link>
				</div>
			</div>
		</div>
		
		<Free class="wow animate__animated animate__slideInUp animate__delay-0.5s"></Free>
		
		<Footer></Footer>
		<div class="pupop1">
			<div class="inputDiv">
				<img class="guanbi" @click="guanbi()" src="../assets/images/guanbiWhite.png" alt="">
				<img :src="pupopImg" alt="">
			</div>
		</div>
	</div>
</template>
<script>
	import Nav from "../components/nav.vue"
	import SwiPer from "../components/swiper.vue"
	import Footer from "../components/footer.vue"
	import Free from "../components/free.vue"
	import Advant from "../components/advantage.vue"
	import numUp from "../components/numUp.vue"
	import WOW from 'wowjs'
	import '../assets/css/swiper-3.4.2.min.css'
	import $ from "jquery"
	 import Swiper from "swiper";
	export default {
		data() {
			return {
				index:[],
				scroll1:'',
				cartList: [],// 产品列表
				show:false,
				cartIndex: 0,
				newsIndex:0,
				news_list:[],
				casetype:[],
				activeName: 'second',
				rukeData: [
					{num: 0,txt1: '10余年阀门经验',dsc: '诚实守信 永续经营'},
					{num: 0,txt1: '专利技术',dsc: '自主专利 彰显实力'},
					{num: 0,txt1: '加工检测装备',dsc: '装备齐全 奠定基础'},
					{num: 0,txt1: '合作客户',dsc: '客户满意 长期合作'}
				],
				pupopIndex:'',
				zizhiArr:'',
				pupopImg:'',

				//富文本溢出处理
				isLength:false,//判断内容是否过长
				schoolInfo:"",//记录省略之后的内容
				schoolData:"",//记录源数据
				zizhi:'',

				vueSwiper:'',

				arrCompute:'',
			}
		},
		components: {
			numUp,
			Nav,
			Footer,
			Free,
			Advant,
			SwiPer
		},
		created() {
			this.getIndex();
		},
		methods: {
			falst(id){
				this.$router.push({
					path:'/newsdsc',
					query:{
						id:id,
					}
				})
			},
			getKeyword(){
				this.$http.get("https://admin.ruikefamen.com/api/index/index")
				.then(res=>{
				})
			},
			guanbi(){
				$('.pupop1').fadeToggle()
				$(document).unbind("scroll.unable");
			},
			handleClose(){
				this.show = false
			},
			swiperNext(val){
				if(val === 'right') {
						this.$refs.swiper1.next()
					} else {
						this.$refs.swiper1.prev()
				}
			},
			gojeishao(){
				this.$router.push({
					path:'/about',
				})
			},
			openBig(index,url){// 查看大图
				this.pupopImg = url
				this.show = true
				$('.pupop1').fadeToggle()
				var top=$(document).scrollTop();
					$(document).on('scroll.unable',function (e){
					$(document).scrollTop(top);
				})
			},
			closeBig(){// 关上大图
				$(".chakanbox").css('display','none')
			},
			handleClick(tab, event) {
			},
			cartShow(index) {
				this.cartIndex = index

				
			},
			newsId(index){
				this.newsIndex = index
			},
			//数组循环链接为4的倍数数组
			arrUp(){
				var _this = this
				let step = _this.arrCompute.length%4
				if(step>0){
					_this.arrCompute = _this.arrCompute.concat(_this.zizhi)
					_this.arrUp()
				}else{
					_this.zizhiArr = _this.group(_this.arrCompute,4)
					return;
				}
			},
			//数组分割
			group(array, subGroupLength) {
				let index = 0;
				let newArray = [];
				while(index < array.length) {                                   
					newArray.push(array.slice(index, index += subGroupLength));
				}
				return newArray;
			},
			//内容超过200个字符显示省略号
			richTextFormat(value) {
				value = value.replace(/<\/?.+?>/g,'')
				value = value.replace(/\s+/g,'')
				if (value.length > 200) {
					this.isLength = true;	
					return value.slice(0, 250) + "...";
				}else{
					this.isLength = false;
				}
				return value;
			},
			newsRichTextFormat(value) {
				value = value.replace(/<\/?.+?>/g,'')
				value = value.replace(/\s+/g,'')
				if (value.length > 200) {
					this.isLength = true;	
					return value.slice(0, 100) + "...";
				}else{
					this.isLength = false;
				}
				return value;
			},
			newsRichTextFormat1(value) {
				value = value.replace(/<\/?.+?>/g,'')
				value = value.replace(/\s+/g,'')
				if (value.length > 300) {
					this.isLength = true;	
					return value.slice(0, 90) + "...";
				}else{
					this.isLength = false;
				}
				return value;
			},
			//秒转时间
			timeData(s){
				let date = new Date(s*1000).toLocaleString()
				let dateTime = date.split(' ')[0].split('/')
				return dateTime;
			},
			getIndex(){
				var _this = this
				_this.$apis.get(_this.$apis.index)
				.then(res=>{
				  if(res.data.code == 1){
					_this.index = res.data.data 
					this.schoolData = res.data.data.content;
					this.schoolInfo = this.richTextFormat(this.schoolData)
					_this.news_list = res.data.data.news_list// 新闻资讯
					for(let i=0;i<res.data.data.news_list.length;i++){
						for(let j=0;j<res.data.data.news_list[i].newslist.length;j++){
							res.data.data.news_list[i].newslist[j].dataTime = res.data.data.news_list[i].newslist[j].createtime.split('-')
							if(j == 0){
								res.data.data.news_list[i].newslist[j].content1 = _this.newsRichTextFormat1(res.data.data.news_list[i].newslist[j].content)
								res.data.data.news_list[i].newslist[j].content = _this.newsRichTextFormat(res.data.data.news_list[i].newslist[j].content)
							}else{
								res.data.data.news_list[i].newslist[j].content = _this.newsRichTextFormat(res.data.data.news_list[i].newslist[j].content)
							}
							
						}
					}



					_this.cartList = res.data.data.category_product
					_this.casetype = res.data.data.case_type
					_this.zizhi = res.data.data.patent_list
					console.log('资质',res.data.data.patent_list)
					_this.arrCompute = res.data.data.patent_list
					_this.arrUp()
					this.newsIndex = 0
					_this.rukeData[0].num = res.data.data.famenExp
					_this.rukeData[1].num = res.data.data.Patented
					_this.rukeData[2].num = res.data.data.Testing
					_this.rukeData[3].num = res.data.data.Customers
				  }
				})
			}

		},
		mounted() {
			this.getKeyword()
			new WOW.WOW().init();
			new Swiper ('.swiper-container', {
				loop: true,
				// 如果需要分页器
				pagination: '.swiper-pagination',
				// 如果需要前进后退按钮
				nextButton: '.swiper-button-next',
				prevButton: '.swiper-button-prev',
				// 如果需要滚动条
				// scrollbar: '.swiper-scrollbar',
				//如果需要自动切换海报
				autoplay: {
				  delay: 1000,//时间 毫秒
				  disableOnInteraction: false,//用户操作之后是否停止自动轮播默认true
				},
			})
		},
	}
</script>

<style lang="less" scoped="scoped">
.pupop1{
	width: 100vw;
	height: 100vh;
	display: none;
	background-color: rgba(0, 0, 0, 0.65);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	.inputDiv{
		margin: 0 auto;
		text-align: center;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		.guanbi{
			width: 30px;
			position: absolute;
			top: 10px;
			right: 10px;
			cursor: pointer;
		}
	}
}
.swiper-container111 {
    width: 600px;
    height: 300px;
}  
.gonews {
	width: 155px;
	height: 58px;
	margin: 55px auto;
	background-color: #cc6633;
	line-height: 58px;
	text-align: center;
	color: #fff;
	font-size: 18px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0	 30px ;
	span {
		font-family: 'SimSun';
		color: #fff;
	}
	img{
		width: 12px;
	}
}
	// 轮播图
	.el-carousel__item h3 {
		color: #475669;
		font-size: 0.259259rem;
		opacity: 0.75;
		line-height: 2.777777rem;
		margin: 0;
	}

	.el-carousel__item:nth-child(2n) {
		background-color: #99a9bf;
	}

	.el-carousel__item:nth-child(2n+1) {
		background-color: #d3dce6;
	}

	/* 走进瑞科 */
	.gork {
		width: 1450px;;
		margin: 0 auto;
		padding-top: 60px;
		>div {
			width: 100%;
			margin: 0 auto;
			background-color: pink;
			background: url(../assets/images/bg3.png) no-repeat center center/contain;
			background-size: 1014px 565px;
			.gork-title {
				text-align: center;
				font-size: 38px;
				font-weight: 900;
				margin-top: 47px;
				margin-bottom: 50px;
				span:nth-child(1) {
					color: #CC6633;
				}
				span:nth-child(2) {
					color: #1C5380;
				}
				p {
					font-size: 24px;
					font-weight: 200;
					color: #666;
				}
			}

			.gork-dsc {
				font-size: 18px;
				margin-bottom: 56px;
				p{
					line-height: 36px;
					letter-spacing: 2px;
					color: #333;
					overflow : hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 4;
					-webkit-box-orient: vertical;
					
				}
				&>div{
					position: relative;
					div{
						width: 115px;
						color: #CC6633;
						background: #fff;
						height: 36px;
						line-height: 36px;
						font-weight: 700;
						position: absolute;
						bottom: 0;
						right: 0;
						span{
							color: #333;
						}
					}
				}
			}
			.numList1{
				display: flex;
				justify-content: space-evenly;
				align-items: center;
				margin-bottom: 80px;
			}
			.gork-info {
				font-size: 16px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-right: 1px solid #eee;
				.gork-info-box {
					width: 349px;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: space-around;
					p {
						span {
							display: inline-block;
							color: #Cc6633;
							font-size: 18px;
							font-weight: 700;
							vertical-align: top;
						}
					}
					p:nth-child(1) {
						font-size: 79px;
						font-family: tccm;
						color: #CC6633;
						font-weight: 900;
					}
					p:nth-child(2){
						margin-bottom: 5px;
					}
					p:nth-child(2),p:nth-child(3){
						font-size: 16px;
					}
				}
			}
			.gork-info:nth-child(4) {
				border: none;
			}
		}

	}

	// 查看专利大图
	.chakanbox{
		position: fixed;
		top: 0;
		width: 100vw;
		height: 100vh;
		z-index: 99999;
		background-color: rgba(0,0,0,0.7);
		display: none;
		.chakanzz{
			position: fixed;
			top: 13%;
			left: 0;
			right: 0;
			margin: 0 auto; 
			z-index: 99;
			.el-icon-close{
				position: absolute;
				top: 0.22rem;
				right: 0.3rem;
				cursor: pointer;
			}
		}	
	}
	// 轮播图
	.el-carousel__item{
		display: flex;
		justify-content: left;
		background: none !important;
	}
	// 产品中心
	.product {
		background-color: #eaeaea;
		.product-title {
			margin-bottom: 56px;
			text-align: center;
			font-size: 38px;
			font-weight: 900;
			span:nth-child(1) {
				color: #CC6633;
			}
			span:nth-child(2) {
				color: #1C5380;
			}

			p {
				font-size: 24px;
				font-weight: 200;
				color: #666;
			}
		}
		>div {
			width: 1400px;;
			margin: 0 auto;
			padding: 56px 0 ;
			.proList{
				.proListTitle{
					font-size: 18px;
					display: flex;
					justify-content: space-between;
					background: #D1D1D1;
					align-items: center;
					color: #1c5380;
					p{
						text-align: center;
					}
					.p3{
						font-size: 12px;
					}
					img{
						width: 32px;
					}
					.proListItem{
						width: 290px;
						height: 103px;
						padding: 15px 0;
						position: relative;
						background: #D1D1D1;
						cursor:pointer;
						.rightBorder{
							width: 1px;
							height: 60px;
							background: #b5b5b5;
							position: absolute;
							right: 0;
							top: 50%;
							transform: translateY(-50%);
						}
					}
					.active{
						background: #1c5380;
						color: #fff;
						.rightBorder{
							background: #1c5380;
						}
					}
				}
			}
			// 商品列表
			.product-list {
				text-align: center;
				.listDiv{
					padding-top: 18px;
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					align-items: center;
					.product-list-info:hover{
						.pro-zzc{
							transform: scaleY(1);
							opacity: 1;
							transition: 0.5s;
							// display: block;
						}
					}
					.product-list-info {
						position: relative;
						.pro-zzc{
							// 遮罩层
							cursor: pointer;
							width: 100%;
							height: 301px;
							margin-bottom: 30px;
							background-color: rgba(204,102,51,0.88);
							text-align: left;
							position: absolute;
							top: 0;
							display: flex;
							transform-origin: top;
							transform: scaleY(0);
							opacity: 0;
							flex-direction: column;
							justify-content: space-between;
							padding: 20px 43px;
							padding-top: 58px;
							padding-bottom: 61px;
							div{
								display: flex;
								justify-content: space-between;
								flex-direction: column;
								p{
									font-weight: 900;
									font-size:26px;
									color: #fff;	
								}
							}
							.chakan{
								p{
									padding-bottom: 6px;
									color: #fff;
									border-bottom: 1px solid #fff;
									font-size: 16px;
									span{
										color: #fff;
										font-family: "SimSun";
										float: right;
									}
								}
							}
						}
						.product-list-box {
							cursor: pointer;
							width: 335px;
							height: 301px;
							margin-bottom: 20px;
							background-color: #fff;
							img{
								width: 280px;
								height: 252px;
								display: block;
								margin: 0 auto;
							}
							p{
								width: 335px;
								padding: 0 30px;
								font-size: 18px;
								white-space:nowrap;
								overflow:hidden;
								text-overflow:ellipsis;
							}
						}
					}
				}
			}


			.product-cart {
				width: 94%;
				display: flex;
				margin: 0 auto;
				margin-top: 50px;
				justify-content: space-around;

				.product-cart-item {
					background-color: #d1d1d1;
					width: 20%;
					padding: 0.74074rem 0;
					font-size: 18px;
					text-align: center;
					cursor: pointer;

					p {
						color: #1c5380;

					}

					p:nth-child(3) {
						font-size: 12px;
					}
				}
				.active {
					background-color: #1c5380;
					p{
						color: #fff;
					}
				}
			}
			.goproCenter {
				margin: 0 auto;
				margin-top: 45px;
				display: flex;
				justify-content: center;
				align-items: center;
				.gocpzx {
					width: 186px;
					height: 58px;
				}
			}
		}
	}

	// 资质专利
	.patent {
		min-width: 1400px;;
		padding-bottom: 87px;
		background: url(../assets/images/bgzl.png);
		background-size: 100% 100%;
		.patent-main {
			.swiper-container{
				width: 1400px;;
				height: 400px;
				margin: 0 auto;
				position: relative;
				.leftBtn{
					width: 50px;
					height: 60px;
					background: url(../assets/images/leftBtn.png);
					background-size: 100% 100%;
					position: absolute;
					top: 50%;
					left: 0;
					transform: translateY(-50%);
					z-index: 999;
					cursor: pointer;
				}
				.rightBtn{
					width: 50px;
					height: 60px;
					background: url(../assets/images/rightBtn.png);
					background-size: 100% 100%;
					position: absolute;
					top: 50%;
					right: 0;
					transform: translateY(-50%);
					z-index: 999;
					cursor: pointer;
				}
				.swiperItem{
					display: flex;
					justify-content: left;
					align-items: center;
					&>.swiper-slide:last-child{
						margin-right: 0 !important;
					}
					.swiper-slide{
						width: 335px;
						height: 301px;
						padding: 0;
						margin: 0;
						margin-right: 20px;
					}
					.swiper-slide:hover{
						.lbzzc{
							cursor: pointer;
							transform: scaleY(1);
							opacity: 1;
							transition: 0.5s;
							// display: block;
						}
					}
				}
			}
		}
		.patent-title {
			text-align: center;
			font-size: 38px;
			margin: 50px 0;
			margin-bottom: 10px;
			span:nth-child(1) {
				color: #cc6633;
				font-weight: 900;
			}
			span:nth-child(2) {
				color: #fff;
				font-weight: 900;
			}
			p {
				color: #999999 !important;
				font-size: 24px;
				font-weight: 100;
			}
		}
		.lbzzc{
			width: 335px;
			height: 301px;
			padding: 20px 43px;
			padding-top: 58px;
			background-color: rgba(204,102,51,0.85);
			position: absolute;
			transform-origin: top;
			transform: scaleY(0);
			p{
				font-size: 24px;
				font-weight: 900;
				color: #fff;
			}
			.chakan{
				width: 249px;
				position: absolute;
				bottom: 61px;
				p{
					padding-bottom: 6px;
					font-size: 16px;
					border-bottom: 1px solid #fff;
					span{
						color: #fff;
						font-family: "SimSun";
						float: right;
					}
				}
			}
		}
	}

	// 新闻资讯
	.news {
		width: 1450px;;
		margin: 0 auto;
		.news-title {
			text-align: center;
			font-size: 38px;
			margin-top: 70px;
			margin-bottom: 40px;
			span:nth-child(1) {
				color: #cc6633;
				font-weight: 900;
			}
			span:nth-child(2) {
				color: #1C5380;
				font-weight: 900;
			}
			p {
				font-size: 24px;
				font-weight: 100;
			}
		}
		.news-nav {
			display: flex;
			justify-content: center;
			font-size: 18px;
			div {
				width: 166px;
				height: 56px;
				line-height: 56px;
				text-align: center;
				margin: 0 14px;
				margin-bottom: 38px;
				background-color: #A1A1A1;
				color: #fff;
			}
			.active{
				background: #1C5380;
			}
		}
		.news-main {
			margin: 0 auto;
			margin-bottom: 75px;
			.news-main-l {
				width: 1398px;
				margin: 0 auto;
				display: flex;
				justify-content: space-between;
				.zanwu{
					width: 100%;
					color: #666;
					font-size: 18px;
					text-align: center;
					line-height: 70px;
				}
				.left{
					width: 680px;
					img{
						width: 680px;
						height: 405px;
						cursor: pointer;
						background-color: pink;
					}
					.leftItem{
						display: flex;
						margin-top: -13px;
						background: #F5F5F5;
						.txt{
							position: relative;
							&>p:nth-child(2){
								padding-right: 55px;
							}
							.xiangqing{
								width: 127px;
								font-weight: 700;
								color: #CC6633;
								font-size: 14px;
								position: absolute;
								right: 0;
								bottom: 28px;
								background: #f5f5f5;
								span{
									color: #666;
									margin-right: 10px;
								}
							}
						}
					}
				}
				.right{
					width: 688px;
					.rightItem{
						display: flex;
						border-bottom: 1px solid #E3E3E3;
						.txt{
							position: relative;
							.xiangqing{
								width: 127px;
								font-weight: 700;
								color: #CC6633;
								font-size: 14px;
								position: absolute;
								right: 0;
								bottom: 28px;
								background: #fff;
								span{
									color: #666;
									margin-right: 10px;
								}
							}
						}
					}
				}
				.num{
					width: 100px;
					margin: 25px 0;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					border-right: 1px solid #E3E3E3;
					span {
						color: #333;
						font-size: 48px;
						font-weight: 900;
						display: block;
					}
					p{
						color: #999;
						font-size: 16px;
					}
				}
				.txt {
					flex: 1;					
					padding-top: 25px;
					padding-left: 19px;
					flex-wrap: wrap;
					border: none;
					p:nth-child(1) {
						font-size: 24px;
						margin-bottom: 14px;
					}
					p:nth-child(2) {
						font-size: 14px;
						color: #666;
						overflow : hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
					}
				}
			}
			.news-main-r{
				display: flex;
				ul{
					display: flex;
					flex-direction: column;
					font-size: 0.393558rem;
					li{
						width: 100%;
						height: 100%;
						display: flex;
						border-bottom: 1px solid #ececec;
						.num{
							width: 2.811128rem;
							height: 2.530015rem;
							margin-top: 0.337335rem;
							display: flex;
							flex-direction: column;
							justify-content: center;
							align-items: center;
							font-size: 0.337335rem;
							color: #666;
							border-right: 1px solid #ececec;
							span {
								font-size: 36px;
								font-weight: 900;
								display: block;
							}
						}
						.txt {
							width: 100%;
							display: flex;
							flex-direction: column;
							padding: 20px;
							p:nth-child(1) {
								font-size: 20px;
								margin-bottom: 10px;
							}
							p:nth-child(2) {
								color: #666;
							}
						}
					}
				}
					
			}
		}
	}

	// 工程案例
	.case{
		width: 1400px;
		margin: 0 auto;
		// 企业优势title
		.case-title {
			text-align: center;
			font-size: 38px;
			margin-top: 70px;
			margin-bottom: 50px;
			span:nth-child(1) {
				color: #cc6633;
				font-weight: 900;
			}
			span:nth-child(2) {
				color: #1C5380;
				font-weight: 900;
			}
			p {
				color: #666;
				font-size: 24px;
				font-weight: 100;
			}
		}
		.case-main{
			width: 100%;
			margin: 0 auto;
			margin-bottom: 104px;
			display: flex;
			div{
				cursor: pointer;
			}
			.case-main-l{
				width: 700px;
				height:480px;
				position: relative;
				img{
					position: absolute;
					z-index: -2;
					top: 0;
					width: 100%;
					height: 100%;
				}
				.txt{
					border-left: 2px solid #fff;
					padding-left: 18px;
					display: flex;
					flex-direction: column;
					justify-content: space-between;	
					margin-top: 83px;
					margin-left: 26px;
					p{
						color: #fff;
						overflow : hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
						line-height: 0.9rem;
						
					}
					p:nth-child(1){
						font-size: 30px;
						margin-top: -8px;
					}
					p:nth-child(2){
						margin-top: 20px;
						padding-right: 113px;
						font-size: 18px;
						line-height: 26px;
						margin-bottom: -4px;
					}
						
				}
				
			}
			.case-main-r{
				flex: 1;
				display: flex;
				flex-wrap: wrap;
				align-content: flex-start;
				>div{
					width: 350px;
					height: 240px;
				}
				>div:nth-child(1){
					position: relative;
					padding-top: 33px;
					padding-left: 28px;
					img{
						position: absolute;
						top: 0;
						left: 0;
						z-index: -1;
						top: 0;
					}
					.txt{
						margin: 0;
						padding-right: 50px;
						p:nth-child(1){
							font-size: 30px;
							font-weight: 600;
						}
						p:nth-child(2){
							font-size: 18px;
							margin-top: 18px;
						}
					}
				}
				>div:nth-child(2){
					background-color: #1C5380;
					.txt{
						margin-top: 64px;
						margin-left: 76px;
						p:nth-child(1){
							font-size: 20px;
							font-weight: 100;
						}
						p:nth-child(2){
							font-size: 30px;
							margin-top: 9px;
							line-height: 31px;
						}
						button{
							width: 179px;
							height: 48px;
							background-color: #cc6633;
							font-size: 16px;
							outline: none;
							border: none;
							cursor: pointer;
							color: #fff;
							span{
								color: #fff;
								font-family: 'SimSun';
							}
						}
					}
				}
				>div:nth-child(3){
					background-color: #cc6633;
					.txt{
						padding-right: 50px;
						margin-top: 73px;
						margin-left: 28px;
						p:nth-child(1){
							font-size: 30px;
						}
						p:nth-child(2){
							margin-top: 18px;
							overflow : hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 2;
							-webkit-box-orient: vertical;
							line-height: 26px;
						}
						
					}
				}
				>div:nth-child(4){
					position: relative;
					img{
						position: absolute;
						z-index: -1;
						top: 0;
						width: 100%;
						height: 100%;
					}
					.txt{
						padding-right: 50px;
						padding-left: 19px;
						margin-top: 37px;
						margin-left: 26px;
						border-left: 2px solid #fff;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						p{
							color: #fff;
						}
						p:nth-child(1){
							font-size: 30px;
							font-weight: 600;
						}
						p:nth-child(2){
							margin-top: 18px;
							font-size: 18px;
							overflow : hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 2;
							-webkit-box-orient: vertical;
							line-height: 26px;
						}
					}
				}
				.txt{
					padding-left: 19px;
					border-left: 2px solid #fff;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					p{
						color: #fff;
					}
					p:nth-child(1){
						font-size: 30px;
						font-weight: 600;
						margin-top: -6px;
					}
					p:nth-child(2){
						margin-bottom: -4px;
						font-size: 18px;
						overflow : hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
						line-height: 26px;
					}
				}
				
			}
		}
	}
	// 查看专利大图
		.chakanbox{
			position: fixed;
			top: 0;
			width: 100vw;
			height: 100vh;
			z-index: 99999;
			background-color: rgba(0,0,0,0.7);
			display: none;
			.chakanzz{
				position: fixed;
				top: 50%;
				left: 0;
				right: 0;
				transform: translateY(-50%);
				margin: 0 auto; 
				width: 30%;
				z-index: 99;
				.el-icon-close{
					position: absolute;
					top: 0.22rem;
					right: 0.3rem;
					cursor: pointer;
					img{
						display: block;
					}
				}
			}	
		}
		.fontFZ{
			font-family: fz;
			color: #666 !important;
		}
</style>

