<template>
	<div id="conpanyDsc">
		<Nav></Nav>
		<div class="banner">
			<img src="../assets/images/1.png" alt="" class="wow animate__animated animate__slideInDown animate__delay-0.5s">
		</div>
		
		<div style="min-width: 1400px;background-color: #f6f8f9;">
		<div class="nav">
			<div class="nav-cart wow animate__animated animate__slideInDown animate__delay-0.5s">
				<!-- 产品分类 -->
				<div :class="{active:index==cartIndex}" class="nav-cart-item" v-for="(item,index) in cartList"
					@click="cartShow(index)" :key='index'>
					<p>{{item.name}}</p>
				</div>
			</div>
		</div>
		</div>
		<!-- 公司简介 part -->
		<div v-if="cartIndex == 0">
			<div class="container">
				<!-- 公司简介title -->
				<div class="company wow animate__animated animate__slideInUp animate__delay-0.5s">
					<div>
						<div>
							<!-- gutter栅格距离  默认为0 -->
							<div>
								<div class="con-title ">
									<span>公司</span>
									<span>简介</span>
									<p class="fontFZ">COMPANY PROFILE</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- 大数字 -->
				<el-row type="" :gutter="10" justify="center" style="margin-bottom: 79px;">
					<el-col class="con-info" :xs="24" :sm="12" :md="12" :lg="6" :xl="6" v-for="(item,index) in rukeData" :key="index">
						<div class="con-info-box wow animate__animated animate__slideInUp animate__delay-0.5s">
							<p><num-up :value="item.num" :type='1'></num-up><span>+</span></p>
							<p>{{item.txt1}}</p>
							<p>{{item.dsc}}</p>
						</div>
					</el-col>
				</el-row>
				
				<div class="con-txt wow animate__animated animate__slideInUp animate__delay-0.5s">
					<p v-html="profile.content" style="text-indent: 1em;">{{profile.content}}</p>
					
				</div>
				<div class="con-pic">
						<img v-for="(item,index) in profile.profileImages" :key="index" :src="profile.profileImages[index]" class="con-info-box wow animate__animated animate__slideInLeft animate__delay-0.5s">
				</div>
				
			</div>
			<!-- 企业优势 -->
			<Advant class="wow animate__animated animate__slideInUp animate__delay-0.5s"></Advant>
			
			<!-- 全景展示 -->
			<div class="display wow animate__animated animate__slideInUp animate__delay-0.5s">
				<div style="width: 100%;margin: 0 auto;">
					<el-row type="flex" justify="center">
						<!-- gutter栅格距离  默认为0 -->
						<el-col :xs="16" :sm="16" :md="18" :lg="20" :xl="24">
							<div class="display-title">
								<span>全景</span>
								<span>展示</span>
								<p class="fontFZ">PANORAMIC DISPLAY</p>
							</div>
						</el-col>
					</el-row>
				</div>
				<div class="display-pic" style="text-align:center;">
					<iframe :src="profile.display" scrolling="no" style="width: 1400px;height:480px;" frameborder="0"></iframe>
				</div>
			</div>
			
			<!-- 免费获取产品报价 -->
			<Free class="wow animate__animated animate__slideInUp animate__delay-0.5s" ></Free>
		</div>
		
		<!-- 组织架构 part -->
		<div v-if="cartIndex == 1">
			<div class="organization company wow animate__animated animate__slideInUp animate__delay-0.5s">
				<div>
					<el-row type="flex" justify="center">
						<!-- gutter栅格距离  默认为0 -->
						<el-col :xs="16" :sm="16" :md="18" :lg="20" :xl="24">
							<div class="display-title">
								<span>组织</span>
								<span>架构</span>
								<p class="fontFZ">ORGANIZATIONAL STRUCTURE</p>
							</div>
						</el-col>
					</el-row>
				</div>
				<div class="organization-pic"><!-- 组织结构图 -->
					<img :src="structure.sturcImage" >
				</div>
			</div>
			
			
		</div>
		
		<div class="chakanbox">
			<!-- 查看公司荣誉大图 -->
			<div class="chakanzz" v-if="pupopIndex === 0 || pupopIndex>0">
				<img :src="patents[pupopIndex].image"  style="width: 100%;height: 100%;">
				<i class="el-icon-close" @click="closeBig"></i>
			</div>	
		</div>
		<!-- 公司荣誉 part -->
		<div v-if="cartIndex == 2">
			<div class="honor company wow animate__animated animate__slideInUp animate__delay-0.5s">
				<div>
					<div>
						<div>
							<div class="honor-title">
								<span>公司</span>
								<span>荣誉</span>
								<p class="fontFZ">COMPANY HONOR</p>
							</div>
						</div>
					</div>
				</div>
			
				
				
				<div class="honor-list" >
					<div class="item" v-for="(item,index) in patents" :key="index">
						<div to="" @click='openBig(index,item.image)' class="pro-zzc">
							<div><p>{{item.title}}</p></div>
							<div class="chakan">
								<p>查看详情<span>&gt;</span></p>
							</div>
						</div>	
						<div style="overflow: hidden;" class="imgdiv">
							<img :src="patents[index].slimage" >
						</div>
					</div>
					<!-- <div class="item" style="border:none;"></div>
					<div class="item" style="border:none;"></div> -->
				</div>
				
				
				<div class="honor-yema">
					<div class="pro-yema">
						<el-pagination background layout="prev, pager,next" 						
						@current-change="pagesChange"
						:total="total"
						:current-page.sync="page"
						:page-size="pageSize"
						prev-text="首页" 
						next-text="尾页"
						@prev-click="toHead"
						@next-click="nextPage"
						>
						</el-pagination>	
					</div>
				</div>
				
			</div>
		</div>
		
		<!-- 企业文化 part -->
		<div v-if="cartIndex == 3">
			<div class="culture company wow animate__animated animate__slideInUp animate__delay-0.5s">
					<div type="flex" justify="center">
						<div>
							<div class="culture-title">
								<span>企业</span>
								<span>文化</span>
								<p class="fontFZ">CORPORATE CULTURE</p>
							</div>
						</div>
					</div>
				<div class="culture-pic">
					<div class="culture-pic-item" v-for="(item,index) in cultureList" :key="index">
						<p><img :src="cultureList[index].image"></p>
						<p>{{cultureList[index].title}}</p>
						<p v-html="cultureList[index].desc">{{cultureList[index].desc}}</p>
					</div>
				</div>
			</div>
			
			
		</div>
		
		<!-- 新闻动态 part -->
		<div v-if="cartIndex == 4">
			<div class="news ">
				<!-- 新闻小导航 -->
				<div class="news-l ">
					<div class="news-nav company wow animate__animated animate__slideInUp animate__delay-0.5s">
						<div>
							<div class="news-nav-item" :class="{newsactive:index==newsIndex}" v-for="(item,index) in product" :key="index" @click="newsShow(index,item.id)">{{item.name}}</div>
						</div>
					</div>
					
					<!-- 新闻列表 -->
					<div class="zanwu" v-if="newsList.length<=0">
						暂无新闻动态...
					</div>
					<router-link v-for="(item,index) in newsList" :key="index" :to="{path:'/newsdsc',query:{id:item.id}}" tag="div" class="news-l-list wow animate__animated animate__slideInUp animate__delay-0.5s ">
						<div class="list-l wow animate__animated animate__slideInUp animate__delay-0.5s">
							<img :src="newsList[index].eximage" >
						</div>
						<div class="list-c wow animate__animated animate__slideInUp animate__delay-0.5s">
							<p>{{newsList[index].title}}</p>
							<p v-html="newsList[index].content">{{newsList[index].content}}</p>
							<div class="xiangqing"><span>...</span>[详情]</div>
						</div>
						<div class="xian"></div>
						<div class="list-r wow animate__animated animate__slideInUp animate__delay-0.5s">
							<span>{{item.dataTime[2]}}</span>
							<p>{{item.dataTime[0]}}.{{item.dataTime[1]}}</p>
						</div>
					</router-link>
					
					<!-- 页码 -->
					<div class="pro-yema">
						<el-pagination background layout="prev, pager,next" 						
						@current-change="pagesChange"
						:total="total"
						:current-page.sync="page"
						prev-text="首页" 
						next-text="尾页"
						@prev-click="toHead"
						@next-click="nextPage"
						>
						</el-pagination>	
						
					</div>
					
				</div>
				<!-- 产品推荐 -->
				<div class="news-r">
					<div class="news-r-nav wow animate__animated animate__slideInUp animate__delay-0.5s">
						<p>
							<img src="../assets/images/heng.png" >
							&ensp;产品推荐&ensp;
							<img src="../assets/images/heng.png" >
						</p>
					</div>
					<!-- 推荐商品列表 -->
					<div class="news-item wow animate__animated animate__slideInUp animate__delay-0.5s" v-for="(item,index) in hotList" :key="index" @click="gochanpin(item.id)">
						<p><img :src="item.image" ></p>
						<p align="center">{{item.name}}</p>
					</div>
					
					<!-- 联系我们 -->
					<div class="news-r-nav wow animate__animated animate__slideInUp animate__delay-0.5s" style="margin-top: 20px;background-color: #266193;">
						<p>
							<img src="../assets/images/heng.png" >
							<span>&ensp;联系我们&ensp;</span>
							<img src="../assets/images/heng.png" >
						</p>
					</div>
					
					<div class="lianxi  wow animate__animated animate__slideInUp animate__delay-0.5s">
						<p :title="contactList.steamAddress">地址：{{contactList.steamAddress}}</p>
						<p>电话：<span style="font-size: 28px;color: #a9a9a9;">{{contactList.steamHotline}}</span></p>
						<p>传真：{{contactList.steamFax}}</p>
						<p>邮编：{{contactList.steamPostcode}}</p>
						<p>网址：{{contactList.steamUrl}}</p>
						<p>邮箱：{{contactList.steamEmail}}</p>
					</div>
				</div>
				
			</div>
		</div>
		<div class="pupop2">
			<div class="inputDiv">
				<img class="guanbi" @click="guanbi()" src="../assets/images/guanbiWhite.png" alt="">
				<img :src="pupop" alt="">
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Swiper from "../components/swiper.vue"
	import Nav from "../components/nav.vue"
	import Footer from "../components/footer.vue"
	import Free from "../components/free.vue"
	import Advant from "../components/advantage.vue"
	import numUp from "../components/numUp.vue"
	import $ from "jquery"
	export default{
		data(){
			return{
				patents:[],// 荣誉数据
				page:1,// 当前页数
				total:0,// 总条数
				pageSize:12,// 显示条数
				currentPage: 1,
				cartIndex: 0,
				newsIndex:0,
				res:[],
				profile:[],// 公司简介数据
				structure:[],// 组织架构 
				product:[],//  新闻下面的平台分类 
				newsList:[],// 新闻分类
				contactList:[],//联系我们
				honor1:[],//公司荣誉
				cartList: [{name:'公司简介'},{name:'组织架构'},{name:'公司荣誉'},{name:'企业文化'},{name:'新闻动态'}],
				rukeData: [
					{num: 0,txt1: '10余年阀门经验',dsc: '诚实守信 永续经营'},
					{num: 0,txt1: '专利技术',dsc: '自主专利 彰显实力'},
					{num: 0,txt1: '加工检测装备',dsc: '装备齐全 奠定基础'},
					{num: 0,txt1: '合作客户',dsc: '客户满意 长期合作'}
				],
				// 企业文化数据	
				cultureList:[],
				pupopIndex:'',
				hotList:'',//推荐产品

				lastPage:'',
				pupop:'',
			}
		},
		components: {
			numUp,
			Nav,
			Footer,
			Free,
			Advant,
			Swiper
		},
		watch:{
			$route(){
				if(this.$route.query.i){
					this.cartShow(this.$route.query.i)
				}else{
					this.cartShow(0)
				}
			},
		},
		created() {
			var _this = this
			this.getIndexData()
			this.getHonor();
			this.getHotList()
			this.getProfile();
			this.getStructure();
			this.getCulture();
			this.getProduct();
			this.getContact();
			if(this.$route.query.i){
				this.cartShow(this.$route.query.i)
			}else{
				this.cartShow(0)
			}
			_this.$apis.get(_this.$apis.inside_banner).then(res=>{
				console.log('banner',res.data.data)
				$('.banner').css('background','url('+res.data.data.About+')')
				$('.banner').css('backgroundSize','100% 100%')
			})
		},
		methods: {
			getIndexData(){
				var _this = this;
				_this.$apis.get(_this.$apis.index).then(res=>{
					_this.rukeData[0].num = res.data.data.famenExp
					_this.rukeData[1].num = res.data.data.Patented
					_this.rukeData[2].num = res.data.data.Testing
					_this.rukeData[3].num = res.data.data.Customers
				})
			},
			//去产品详情
			gochanpin(id){
				var _this = this;
				_this.$router.push({
					path:'/prodsc',
					query:{
						id:id
					}
				})
			},
			//获取推荐产品
			getHotList(){
				var _this = this;
				_this.$apis.get(_this.$apis.hot_list).then(res=>{
					_this.hotList = res.data.data
				})
			},
			getHonor(i){
				this.$http.get('https://admin.ruikefamen.com/api/company/honor_list',
				{params:{page:i?i:this.page}})
				.then(res=>{
					this.patents = res.data.data.data
					this.total = res.data.data.total
					this.pageSize = res.data.data.per_page
					this.lastPage = res.data.data.last_page
				})
			},
			pagesChange(){
				if(this.cartIndex == 4){
					this.getNewsList(this.page,this.newsTypeId)
				}else if(this.cartIndex == 2){
					this.getHonor(this.page)
				}
				$("html,body").animate({"scrollTop":"450"},500)
			},

			cartShow(index) {
				this.cartIndex = index
				this.page = 1
				if(index == 4){
					this.getNewsList(1,this.newsTypeId)
				}else if(index == 2){
					this.getHonor()
				}
			},
			newsShow(index,id) {
				// 新闻小导航
				this.newsIndex = index
				this.newsTypeId = id
				this.getNewsList(1,this.newsTypeId)
			},
			toHead(){
				this.page = 1;
			},
			nextPage(){
				this.page = this.lastPage;
			},
			getProfile(){
				this.$http.get('https://admin.ruikefamen.com/api/company/profile')
				.then(res=>{
					this.profile = res.data.data
				})
			},
			getStructure(){// 组织架构
				this.$http.get('https://admin.ruikefamen.com/api/company/structure')
				.then(res=>{
					this.structure = res.data.data
				})
			},
			getProduct(){
				this.$http.get('https://admin.ruikefamen.com/api/index/gettype?type=news')
				.then(res=>{
					this.product = res.data.data,
					this.newsTypeId = res.data.data[0].id
					this.getNewsList(1,this.newsTypeId)
				})
			},

			newsRichTextFormat(value) {
				value = value.replace(/<\/?.+?>/g,'')
				value = value.replace(/\s+/g,'')
				if (value.length > 200) {
					this.isLength = true;	
					return value.slice(0, 90) + "...";
				}else{
					this.isLength = false;
				}
				return value;
			},
			//秒转时间
			timeData(s){
				let date = new Date(s*1000).toLocaleString()
				let dateTime = date.split(' ')[0].split('/')
				return dateTime;
			},	
			getNewsList(i,id){
				var _this = this;
				_this.$apis.get(_this.$apis.newsList,
					{params:{page:i?i:1,categoryid:id}}
				).then(res=>{
					_this.res = res.data.data
					if(_this.page == 1){
						_this.total = res.data.data.total
						_this.pageSize = res.data.data.per_page
					}
					_this.lastPage = res.data.data.last_page
					for(let j=0;j<res.data.data.data.length;j++){
						res.data.data.data[j].dataTime = res.data.data.data[j].createtime.split('-')
						res.data.data.data[j].content = _this.newsRichTextFormat(res.data.data.data[j].content)
					}
					_this.newsList = res.data.data.data// 新闻列表数据
					console.log(res.data.data.data,'新闻列表')
				})
			},
			getContact(){
				this.$http.get("https://admin.ruikefamen.com/api/index/index")
				.then(res=>{
					this.contactList = res.data.data
				})
			},
			getCulture(){
				var _this = this;
				_this.$apis.get(_this.$apis.culture)
				.then(res=>{
					_this.cultureList = res.data.data.list
				})
			},
			openBig(index,url){// 查看大图
				this.pupopIndex = index
				// document.querySelector(".chakanbox").style.display = 'block'
				this.pupop = url
				$('.pupop2').fadeToggle()
				var top=$(document).scrollTop();
					$(document).on('scroll.unable',function (e){
					$(document).scrollTop(top);
				})
			},
			guanbi(){
				$('.pupop2').fadeToggle()
				$(document).unbind("scroll.unable");
			},
			closeBig(){// 关上大图
				document.querySelector(".chakanbox").style.display = 'none'
			},
			
		},
	}
</script>

<style scoped="scoped" lang="less">
.pupop2{
	width: 100vw;
	height: 100vh;
	display: none;
	background-color: rgba(0, 0, 0, 0.65);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	.inputDiv{
		width: 600px;
		margin: 0 auto;
		text-align: center;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		.guanbi{
			width: 30px;
			position: absolute;
			top: 10px;
			right: 10px;
			cursor: pointer;
		}
	}
}
	.banner{
		position: relative;
		width: 100%;
		height: 500px;
		text-align: center;
		// background: url(../assets/images/inbanner01.png) no-repeat center center / cover;
		img{
			height: 90px;
			width: auto;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			top: 100px;
			margin: auto;
		}
	}
	.nav{
		width: 100%;
		height: 80px;
		margin: 0 auto;
		background-color: #f6f8f9;
		.nav-cart {
			width: 1250px;
			height: 80px;
			line-height: 80px;
			margin: 0 auto;
			display: flex;
			.nav-cart-item {
				background-color: #d1d1d1;
				width: 250px;
				height: 100%;
				font-size: 18px;
				text-align: center;
				cursor: pointer;
				transform: skew(-25deg);
				p {
					color: #666;
					transform: skew(25deg);
				}
				p:nth-child(3) {
					font-size: 0.337335rem;
				}
			}
			.active {
				background-color: #1c5380;
				p{
					color: #fff;
				}
			}
		}
	}
	// 公司简介 part
	.container{
		width: 1400px;
		margin: 0 auto;
		background: url(../assets/images/bg3.png) no-repeat top center/contain;
		.company{
			width: 100%;
			.con-title {
				text-align: center;
				padding: 100px 0 45px 0;
				font-size: 38px;
				span:nth-child(1) {
					color: #cc6633;
					font-weight: 900;
				}
				span:nth-child(2) {
					color: #1C5380;
					font-weight: 900;
				}
				p {
					font-size: 24px;
					font-weight: 100;
				}
			}
		}
		.con-info {
			display: flex;
			align-items: center;
			justify-content: center;
			border-right: 1px solid #eee;
			.con-info-box {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: space-around;
				p {
					font-size: 16px;
					span {
						font-size: 18px;
						display: inline-block;
						color: #Cc6633;
						font-weight: 100;
						margin-left: 0.111111rem;
						vertical-align: top;
					}
				}
				p:nth-child(1) {
					font-size: 79px;
					color: #CC6633;
					font-weight: 900;
					font-family: tccm;
				}
			}
		}
		.con-info:nth-child(4) {
			border: none;
		}
	
		.con-txt{
			width: 1400px;
			margin: 0 auto;
			p{
				font-size: 18px;
				line-height: 36px;
			}
		}
		.con-pic{
			width: 1400px;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			margin-bottom: 150px;
			margin-top: 79px;
			img{
				width: 679px;
			}
		}
		// 公司简介 end
	}

	// 全景展示
	.display{
		min-width: 1400px;
		margin: 0 auto;
		.display-title {
			text-align: center;
			padding: 84px 0 42px;
			font-size: 38px;
			span:nth-child(1) {
				color: #cc6633;
				font-weight: 900;
			}
			span:nth-child(2) {
				color: #1C5380;
				font-weight: 900;
			}
			p {
				font-size: 24px;
				font-weight: 100;
			}
		}
		.display-pic{
			background-color: #fff;
			width: 100%;
			padding-bottom: 209px;
			img{
				width: 100%;
				height: auto;
			}
		}
	}
	
	// 组织架构 part
	.organization{
		width: 1400px;
		margin: 0 auto;
		.display-title {
			text-align: center;
			padding: 100px 0;
			font-size: 38px;
			span:nth-child(1) {
				color: #cc6633;
				font-weight: 900;
			}
			span:nth-child(2) {
				color: #1C5380;
				font-weight: 900;
			}
			p {
				font-size: 24px;
				font-weight: 100;
			}
		}
		.organization-pic{
			width: 1400px;
			margin: 0 auto;
			margin-bottom: 160px;
			img{
				width: 100%;
				height: auto;
			}
		}
		
	}
	
	
	// 查看专利大图
	.chakanbox{
		position: fixed;
		top: 0;
		width: 100vw;
		height: 100vh;
		z-index: 99999;
		background-color: rgba(0,0,0,0.7);
		display: none;
		.chakanzz{
			position: fixed;
			top: 13%;
			left: 0;
			right: 0;
			margin: 0 auto; 
			width: 14rem;
			height: 14rem;
			background-color: pink;
			z-index: 99;
			.el-icon-close{
				position: absolute;
				top: 0.22rem;
				right: 0.3rem;
				cursor: pointer;
			}
		}	
	}
	.honor-title {
		width: 1400px;
		margin: 100px auto 80px;
		text-align: center;
		font-size:38px;
		span:nth-child(1) {
			color: #cc6633;
			font-weight: 900;
		}
		span:nth-child(2) {
			color: #1C5380;
			font-weight: 900;
		}
		p {
			font-size: 24px;
			font-weight: 100;
		}
	}
	// 公司荣誉 part
	.honor{
		width: 1400px;
		margin: 0 auto;
		.honor-title {
			width: 1400px;
			margin: 100px auto 80px;
			text-align: center;
			font-size:38px;
			span:nth-child(1) {
				color: #cc6633;
				font-weight: 900;
			}
			span:nth-child(2) {
				color: #1C5380;
				font-weight: 900;
			}
			p {
				font-size: 24px;
				font-weight: 100;
			}
		}
		
		.honor-list{
			// 商品列表
			width: 100%;
			// display: flex;
			// flex-wrap: wrap;
			// text-align: center;
			// justify-content: space-between;
			.item:nth-child(4n){
				margin-right: 0;
			}
			.item{
				display: inline-block;
				position: relative;
				width: 335px;
				height: 301px;
				margin: 0 20px 20px 0;
				border: 1px solid #e6e6e6;
				.pro-zzc{
					// 遮罩层
					cursor: pointer;
					width: 100%;
					height: 100%;
					background-color: rgba(204,102,51,0.88);
					text-align: left;
					position: absolute;
					top: 0;
					display: flex;
					transform-origin: top;
					transform: scaleY(0);
					opacity: 0;
					flex-direction: column;
					justify-content: space-between;
					padding: 20px 43px;
					padding-top: 58px;
					padding-bottom: 61px;
					div{
						height: 45px;
						display: flex;
						justify-content: space-between;
						flex-direction: column;
						p{
							font-weight: 900;
							margin-top: 0.2rem;
							line-height: 1.05rem;
							font-size: 24px;
							color: #fff;	
						}
					}
					.chakan{
						p{
							color: #fff;
							border-bottom: 1px solid #fff;
							font-size: 18px;
							span{
								color: #fff;
								font-family: "SimSun";
								float: right;
							}
						}
					}
				}
				.imgdiv{
					width: 335px;
					height: 301px;
					display: flex;
					flex-direction: column;
					align-items: center;
				}
				
			}
			.item:hover{
				.pro-zzc{
					transform: scaleY(1);
					opacity: 1;
					transition: 0.5s;
					// display: block;
				}
			}
			.item:nth-child(4n){
				margin-right: 0;
			}
		}
		
		.pro-yema{
			margin: 90px auto;
			display: flex;
			justify-content: center;
			/deep/ .el-pagination.is-background .el-pager li{
				background: #fff;
				border: 1px solid #eee;
			}
			/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
				background-color: #cc6633;
			}
			/deep/ .el-pagination.is-background .el-pager li:not(.disabled).number {
				width: 48px;
				height: 48px;
				line-height: 48px;
			}
			/deep/ .el-pagination.is-background .btn-prev {
				width: 68px;
				height: 48px;
				line-height: 48px;
				background: #fff;
				border: 1px solid #eee;
				span{
					width: 68px;
					height: 48px;
					line-height: 48px;
				}
			}
			/deep/ .el-pagination.is-background .btn-next {
				width: 68px;
				height: 48px;
				line-height: 48px;
				background: #fff;
				border: 1px solid #eee;
				span{
					width: 68px;
					height: 48px;
					line-height: 48px;
				}
			}
		}
	}

	// 企业文化 part
	.culture{
		width: 100%;
		margin: 0 auto;
		.culture-title {
			width: 1400px;
			margin: 0 auto;
			text-align: center;
			padding: 100px 0 89px 0;
			font-size: 38px;
			span:nth-child(1) {
				color: #cc6633;
				font-weight: 900;
			}
			span:nth-child(2) {
				color: #1C5380;
				font-weight: 900;
			}
			p {
				font-size: 24px;
				font-weight: 100;
			}
		}
		.culture-pic{
			min-width: 1400px;
			margin: 0 auto;
			margin-bottom: -2px;
			padding: 0 98.5px;
			background: url(../assets/images/500968118.png) no-repeat center center/cover;
			display: flex;
			.culture-pic-item{
				width: 286px;
				height: 694px;
				box-sizing: border-box;
				padding: 0 34px;
				padding-top: 86px;
				border-left: 1px solid rgba(88,86,94,0.8);
				cursor: pointer;
				p{
					color: #fff;
					border: none;
					img{
						border: none;
					}
				}
				p:nth-child(2){
					font-size: 30px;
					margin-top: 33px;
				}
				p:nth-child(3){
					font-size: 16px;
					margin-top: 16px;
					line-height: 30px;
				}
			}
			.culture-pic-item:nth-child(1){
				p{img{
					width: 58px;
				}}
			}
			.culture-pic-item:nth-child(2){
				p{img{
					width: 49px;
				}}
			}
			.culture-pic-item:nth-child(3){
				p{img{
					width: 58px;
				}}
			}
			.culture-pic-item:nth-child(4){
				p{img{
					width: 55px;
				}}
			}
			.culture-pic-item:nth-child(5){
				p{img{
					width: 57px;
				}}
			}
			.culture-pic-item:nth-child(6){
				p{img{
					width: 58px;
				}}
			}
			:last-child{
				border-right: 1px solid rgba(88,86,94,0.8);
			}
			.culture-pic-item:hover{
				background-color: rgba(0,0,0,.4);
				transition: 0.5s;
			}
		}
	}

	// 新闻动态 part
	.news{
		width: 1400px;
		padding-top: 62px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		// 页码
		.pro-yema{
			margin: 90px auto;
			display: flex;
			justify-content: center;
			/deep/ .el-pagination.is-background .el-pager li{
				background: #fff;
				border: 1px solid #eee;
			}
			/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
				background-color: #cc6633;
			}
			/deep/ .el-pagination.is-background .el-pager li:not(.disabled).number {
				width: 48px;
				height: 48px;
				line-height: 48px;
			}
			/deep/ .el-pagination.is-background .btn-prev {
				width: 68px;
				height: 48px;
				line-height: 48px;
				background: #fff;
				border: 1px solid #eee;
				span{
					width: 68px;
					height: 48px;
					line-height: 48px;
				}
			}
			/deep/ .el-pagination.is-background .btn-next {
				width: 68px;
				height: 48px;
				line-height: 48px;
				background: #fff;
				border: 1px solid #eee;
				span{
					width: 68px;
					height: 48px;
					line-height: 48px;
				}
			}
		}
		.news-nav::-webkit-scrollbar {
			display: none;
		}
		.news-nav{
			width: 100%;
			margin-bottom: 43px;
			background: #EDEDED;
			overflow-x: scroll;
			&>div{
				display: flex;
				flex-wrap: nowrap;
			}
			.news-nav-item{
				flex-shrink: 0;
				width: 157px;
				height: 64px;
				border-right: 4px solid #fff;
				background-color: #ededed;
				font-weight: 900;
				font-size: 18px;
				text-align: center;
				line-height: 64px;
				cursor: pointer;
			}
			.newsactive{
				background-color: #266193;
				color: #fff;
			}
		}
		.news-l{
			width: 1063px;
			padding-right: 33px;
			.zanwu{
				width: 100%;
				color: #666;
				font-size: 18px;
				text-align: center;
				line-height: 70px;
			}
		}
		.news-r{
			width: 335px;
			.news-r-nav{
				background-color: #cc6633;
				display: flex;
				justify-content: center;
				p{
					font-size: 18px;
					color: #fff;
					font-weight: 900;
					line-height: 64px;
				}
				img{
					width: 27px;
					vertical-align: middle;
				}
			}
			// 右侧推荐商品
			.news-item{
				width: 335px;
				height: 301px;
				margin-top: 20px;
				background-color: #fff;
				position: relative;
				overflow: hidden;
				border : 1px solid #E6E6E6;
				cursor:pointer;
				img{
					display: block;
					width: 285px;
					height: 252px;
					margin: 0 auto;
				}
				p:nth-child(2){
					width: 335px;
					padding: 0 30px;
					font-size: 18px;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}
			.lianxi{
				width: 100%;
				background-color: #fff;
				height: 6.971599rem;
				border : 1px solid #E3E3E3;
				line-height: 0.843338rem;
				padding: 0.618448rem;
				margin-top: 0.421669rem;
				color: #666;
				p{
					font-size: 14px;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				}
				p span{
					font-size: 22px;
					color: #666;
				}
			}
			
		}
		.news-l-list{
			height: 214px;
			padding: 44px 0;
			margin-right: 33px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-bottom: 1px solid #e6e6e6;
			cursor:pointer;
			.list-c{
				flex: 1;
				margin-left: 27px;
				margin-right: 140px;
				position: relative;
				.xiangqing{
					width: 105px;
					font-weight: 700;
					color: #CC6633;
					font-size: 14px;
					background: #fff;
					position: absolute;
					right: 0;
					bottom: 1px;
					span{
						color: #666;
						margin-right: 10px;
					}
				}
				p{
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
				}
				p:nth-child(1){
					font-size: 24px;
					margin-bottom:14px;
				}
				p:nth-child(2){
					color: #666666;
					font-size: 14px;
					margin-top: 5px;
					line-height: 18px;
				}
			}
			.xian{
				width: 1px;
				height: 82px;
				background: #e6e6e6;
			}
			.list-r{
				padding-left: 25px;
				width: 105px;
				p:nth-child(1){
					font-weight: 900;
					font-size: 48px;
				}
				p:nth-child(2){
					font-size: 16px;
					color: #999;
				}
			}
			
		}
	}
	.list-l{
		width: 212px;
		height: 126px;
	}
	.fontFZ{
		font-family: fz;
		color: #666 !important;
	}
</style>
