<template>
  <div>
	<div id="app">
		<!-- 导航 -->
	<div class="obox"></div><!-- 左边橘色盒子 -->
		<div class="navDiv">
			<div class="nav-safe">
				<div class="navList">
					<div class="img"><img src="../assets/images/LOGO-bai.png"></div>
					<div class="linkList">
						<router-link to="/index"  active-class="activeClass" class="navItem ccc">
							<div>首页</div>
							<div class="titlee">HOME</div>
							<div class="block"></div>
						</router-link>
						<router-link to="/about"  active-class="activeClass" class="navItem hover">
							<div>走进瑞科</div>
							<div class="titlee">ABOUT</div>
							<div class="block"></div>
						</router-link>
						<div class="navBlock">
							<div class="left">
								<div class="img"><img src="../assets/images/logoo.png"></div>
								<div class="p1">{{index.profileTitle}}</div>
								<div class="p2">{{index.profileSlogan}}</div>
							</div>
							<div class="center">
								<div class="centerTitle">走进瑞科 <span>ABOUT</span>
									<div class="xuxian"></div>
								</div>
								<div class="centerItem" @click="goConpany(0)">
									<div>公司简介</div>
									<div class="no"><img src="../assets/images/navYou.png" alt=""></div>
									<div class="ac"><img src="../assets/images/navYouu.png" alt=""></div>
								</div>
								<div class="centerItem" @click="goConpany(1)">
									<div>组织架构</div>
									<div class="no"><img src="../assets/images/navYou.png" alt=""></div>
									<div class="ac"><img src="../assets/images/navYouu.png" alt=""></div>
								</div>
								<div class="centerItem" @click="goConpany(2)">
									<div>公司荣誉</div>
									<div class="no"><img src="../assets/images/navYou.png" alt=""></div>
									<div class="ac"><img src="../assets/images/navYouu.png" alt=""></div>
								</div>
								<div class="centerItem" @click="goConpany(3)">
									<div>企业文化</div>
									<div class="no"><img src="../assets/images/navYou.png" alt=""></div>
									<div class="ac"><img src="../assets/images/navYouu.png" alt=""></div>
								</div>
								<div class="centerItem" @click="goConpany(4)">
									<div>新闻动态</div>
									<div class="no"><img src="../assets/images/navYou.png" alt=""></div>
									<div class="ac"><img src="../assets/images/navYouu.png" alt=""></div>
								</div>
							</div>
							<div class="right">
								<img :src="index.profileUpimage" alt="">
							</div>
						</div>
						<router-link to="/product"  active-class="activeClass" class="navItem hover">
							<div>产品中心</div>
							<div class="titlee">PRODUCT</div>
							<div class="block"></div>
						</router-link>
						<div class="navBlock">
							<div class="left">
								<div class="img"><img src="../assets/images/logoo.png"></div>
								<div class="p1">{{index.profileTitle}}</div>
								<div class="p2">{{index.profileSlogan}}</div>
							</div>
							<div class="center">
								<div class="centerTitle">产品中心 <span>PRODUCT</span>
									<div class="xuxian"></div>
								</div>
								<div class="centerItem"  @click="gochanpin(item.id)" v-for="(item,i) in chanpin" :key="i">
									<div>{{item.name}}</div>
									<div class="no"><img src="../assets/images/navYou.png" alt=""></div>
									<div class="ac"><img src="../assets/images/navYouu.png" alt=""></div>
								</div>
							</div>
							<div class="right">
								<img :src="index.profileUpimage" alt="">
							</div>
						</div>
						<router-link to="/patent"  active-class="activeClass" class="navItem">
							<div>资质专利</div>
							<div class="titlee">PATENT</div>
							<div class="block"></div>
						</router-link>
						<router-link to="/case"  active-class="activeClass" class="navItem hover">
							<div>工程案例</div>
							<div class="titlee">CASE</div>
							<div class="block"></div>
						</router-link>
						<div class="navBlock">
							<div class="left">
								<div class="img"><img src="../assets/images/logoo.png"></div>
								<div class="p1">{{index.profileTitle}}</div>
								<div class="p2">{{index.profileSlogan}}</div>
							</div>
							<div class="center">
								<div class="centerTitle">工程案例 <span>CASE</span>
									<div class="xuxian"></div>
								</div>
								<div class="centerItem"  @click="goanli(item.id)" v-for="(item,i) in anli" :key="i">
									<div>{{item.name}}</div>
									<div class="no"><img src="../assets/images/navYou.png" alt=""></div>
									<div class="ac"><img src="../assets/images/navYouu.png" alt=""></div>
								</div>
							</div>
							<div class="right">
								<img :src="index.profileUpimage" alt="">
							</div>
						</div>
						<router-link to="/service"  active-class="activeClass" class="navItem hover">
							<div>技术服务</div>
							<div class="titlee">SERVICE</div>
							<div class="block"></div>
						</router-link>
						<div class="navBlock">
							<div class="left">
								<div class="img"><img src="../assets/images/logoo.png"></div>
								<div class="p1">{{index.profileTitle}}</div>
								<div class="p2">{{index.profileSlogan}}</div>
							</div>
							<div class="center">
								<div class="centerTitle">技术服务 <span>SERVICE</span>
									<div class="xuxian"></div>
								</div>
								<div class="centerItem" @click="gojishu(1)">
									<div>售前服务</div>
									<div class="no"><img src="../assets/images/navYou.png" alt=""></div>
									<div class="ac"><img src="../assets/images/navYouu.png" alt=""></div>
								</div>
								<div class="centerItem" @click="gojishu(2)">
									<div>售后服务</div>
									<div class="no"><img src="../assets/images/navYou.png" alt=""></div>
									<div class="ac"><img src="../assets/images/navYouu.png" alt=""></div>
								</div>
								<div class="centerItem" @click="gojishu(3)">
									<div>质量承诺</div>
									<div class="no"><img src="../assets/images/navYou.png" alt=""></div>
									<div class="ac"><img src="../assets/images/navYouu.png" alt=""></div>
								</div>
								<div class="centerItem" @click="gojishu(4)">
									<div>常见问题</div>
									<div class="no"><img src="../assets/images/navYou.png" alt=""></div>
									<div class="ac"><img src="../assets/images/navYouu.png" alt=""></div>
								</div>
								<div class="centerItem" @click="gojishu(5)">
									<div>用户反馈</div>
									<div class="no"><img src="../assets/images/navYou.png" alt=""></div>
									<div class="ac"><img src="../assets/images/navYouu.png" alt=""></div>
								</div>
							</div>
							<div class="right">
								<img :src="index.profileUpimage" alt="">
							</div>
						</div>
						<router-link to="/contact"  active-class="activeClass" class="navItem">
							<div>联系我们</div>
							<div class="titlee">CONTACT US</div>
							<div class="block"></div>
						</router-link>
					</div>
					<div class="phoneItem">
						<div class="img"><img src="../assets/images/dianhuaaa.png" alt=""></div>
						<div class="phoneText">
							<div>咨询电话 HOTLINE</div>
							<div class="color1">0371-27565515 27565525</div>
						</div>
					</div>
				</div>
				<div class="navRight">
					<div class="hbox"><!-- 灰色盒子 -->
						<div class="grey1"></div>
						<div class="lange">
							<img src="../assets/images/lange.png" alt="">
							<span>Language</span>
							<div class="block">
								<div class="c"></div>
							</div>
						</div>
						<div class="grey"></div>
						<ul class="hbox-zzc">
							<!-- 语言的遮罩层 -->
							<li v-for="(item,i) in languageArr" :key="i" @click="qu(item.url)">
								<img :src="item.image" alt="">
								<span>{{item.title}}</span>
							</li>
							<!-- <li><img src="../assets/images/English.png" ></li>
							<li><img src="../assets/images/taiguo.png" ></li>
							<li><img src="../assets/images/bajsit.png"></li>
							<li><img src="../assets/images/alianqiu.png"></li>
							<li><img src="../assets/images/eluos.png" ></li>
							<li><img src="../assets/images/yindu.png"></li> -->
						</ul>
					</div>
					<div class="oboxr" style="z-index:999;" @click="go">
						<img src="../assets/images/ss.png" class="search">
					</div>
				</div>	
				<!-- <ul class="nav-ul">
					<li><a href=""><img src="../assets/images/bannertxt.png" style="width: 4.5rem;"></a></li>
					<li>
						<router-link to="/index" active-class="activeClass">首页<br><span>HOME</span></router-link></li>
					<li>
						<router-link to="/about"  active-class="activeClass">
							走进瑞科<br><span>ABOUT</span>
						</router-link>
						<div class="zjrk-zz">
							<div class="zjrk-zz-l">
								<div>
									<img src="../assets/images/LOGO-b.png">
									<p>{{index.profileTitle}}</p>
									<p>{{index.profileSlogan}}</p>
								</div>
							</div>
							<div class="zjrk-zz-c">
								<p align="left">走进瑞科<small>&ensp;ABOUT</small></p>
								<ul class="zjrk-ul">
									<li>
										<router-link :to="{name:'conpany', query: {i: 0}}" tag="a" >公司简介<small>&gt;</small></router-link>
										
									</li>
									<li>
										<router-link :to="{name:'conpany', query: {i: 1}}" tag="a" >组织架构<small>&gt;</small></router-link>
									</li>
									<li>
										<router-link :to="{name:'conpany', query: {i: 2}}" tag="a" >公司荣誉<small>&gt;</small></router-link>
									</li>
									<li>
										<router-link :to="{name:'conpany', query: {i: 3}}" tag="a" >企业文化<small>&gt;</small></router-link>
									</li>
									<li>
										<router-link :to="{name:'conpany', query: {i: 4}}" tag="a" >新闻动态<small>&gt;</small></router-link>
									</li>
								</ul>
							</div>
							<div class="zjrk-zz-r">
								<img src="../assets/images/hp.png" >
							</div>
						</div>
					</li>
					<li><router-link to="/prolist" active-class="activeClass">产品中心<br><span>PRODUCT</span></router-link></li>
					<li><router-link to="/patent"  active-class="activeClass">资质专利<br><span>PATENT</span></router-link></li>
					<li><router-link to="/case" active-class="activeClass">工程案例<br><span>CASE</span></router-link></li>
					<li><router-link to="/service" active-class="activeClass">技术服务<br><span>SERVICE</span></router-link></li>
					<li><router-link to="/contact" active-class="activeClass">联系我们<br><span>CONTACT US</span></router-link></li>
					<li>
						<a href="">
							<img src="../assets/images/dianhuaaa.png" width="32px" height="28px" style="margin-right: 10px;">
							<p style="text-align: left;">
								<span>咨询电话 HOTLINE</span><br>
								<span style="color: #ff7a2a;">0371-27565515  27565525</span>
							</p>
						</a>
					</li>
				</ul> -->
			</div>
		</div>
	</div>
	<div class="pupop">
		<div class="inputDiv">
			<img  class="guanbi" src="../assets/images/guanbiWhite.png" alt="" @click="guanbi">
			<img src="../assets/images/sousuosousuo.png" alt="" @click="gogogo">
			<input type="text" v-model="value" placeholder="请输入产品关键字" onfocus="this.placeholder=''" onblur="this.placeholder='请输入产品关键字'" @input="input" @keydown="keyss($event)"/>
		</div>
	</div>
		<el-dialog
			:visible.sync="dialogVisible"
			:before-close="handleClose">
			<div style="height: 300px;"></div>
			<div class="inputDiv">
				<img src="../assets/images/sousuosousuo.png" alt="" @click="gogogo">
				<input type="text" v-model="value" placeholder="请输入产品关键字" @input="input" @keydown="keyss($event)"/>
			</div>
		</el-dialog>
  </div>
</template>
<script>
import $ from 'jquery'
	export default {
		data(){
			return{
				show:false,
				
				scroll:'',
				isShow:true,
				navIndex:1,

				dialogVisible:false,
				anli:'',
				chanpin:'',
				languageArr:'',
				value:'',
				index:'',
			}
		},
		created() {
		},
		mounted(){
			window.onscroll = function () {
				this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
				var nav = document.querySelector(".nav-safe")
				if(this.scroll>0){
					nav.style.backgroundColor = 'rgba(35,88,132,1)'
					nav.style.transition = '0.3s'
					document.querySelector(".obox").style.backgroundColor = 'rgba(204,102,51,1)'
					document.querySelector(".obox").style.transition = '0.3s'
				}else{
					nav.style.backgroundColor = 'rgba(35,88,132,0.7)'
					nav.style.transition = '0.3s'
					document.querySelector(".obox").style.backgroundColor = 'rgba(204,102,51,0.7)'
					document.querySelector(".obox").style.transition = '0.3s'
				}
			}
			this.getData()
			this.getLanguage()
			this.getIndex()
		},
		methods: {
			getIndex(){
				this.$http.get("https://admin.ruikefamen.com/api/index/index")
				.then(res=>{
					if(res.data.code == 1){
						this.index = res.data.data
					}
				})
			},
			handleClose(){
				this.dialogVisible = false
			},
			qu(url){
				window.open(url);
			},
			gogogo(){
				let value = this.value
				$(document).unbind("scroll.unable");
				$('.pupop').fadeToggle()
				this.$router.push({path:'/prolist',query:{value:value}});
			},
			input(){
				sessionStorage.setItem('value',this.value)
			},
			keyss(e){//键盘事件
				if(e.keyCode == 13){
					let value = this.value
					$(document).unbind("scroll.unable");
					this.$router.push({path:'/prolist',query:{value:value}});
				}	
			},
			gojishu(i){
				this.$router.push({
					path:'/service',
					query:{
						i:i
					}
				})
			},
			gochanpin(id){
				this.$router.push({
					path:'/product',
					query:{
						id:id
					}
				})
			},
			goanli(id){
				this.$router.push({
					path:'/case',
					query:{
						id:id
					}
				})
			},
			getLanguage(){
				this.$http.get("https://admin.ruikefamen.com/api/index/index_language")
				.then(res=>{
					if(res.data.code == 1){
						this.languageArr = res.data.data
					}
				})
			},
			getData(){
				this.$http.get("https://admin.ruikefamen.com/api/index/index_data")
				.then(res=>{
					this.anli = res.data.data.case_type
					this.chanpin = res.data.data.category_product
				})
			},
			goConpany(i){
				this.$router.push({
					path:'/about',
					query:{i:i}
				})
			},
			goUrl(url,i){
				this.$router.push({
					path:url,
				})
				this.navIndex = i
			},
			guanbi(){
				$('.pupop').fadeToggle()
				$(document).unbind("scroll.unable");
			},
			go(){
				$('.pupop').fadeToggle()
				var top=$(document).scrollTop();
					$(document).on('scroll.unable',function (e){
					$(document).scrollTop(top);
				})
				// this.dialogVisible = true
				// this.$router.push({
				// 	path:'/product',
				// })	
			},
		},
		
	}
</script>
<style scoped="scoped" lang="less">
.pupop{
	width: 100vw;
	height: 100vh;
	display: none;
	background-color: rgba(0, 0, 0, 0.65);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
}
.inputDiv{
	width: 836px;
	margin-top: 300px;
	margin: 0 auto;
	border-bottom: 1px solid #FFFFFF;
	position: absolute;
	top: 40%;
	left: 50%;
	transform: translate(-50%,-50%);
	.guanbi{
		width: auto;
		height: auto;
		top: -100px;
		right: -50px;
	}
	input{
		color: #fff;
		width: 100%;
		height: 40px;
		border: none;
		outline-style: none;
		border: none !important;
		background: none;
		font-size: 16px;
	}
	img{
		width: 18px;
		height: 18px;
		display: block;
		cursor: pointer;
		position: absolute;
		right: 0px;
		top: 50%;
		transform: translateY(-50%);
	}	
}
img{
	width: 100%;
	height: 100%;
	display: block;
}
.activeClass{
	color: #FF7A2A !important;
	.block{
		display: block !important;
	}
}
	// 头部导航
	#app{
		width: 1920px;
		min-width: 1400px;
		position: fixed;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		z-index: 999;
	}
	.obox{
		// 左边橘色块
		width: 242px;
		height: 88px;
		position: absolute;
		margin-left: -16px;
		z-index: 999;
		background-color: rgba(204,102,51,0.85);
		transform: skew(-20deg);
	}
	.navDiv{
		padding-left: 116px;
		padding-top: 20px;
		position: absolute;
		z-index: 99;
	}
	.nav-safe{
		width: 100%;
		height: 90px;
		background-color: rgba(35,88,132,0.7);
		display: flex;
		justify-content: space-between;
		transform: skew(-20deg);
		.navList{
			width: 1440px;
			padding-left: 145px;
			font-size: 16px;
			color: #fff;
			transform: skew(20deg);
			display: flex;
			align-items: center;
			.img{
				width: 191px;
			}
			// .linkList:hover .navBlock{
			// 	transform: scaleY(1);
			// 	opacity: 1;
			// 	transition: 0.5s;
			// }
			.linkList{
				width: 864px;
				padding-left: 80px;
				padding-right: 80px;
				height: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				.navBlock{
					color: #666666;
					width: 100%;
					height: 337px;
					padding-top: 70px;
					display: flex;
					justify-content: space-evenly;
					background: #F3F3F3;
					position: absolute;
					top: 90px;
					left: -18px;
					transform: scaleY(0);
					opacity: 0;	
					transform-origin:top;
					.left{
						.img{
							padding-left: 37px;
							img{
								width: 191px;
							}
						}
						.p1{
							font-size: 28px;
							color: #333;
							margin: 36px 0;
							padding-left: 37px;
							border-left: 2px solid #B45627;
						}
						.p2{
							font-size: 14px;
							padding-left: 37px;
							color: #666;
						}
					}
					.center{
						width: 235px;
						color: #666;
						.centerTitle{
							font-size: 16px;
							font-weight: 700;
							padding-bottom: 12px;
							margin-bottom: 14px;
							position: relative;
							border-bottom: 0.5px solid #b5b5b5;
							// .xuxian{
							// 	width: 100%;
							// 	height: 1px;
							// 	position: absolute;
							// 	bottom: 0;
							// 	background: url(../assets/images/xuxian.png);
							// 	background-size: 100%;
							// }
							span{
								font-size: 11px;
								font-family: arr;
								font-weight: 100;
							}
						}
						.centerItem{
							font-size: 14px;
							line-height: 36px;
							font-family: ar;
							display: flex;
							justify-content: space-between;
							align-items: center;
							cursor:pointer;
							img{
								width: 5px;
							}
							.ac{
								display: none;
							}
						}
						.centerItem:hover{
							color: #FF7A2A;
							// .no{
							// 	display: none;
							// }
							// .ac{
							// 	display: block;
							// }
						}
					}
					.right{
						width: 300px;
						height: 200px;
					}
				}
				.titlee{
					font-size: 11px;
					transform: scale(0.9);
				}
				.navItem:hover{
					color: #FF7A2A;
				}
				.navItem{
					color: #fff;
					display:inline-block;
					width: 90px;
					height: 100%;
					padding: 25px 0;
					text-align: center;
					line-height: 20px;
					cursor:pointer;
					position: relative;
					.block{
						width: 70%;
						height: 2px;
						background: #FF7A2A;
						position: absolute;
						bottom: 20px;
						left: 50%;
						transform: translateX(-50%);
						display: none;
					}
				}
				.hover{
					position: relative;
				}
				.hover:hover + .navBlock{
					transform: scaleY(1);
					opacity: 1;
					transition: 0.5s;
				}
				.navBlock:hover{
					transform: scaleY(1);
					opacity: 1;
					transition: 0.5s;
				}
			}
			.phoneItem{
				margin-right: 10px;
				display: flex;
				align-items: center;
				font-size: 15px;
				.img{
					width: 32px;
					height: 28px;
					margin-right: 10px;
				}
				.color1{
					font-size: 14px;
					color: #FF7A2A;
				}
			}
		}
		.navRight{
			display: flex;
			justify-content: flex-end;
			.hbox{
				width: 167px;
				height: 100%;
				position: relative;
				background-color: #999999;
				// transform: skew(-22deg);
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				.grey{
					height: 100%;
					position: absolute;
					width: 4px;
					background: #999999;
					right: -4px;
					top: 0;
				}
				.grey1{
					height: 100%;
					position: absolute;
					width: 4px;
					background: #999999;
					left: -2px;
					top: 0;
				}
				.lange{
					display: flex;
					align-items: center;
					justify-content: space-evenly;
					transform: scale(0.8) skew(20deg);
					color: #FFFFFF;
					font-size: 16px;
					img{
						width: 31.25px ;
						margin-right: 10px;
					}
					.block{
						position: relative;
						width: 18px;
						height: 9px;
						margin-top: 5px;
						margin-left: 5px;
						overflow: hidden;
						div{
							width: 13px;
							height: 13px;
							background: #fff;
							transform: rotate(45deg);
							position: absolute;
							bottom: 4px;
						}
					}
				}
				.hbox-zzc{
					width: 168px;
					padding: 17px 0;
					position: absolute;
					background-color: #171819;
					top: 90px;
					left: -2px;
					transform: skew(20deg);
					transform: scaleY(0);
					transform-origin: top;
					>li{
						width: 165px;
						padding: 10px 21px;
						display: flex;
						align-items: center;
						font-size: 14px;
						color: #fff;
						>img{
							height: 21px;
							width: auto;
							transform: skew(0deg);
							margin-right: 8px;
						}
					}
				}
			}
			.oboxr{
				position: relative;
				width: 205px;
				margin-right: -17px;
				background-color: #E7601C;
				transform: skew(0);
				z-index: -1;
				cursor:pointer;
				img{
					width: 24px;
					height: auto;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%) skew(22deg);
				}
			}
		}

		.hbox:hover{
			.hbox-zzc{
				// display: block;
				transform: scaleY(1) skew(20deg);
				transition: 0.5s;
				li:hover{
					// background-color: rgba(0,0,0,0.4);
				}
			}
		}
		>.nav-ul{
			/* 导航 */
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			margin: 0 auto;
			text-align: center;
			transform: skew(20deg);
			>li{
				height: 100%;
				a,router-link{
					display: block;
					width: 2.58rem;
					height: 1.265007rem;
					box-sizing: border-box;
					font-size: 0.393558rem;
					color: #fff;
					>span {
						font-size: 0.22489rem;
						margin-top: -0.056222rem;
						color: #fff;
					}
					
				}
				>a:hover,
				>router-link:hover,.activeClass{
						color: #cc6633;
						border-bottom: 2px solid #CC6633;
						span{
							color:#cc6633;
						}
					}
			}
			>li:nth-child(3):hover{
				.zjrk-zz{
					transform: scaleY(1);
					opacity: 1;
					transition: 0.5s;
				}
				>a{
					color: #cc6633;
					border-bottom: 2px solid #CC6633;
					span{
						color:#cc6633;
					}
				}
				
			}
			// logo
			>li:first-child{
				width: 4.9rem;
				a:hover{
					border-bottom: none;
				}
			}
			// 联系我们
			>li:last-child{
				a{
					margin-top: -5px;
					margin-left: 0.4rem;
					margin-right: 0.15rem;
					width: 6.5rem;
					display: flex;
					align-items: center;
				}
				a:hover{
					border-bottom: none;
				}
				img{
					width: 0.955783rem;
					height: 0.787116rem;
				}
				p{
					font-size: 0.337335rem;
				}
				p span:first-child{
					font-size: 0.393558rem;
					color: #fff;
				}
			}
			li:nth-child(3){
				/* 走进瑞科 遮罩层 */
				.zjrk-zz{
					width: 30.39rem;
					height: 8.5rem;
					background-color: #fff;
					position: absolute;
					top: 2.2rem;
					left: -1.36rem;
					display:flex;
					transform: scaleY(0);
					opacity: 0;	
					transform-origin: top;
					justify-content: space-between;
					
					.zjrk-zz-l{
						width: 12rem;
						height: 100%;
						margin-left: 2rem;
						padding: 2rem 0.703703rem;
						>div{
							display: flex;
							flex-direction: column;
							align-items: center;
							flex-wrap: nowrap;
							p:nth-child(2){
								font-size: 0.518518rem;
								margin: 0.74074rem 0;
							}
							p:nth-child(3){
								font-size: 0.259259rem;
							}
						}
					}
					.zjrk-zz-c{
						margin-left: 0rem;
						width: 10rem;
						height: 100%;
						padding: 1.5rem 0rem;
						p{
							font-size: 0.44978rem;
							font-weight: 900;
							color: #666;
							border-bottom: 1px solid #666;
							padding-bottom: 0.28rem;
							margin: 0 0.55rem;
							small{
								color: #666;
								font-size: 0.337335rem;
							}
						}
						.zjrk-ul>li{
							height: 0.85rem;
							display: flex;
							line-height: 0.74074rem;
							:hover{
								color: #cc6633;
							}
							a{
								margin-top: 0;
								font-size: 0.393558rem;
								color: #666;
								display: block;
								width: 100%;
								height: 100%;
								text-align: left;
								padding: 0 0.555555rem;
								small{
									font-family: 'SimSun';
									color: #666;
									float: right;
								}
							}
							
						}
					}
					.zjrk-zz-r{
						height: 100%;
						padding: 1.5rem 1.7rem;
						margin-left: 0.925925rem;
						img{
							width: 100%;
							height: 100%;
						}
					}
				}

			}
		}
	}
	/deep/input::-webkit-input-placeholder {
		color: #fff;
		font-size: 18px;
	}
	/deep/.el-dialog__wrapper{
		background-color: rgba(0, 0, 0, 0.65);
		overflow: scroll;
	}
</style>