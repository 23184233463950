import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/index.vue'
import Conpany from '../views/Conpany.vue'
import ProList from '../views/ProList.vue'
import Project from '../views/Project.vue'
import Patent from '../views/Patent.vue'
import Prodsc from '../views/Prodsc.vue'
import Callme from '../views/Callme.vue'
import Newsdsc from '../views/Newsdsc.vue'
import Technology from '../views/technology.vue'
import Free from "../components/free.vue"
import Map from "../components/map.vue"
import Advant from "../components/advantage.vue"
import Swiper from "../components/swiper.vue"
// import $ from 'jquery' 
Vue.use(VueRouter)
const routes = [
  {
    name: 'default',
    path: '*',
    redirect: '/index'
  },
  {
    path: '/index',
    name: 'index',
    component: Index,
  },
  {
    path: '/about',
    name: 'about',
    component: Conpany,
  },
  {
    path: '/case',
    name: 'case',
    component: Project
  },
  {
    path: '/product',
    name: 'product',
    component: ProList
  },
  {
    path: '/prodsc',
    name: 'prodsc',
    component: Prodsc
  },
  {
    path: '/newsdsc',
    name: 'newsdsc',
    component: Newsdsc
  },
  {
    path: '/service',
    name: 'service',
    component: Technology
  },
  {
    path: '/patent',
    name: 'patent',
    component: Patent
  },
  {
    path: '/contact',
    name: 'contact',
    component: Callme
  },
]
const router = new VueRouter({
  mode: 'history', // 访问路径不带#号
  // base: '/pc',  // 配置单页应用的基路径
  routes
})
router.beforeEach((to, from, next) => {
     // 让页面回到顶部
     document.documentElement.scrollTop = 0
     // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
    next()
 })
 //解决重复点击路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
 return originalPush.call(this, location).catch(err => err)
}
export default router
