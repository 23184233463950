<template>
	<!-- 工程案例 -->
	<div id="projetc">
		<Nav></Nav>
		<div class="banner">
			<img src="../assets/images/6.png" class="wow animate__animated animate__slideInDown animate__delay-0.5s">
		</div>
		<div style="width: 100%;background-color: #f6f8f9;">
			<div class="nav">
				<div class="nav-cart wow animate__animated animate__slideInDown animate__delay-0.5s">
					<!-- 产品分类 -->
					<div :class="{active:index==cartIndex}" class="nav-cart-item" v-for="(item,index) in cases"
						@click="cartShow(index,item.id,item.name,item.english)" :key='cases[index].id'>
						<p>{{cases[index].name}}</p>
					</div>
				</div>
			</div>
		</div>
		
		<!-- 工程案例part -->
		<div class="cases">
			<div class="titleBg">
			</div>
			<div class="cases-title wow animate__animated animate__slideInUp animate__delay-0.5s">
				<span>{{activeTitle}}</span>
				<span>案例</span>
				<p class="fontFZ">{{EactiveTitle}} CASES</p>
			</div>
			<div class="cases-main patent-title" v-if="cases[cartIndex]">
				<p v-html="cases[cartIndex].detail" style="padding: 0 0.8rem;">{{cases[cartIndex].detail}}</p>
				<table cellspacing="0" class="cases-table">
					<tr>
						<th style="width: 300px;">客户名称</th>
						<th style="width: 500px;">工程项目</th>
						<th style="width: 600px;">供货所用阀门</th>
					</tr>
					<tr v-for="(item,index) in caseList" :key="index">
						<td>{{item.username}}</td>
						<td>{{item.ojbname}}</td>
						<td>{{item.famen}}</td>
					</tr>
				</table>
				<div v-if="caseList.length<=0" style="line-height: 40px;text-align: center;color: #999;margin-bottom: 80px;">
					暂无案例信息
				</div>
			</div>
		</div>
		
		<advantage style="margin-bottom: 3.5rem;" class="wow animate__animated animate__slideInUp animate__delay-0.5s"></advantage>
		<free class="wow animate__animated animate__slideInUp animate__delay-0.5s"></free>
		<Footer></Footer>
	</div>
</template>

<script>
	import Nav from "../components/nav.vue"
	import Footer from "../components/footer.vue"
	import free from "../components/free.vue"
	import WOW from 'wowjs'
	import advantage from "../components/advantage.vue"
	import $ from 'jquery'
	export default{
		data(){
			return{
				cartIndex: 0,
				cases:[],// 小标题数据
				table:[],// 工程案例表格数据
				caseList:[],// 工程案例表
				a:0,
				activeTitle:'',
				EactiveTitle:'',
			}
		},
		components:{
			Nav,
			Footer,
			free,
			advantage
		},
		watch:{
			$route(){
				if(this.$route.query.id){
					this.getCases()
				}else{
				}
			},
		},
		created() {
			var _this = this
			this.getCases();
			_this.$apis.get(_this.$apis.inside_banner).then(res=>{
				$('.banner').css('background','url('+res.data.data.Case+')')
				$('.banner').css('backgroundSize','100% 100%')
			})
		},
		methods:{
			cartShow(index,id,name,english) {
				this.cartIndex = index
				this.activeId = id
				this.activeTitle = name
				this.EactiveTitle = english
				this.getTable();
			},
			getCases(){
				var _this = this;
				_this.$apis.get(_this.$apis.cases).then(function(res) {
					_this.cases = res.data.data
					if(_this.$route.query.id){
						for(let i=0;i<res.data.data.length;i++){
							if(_this.$route.query.id == res.data.data[i].id){
								_this.cartIndex = i
								_this.activeId = _this.$route.query.id
								_this.activeTitle = res.data.data[i].name
								_this.EactiveTitle = res.data.data[i].english
							}
						}
					}else{
						_this.cartIndex = 0
						_this.activeId = res.data.data[0].id
						_this.activeTitle = res.data.data[0].name
						_this.EactiveTitle = res.data.data[0].english
					}
					_this.getTable();
				})
			},
			getTable(){
				var _this = this;
				_this.$apis.get(_this.$apis.casesList,{
					params:{id:this.activeId}
				}).then(function(res) {
					_this.caseList = res.data.data
				})
			}
		},
		mounted() {
			new WOW.WOW().init();
		}
	}
</script>

<style scoped="scoped" lang="less">
.cases{
	position: relative;
}
.titleBg{
	width: 1014px;
	height: 297px;
	background: url(../assets/images/bg4.png);
	background-size: 100% 100%;
	position: absolute;
	top: 70px;
	left: 50%;
	transform: translateX(-50%);
	z-index: -1;
}
	.banner{
		position: relative;
		width: 100%;
		height: 500px;
		text-align: center;
		// background: url(../assets/images/Engineeringcase.png) no-repeat center center / cover;
		img{
			height: 90px;
			width: auto;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			top: 100px;
			margin: auto;
		}
	}
	.nav{
		width: 100%;
		height: 80px;
		padding: 0 200px;
		margin: 0 auto;
		background-color: #f6f8f9;
		.nav-cart {
			width: 1250px;
			height: 80px;
			line-height: 80px;
			margin: 0 auto;
			display: flex;
			.nav-cart-item {
				background-color: #d1d1d1;
				width: 250px;
				height: 100%;
				font-size: 18px;
				text-align: center;
				cursor: pointer;
				transform: skew(-25deg);
				p {
					color: #666;
					transform: skew(25deg);
				}
				p:nth-child(3) {
					font-size: 0.337335rem;
				}
			}
			.active {
				background-color: #1c5380;
				p{
					color: #fff;
				}
			}
		}
	}
	// 工程案例part
	.cases{
		width: 100%;
		.cases-title{
			min-width: 1400px;
			// 售后服务title
			text-align: center;
			padding: 60px 0;
			font-size: 38px;
			span:nth-child(1) {
				color: #cc6633;
				font-weight: 900;
			}
			span:nth-child(2) {
				color: #1C5380;
				font-weight: 900;
			}
			p {
				font-size: 24px;
				font-weight: 100;
			}
		}
		.cases-main{
			width: 1400px;
			margin: 0 auto;
			font-size: 18px;
			line-height: 36px;
		}
		.cases-table{
			width: 1400px;
			margin: 0 auto;
			text-align: left;
			border: none;
			margin-top: 58px;
			margin-bottom: 114px;
			text-indent: 10px;
			background-color: #efefef;
			tr{
				height: 41px;
			}
			th{
				background-color: #266193;
				color: #fff;
				border-left: 1px solid #fff;
				border-bottom: 1px solid #fff;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}
			th:nth-child(1),td:nth-child(1){
				border-left: none;
			}
			td{
				border-left: 1px solid #fff;
				border-bottom: 1px solid #fff;
				font-size: 16px;
			}
		}
	}
	.fontFZ{
			font-family: fz;
			color: #666 !important;
		}
</style>
