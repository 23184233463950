<template>
	<div id="callme">
		<Nav></Nav>
		<div class="banner">
			<img src="../assets/images/3.png" class="wow animate__animated animate__slideInDown animate__delay-0.5s">
		</div>
		<!-- 联系我们 -->
		<div class="contact-us">
			<div class="contact-title wow animate__animated animate__slideInUp animate__delay-0.5s">
				<span>联系</span>
				<span>我们</span>
				<p style="font-family: 'fz'">CONTACT US</p>
				<p>您可以通过以下方式联系到我们</p>
			</div>
			
			<!-- 名片 -->
			<div class="contact-card wow animate__animated animate__slideInUp animate__delay-0.5s">
				<div class="card-logo">
					<img src="../assets/images/callLogo.png" >
				</div>
				<ul class="card-main">
					<li>名称：开封瑞科阀门有限公司</li>
					<li>地址：{{contact.steamAddress}}</li>
					<li>电话：<span>{{contact.steamHotline}}</span></li>
					<li>传真：{{contact.steamFax}}</li>
					<li>邮编：{{contact.steamPostcode}}</li>
					<li>网址：{{contact.steamUrl}}</li>
					<li>邮箱：{{contact.steamEmail}}</li>
				</ul>
			</div>
			
			<!-- 销售部联系方式 -->
			<div class="salebox">
				<div class="box1 wow animate__animated animate__fadeInUp animate__delay-0.5s" v-for="(item,index) in aboutus" :key="index">
					<p>{{aboutus[index].name}}</p>
					<p>电 话 ：{{aboutus[index].telnumber}}</p>
					<p>手 机：{{aboutus[index].phone}}</p>
					<p>传 真：{{aboutus[index].fax}}</p>
					<p>邮 箱：{{aboutus[index].email}}</p>
					<p>微 信：{{aboutus[index].wechat}}</p>
				</div>
			</div>
		</div>
		
		<Map style="margin: -1.4rem 0 4.2rem 0;"></Map>
		
		<Footer></Footer>
	</div>
</template>

<script>
	import Nav from "../components/nav.vue"
	import Footer from "../components/footer.vue"
	import Map from "../components/map.vue"
	import WOW from 'wowjs'
	import $ from 'jquery'
	export default{
		data(){
			return{
				aboutus:[],
				contact:[],
			}
		},
		components:{
			Nav,
			Footer,
			Map
		},
		created() {
			var _this = this
			this.getAboutus();
			this.getContact();
			_this.$apis.get(_this.$apis.inside_banner).then(res=>{
				$('.banner').css('background','url('+res.data.data.Contact+')')
				$('.banner').css('backgroundSize','100% 100%')
			})
		},
		methods:{
			getAboutus(){
				this.$http.get("https://admin.ruikefamen.com/api/index/aboutus")
				.then(res=>{
					this.aboutus = res.data.data
				})
			},
			getContact(){
				this.$http.get("https://admin.ruikefamen.com/api/index/index")
				.then(res=>{
					this.contact = res.data.data
				})
			}
		},
		mounted() {
			new WOW.WOW().init();
		},
	}
</script>

<style lang="less" scoped="scoped">
	#callme{
		width: 100%;
		min-width: 1400px;
		.banner{
			position: relative;
			width: 100%;
			height: 500px;
			text-align: center;
			// background: url(../assets/images/Engineeringcase.png) no-repeat center center / cover;
			img{
				height: 90px;
				width: auto;
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				top: 100px;
				margin: auto;
			}
		}
		.contact-us{
			width: 100%;
			height: 1180px;
			background-color: pink;
			background: url(../assets/images/contact1.png) no-repeat top center / contain;
			.contact-title{
				// 售后服务title
				text-align: center;
				padding: 80px 0;
				padding-bottom: 0;
				font-size: 38px;
				span:nth-child(1) {
					color: #cc6633;
					font-weight: 900;
				}
				span:nth-child(2) {
					color: #1C5380;
					font-weight: 900;
				}
				p:nth-child(3){
					font-size: 24px;
					font-weight: 100;
				}
				p:nth-child(4){
					font-size: 24px;
					margin-top: 1rem;
					color: #666;
				}
			}
			.contact-card{
				width: 733px;
				height: 337px;
				background-color: #fff;
				border: 1px solid #eaeaea;
				margin: 73px auto 36px;
				margin-bottom: 118px;
				display: flex;
				.card-logo{
					width: 256px;
					height: 148px;
					margin-top: 94px;
					text-align: center;
					line-height: 148px;
					border-right: 1px solid #eaeaea;
					img{
						width: 115px;
						height: 93px;
						vertical-align: middle;
					}
				}
				.card-main{
					display: flex;
					flex-direction: column;
					justify-content: center;
					li{
						width: 100%;
						margin-left: 70px;
						min-height: 36px;
						font-size: 14px;
						span{
							color: #cc6633;
							font-size: 30px;
							font-weight: 900;
						}
					}
					li:nth-child(3){
						margin-top: -10px;
						margin-bottom: 10px;
					}
				}
			}
			.salebox{
				width: 1400px;
				margin: 0 auto;
				display: flex;
				justify-content: space-between;
				.box1{
					width: 405px;
					height: 286px;
					background-color: #eaeaea;
					padding: 27px;
					font-size: 30px;
					margin-bottom: 155px;
					p{
						line-height: 36px;
						font-size: 15px;
					}
					p:nth-child(1){
						font-size: 30px;
						margin-bottom: 17px;
					}
				}
			}
		}
	}
	/deep/ .BMap_bubble_content{
		
	}
</style>
