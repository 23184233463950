<template>
	<div id="technology" style="background: #fff;">
		<Nav></Nav>
		<div class="banner">
			<img src="../assets/images/4.png" class="wow animate__animated animate__slideInDown animate__delay-0.5s">
		</div>
		<div style="background-color: #fff;">
		<div class="nav">
			<div class="nav-cart wow animate__animated animate__slideInDown animate__delay-0.5s">
				<!-- 产品分类 -->
				<div :class="{active:index==cartIndex}" class="nav-cart-item " v-for="(item,index) in cartList"
					@click="cartShow(index)" :key='index'>
					<p>{{item.name}}</p>
				</div>
			</div>
		</div>
		
		<!-- 售前服务part -->
		<div class="tech-main" v-show="cartIndex == 0">	
			<div type="flex" justify="center" style="width: 1400px;margin: 0 auto;">
				<!-- gutter栅格距离  默认为0 -->
				<div>
					<div class="tech-title wow animate__animated animate__slideInUp animate__delay-0.5s">
						<span>售前</span>
						<span>服务</span>
						<p class="fontFZ">QUALIFICATION PATENT</p>
					</div>
				</div>
			</div>
			
			<!-- 售前服务 介绍 -->
			<div class="tech-sqfw">
				<div style="position: relative;overflow: hidden;"><img :src="saleDsc.saleImage" style="position: absolute;top: 0;left: 0;width: 100%;height: auto;"></div>
				<div>
					<p class="wow animate__animated animate__slideInUp animate__delay-0.5s" v-html="saleDsc.saleContent" >{{saleDsc.saleContent}}</p>
				</div>
			</div>
			
			<div class="tech-liuc">
				<div class="tech-title wow animate__animated animate__slideInUp animate__delay-0.5s">
					<span>询价、订货流程</span>
				</div>
				<div><img :src="saleDsc.saleServiceImage" class="wow animate__animated animate__fadeIn animate__delay-0.5s"></div>
			</div>
			<free class="wow animate__animated animate__slideInUp animate__delay-0.5s"></free>
		</div>
		
		
		<!-- 售后服务 part -->
		<div class="after-sale" v-show="cartIndex == 1">
			<el-row type="flex" justify="center">
				<!-- gutter栅格距离  默认为0 -->
				<el-col :xs="16" :sm="16" :md="18" :lg="20" :xl="24">
					<div class="after-title">
						<span>售后</span>
						<span>服务</span>
						<p class="fontFZ">AFTER-SALE SERVICE</p>
					</div>
				</el-col>
			</el-row>
			
			<!-- 售后服务 介绍 -->
			<div class="after-shfw">
				<div style="position: relative;overflow: hidden;"><img :src="saleAfterDsc.saleImage" style="position: absolute;top: 0;left: 0;width: 100%;height: auto;"></div>
				<div><p v-html="saleAfterDsc.aftersaleContent">{{saleAfterDsc.aftersaleContent}}
</p></div>
			</div>
			
			<div class="after-liuc">
				<div class="after-title">
					<span>售后服务流程</span>
				</div>
				<div><img :src="saleAfterDsc.serviceImage" ></div>
			</div>
			<free class="wow animate__animated animate__slideInDown animate__delay-0.5s"></free>
		</div>
		
		<!-- 质量承诺part -->
		<div class="zhil-main" v-show="cartIndex == 2">
			<div class="zhil-title">
				<span>质量</span>
				<span>承诺</span>
				<p class="fontFZ">QUALITY COMMITMENT</p>
			</div>
					
			<!-- 质量承诺 介绍 -->
			<div class="zhil-shfw">
				<p v-html="quality.quality">{{quality.quality}}
</p>
			</div>
			<free class="wow animate__animated animate__slideInDown animate__delay-0.5s"></free>
			
		</div>
		
		<!-- 常见问题 part -->
		<div class="question" v-show="cartIndex == 3">
			<div class="ques-title">
				<span>常见</span>
				<span>问题</span>
				<p class="fontFZ">COMMON PROBLEM</p>
			</div>

			<div class="newsDetail" v-if="xiangqingShow">
				<p  style="margin-bottom: 36px;">{{detailDetail.name}}</p>
				<div class="title" style="display: flex;align-items: center;justify-content: center;">
					<p>
						发布日期：{{detailDetail.dataTime[0]}}-{{detailDetail.dataTime[1]}}-{{detailDetail.dataTime[2]}}&nbsp;{{detailDetail.dataTime[3]}}&emsp;点击率：{{detailDetail.click}}&emsp;
					</p>
					<div class="fenxiang">
						<div style="margin-right: 5px;">分享</div>
						<vshare></vshare>
					</div>
				</div>
				<p v-html="detailDetail.content" class="newspictxt">{{detailDetail.content}}</p>
				<!-- 上一篇 & 下一篇 -->
				<div class="news-l-next">
					<div style="text-align: left;" @click="qiehuan(detailDetail.up.id)"><span>上一篇：</span>{{detailDetail.up?detailDetail.up.name:'没有上一篇了'}}</div>
					<div class="xian"></div>
					<div style="text-align: right;" @click="qiehuan(detailDetail.down.id)"><span>下一篇：</span>{{detailDetail.down?detailDetail.down.name:'没有下一篇了'}}</div>
				</div>
			</div>

			<div class="ques-list" v-else>
				<div class="ques-item" v-for="(item,index) in question.data" :key="index" @click="detailShow(item.id)">
					<div class="ques-item-l" >
						<p>{{item.name}}</p>
						<div>
							<p v-html="item.content">{{item.content}}</p>
							<div class="xiangqing"><span> ... </span>[详情]</div>
						</div>
						<!-- <router-link to="" tag="p"><span style="color: #cc6633;">[详情]</span></router-link> -->
					</div>
					<div class="ques-item-r">
						<p>{{item.dataTime[2]}}</p>
						<p>{{item.dataTime[0]}}.{{item.dataTime[1]}}</p>
					</div>
					
				</div>
				<!-- 页码 -->
				<div class="ques-yema">
					<el-pagination background layout="prev, pager,next" 
					@current-change="pagesChange"
					:total="total"
					:current-page.sync="page"
					:page-size="pageSize"
					prev-text="首页" 
					next-text="尾页"
					@prev-click="toHead"
					@next-click="currentPage = lastpage"
					>
					</el-pagination>	
				</div>
			</div>
			
			
			<free class="wow animate__animated animate__slideInDown animate__delay-0.5s"></free>
		</div>
		
		<!-- 用户反馈part -->
		<div class="user-feedback" v-show="cartIndex == 4">
			<div class="user-title">
				<span>用户</span>
				<span>反馈</span>
				<p class="fontFZ">USER FEEDBACK</p>
			</div>
			
			<div class="user-table">
				<div class="teble">
					<div class="table-l">
						<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
							<el-form-item prop="name">
								<el-input v-model="ruleForm.name" placeholder="您的姓名："></el-input>
							</el-form-item>
							
							<el-form-item prop="address">
								<el-input v-model="ruleForm.address" placeholder="联系地址："></el-input>
							</el-form-item>
							
							<el-form-item prop="company">
								<el-input v-model="ruleForm.cname" placeholder="公司名称："></el-input>
							</el-form-item>
							
							<el-form-item prop="tel">
								<el-input v-model="ruleForm.phone" placeholder="电        话："></el-input>
							</el-form-item>
							
							<el-form-item prop="email">
								<el-input v-model="ruleForm.email" placeholder="联系邮箱："></el-input>
							</el-form-item>
						</el-form>
					</div>
					<div class="table-r">
						<el-form>
							<el-form-item prop="desc">
								<el-input type="textarea" v-model="ruleForm.content" placeholder="留言内容" class="resizeNone"></el-input>
							</el-form-item>
						</el-form>
					</div>
				</div>
				<div class="table-btn">
					<button @click="tijiao">提交</button>
					<button @click="resetForm('ruleForm')">重置</button>
					
					
				</div>
			</div>
			
		</div>
		
		<Footer></Footer>
		</div>
		
	</div>
</template>

<script>
	import Nav from "../components/nav.vue"
	import Footer from "../components/footer.vue"
	import free from "../components/free.vue"
	import advantage from "../components/advantage.vue"
	import WOW from 'wowjs'
	import $ from "jquery"
	export default{
		data(){
			return{
				detailDetail:'',
				xiangqingShow:false,
				lastpage:0,
				page:1,// 当前页数
				total:0,// 总条数
				pageSize:4,// 显示条数
				currentPage: 1,
				saleDsc:[],// 售前服务数据
				saleAfterDsc:[],// 售后服务数据
				quality:[],// 质量承诺
				question:[],// 常见问题
				cartIndex: 0,
				cartList: [{name:'售前服务'},{name:'售后服务'},{name:'质量承诺'},{name:'常见问题'},{name:'用户反馈'}],
				ruleForm: {
					name: '',
					address:'',//联系地址
					cname:'',//公司名称
					phone:'',//电话
					content:''//留言内容
				},
				rules: {
					name: [
						{ required: true, message: '请输入您的名字', trigger: 'blur' },
					],
					address: [{ required: false},],
					cname: [{ required: false},],
					email: [{ required: false},],
					content: [{ required: false},],
					phone: [
						{ required: true, message: '请输入电话号码', trigger: 'blur' },
					],
				}
			}
		},
		components: {
			Nav,
			Footer,
			free,
			advantage
		},
		watch:{
			$route(){
				if(this.$route.query.i){
					this.cartIndex = this.$route.query.i-1
				}else{
				}
			},
		},
		mounted() {
			new WOW.WOW().init();
			if(this.$route.query.i){
				this.cartIndex = this.$route.query.i-1
			}
		},
		created() {
			var _this = this
			this.getSale();
			this.getSaleAfter();
			this.getQuality();
			this.getQuestion();
			_this.$apis.get(_this.$apis.inside_banner).then(res=>{
				$('.banner').css('background','url('+res.data.data.Service+')')
				$('.banner').css('backgroundSize','100% 100%')
			})
		},
		methods:{
			qiehuan(id){
				this.detailShow(id)
			},
			//显示问题详情
			detailShow(id){
				var _this = this;
				this.xiangqingShow = true
				_this.$apis.get(_this.$apis.question_detail,{params:{id:id}}).then(function(res) {
					if(res.data.code == 1){
						res.data.data.dataTime = res.data.data.createtime.split('-')
						_this.detailDetail = res.data.data
						$("html,body").animate({"scrollTop":"0"},500)
					}
				})
			},
			tijiao(){
				var _this = this;
				_this.$apis.post(this.$apis.userbackFrom,this.ruleForm).then(res=>{
					if(res.data.code == 1){
						_this.$message({
							message: '反馈成功',
							type: 'success'
						});
					}else{
						_this.$message({
							message: res.data.msg,
							type: 'warning'
						});
					}
				})
			},
			pagesChange(){
				this.getQuestion(this.page)
			},
			cartShow(index) {
				this.cartIndex = index
				if(index = 3){
					this.xiangqingShow = false
				}
			},
			toHead(val){
				// console.log(val)
				this.currentPage = 1;
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						getUserback();
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			getSale(){// 售前服务
				this.$http.get('https://admin.ruikefamen.com/api/service/sale')
				.then(res=>{
					this.saleDsc = res.data.data
				})
				.catch(err=>{console.error(err)})
			},
			getSaleAfter(){// 售后服务
				this.$http.get('https://admin.ruikefamen.com/api/service/saleafter')
				.then(res=>{
					this.saleAfterDsc = res.data.data
				})
				.catch(err=>{console.error(err)})
			},
			getQuality(){// 质量承诺
				this.$http.get('https://admin.ruikefamen.com/api/service/quality')
				.then(res=>{
					this.quality = res.data.data
				})
				.catch(err=>{console.error(err)})
			},
			getQuestion(i){// 常见问题
				var _this = this;
				_this.$apis.get(_this.$apis.question,
				{params:{page:i?i:_this.page}})
				.then(function(res) {
					for(let j=0;j<res.data.data.data.length;j++){
						res.data.data.data[j].dataTime = res.data.data.data[j].createtime.split('-')
					}
					_this.question = res.data.data
					_this.total = res.data.data.total
					_this.pageSize = res.data.data.per_page
				})
			},
			getUserback(){
				var _this = this;
				_this.$apis.post(_this.$apis.userbackFrom,{
					name:_this.ruleForm.name,
					address:_this.ruleForm.address,
					phone:_this.ruleForm.tel,
					cname:_this.ruleForm.company,
					email:_this.ruleForm.email,
					content:_this.ruleForm.desc,
					
				}).then(function(res) {
				  alert("提交成功");
				  if(alert==true){
					this.resetForm();
				  }
				})
			},
			
		}
	}
</script>

<style lang="less" scoped="scoped">
	.banner{
		position: relative;
		width: 100%;
		height: 500px;
		text-align: center;
		// background: url(../assets/images/Engineeringcase.png) no-repeat center center / cover;
		img{
			height: 90px;
			width: auto;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			top: 100px;
			margin: auto;
		}
	}
	// 正文导航
	.nav{
		width: 100%;
		height: 80px;
		padding: 0 200px;
		margin: 0 auto;
		background-color: #f6f8f9;
		.nav-cart {
			width: 1250px;
			height: 80px;
			line-height: 80px;
			margin: 0 auto;
			display: flex;
			.nav-cart-item {
				background-color: #d1d1d1;
				width: 250px;
				height: 100%;
				font-size: 18px;
				text-align: center;
				cursor: pointer;
				transform: skew(-25deg);
				p {
					color: #666;
					transform: skew(25deg);
				}
				p:nth-child(3) {
					font-size: 0.337335rem;
				}
			}
			.active {
				background-color: #1c5380;
				p{
					color: #fff;
				}
			}
		}
	}
	// 售前服务part
	.tech-main{
		min-width: 1400px;
		// margin: 0 auto;
		text-align: center;
		.tech-title{
			// 售前服务title
			text-align: center;
			padding: 66px 79px;
			font-size: 38px;
			span:nth-child(1) {
				color: #cc6633;
				font-weight: 900;
			}
			span:nth-child(2) {
				color: #1C5380;
				font-weight: 900;
			}
			p {
				font-size: 24px;
				font-weight: 100;
			}
		}
		.tech-sqfw{
			width: 1400px;
			height: 455px;
			margin: 0 auto;
			background-color: #1C5380;
			display: flex;
			justify-content: space-between;
			div{
				width: 100%;
				font-size: 16px;
				text-align: left;
				padding: 1rem 0.8rem;
				line-height: 36px;
				p{
					color: #fff;
				}
			}
			
		}
		// 订单流程
		.tech-liuc{
			width: 100%;
			.tech-title{
				// 询价、订货流程title
				text-align: center;
				padding: 50px 0;
				font-size: 38px;
				span{
					color: #1C5380;
					font-weight: 900;
				}
				p {
					font-size: 24px;
					font-weight: 100;
				}
			}
			div:nth-child(2){
				width: 70%;
				margin: 0 auto;
				img{
					width: 100%;
				}
				margin-bottom: 2.811128rem;
			}
		}
	}
	.news-l-next{
		height: 50px;
		display: flex;
		margin: 50px 0;
		font-size: 16px;
		justify-content: space-between;
		border-top: 1px solid #e6e6e6;
		border-bottom: 1px solid #e6e6e6;
		line-height: 50px;
		color: #333;
		div{
			width: 600px;
			cursor:pointer;
			border: none;
		}
		.xian{
			width: 1px;
			height: 100%;
			background: #e6e6e6;
		}
		div span{
			color: #999;
		}
	}
	// 售后服务 part
	.after-sale{
		width: 100%;
		.after-title{
			min-width: 1400px;
			// 售后服务title
			text-align: center;
			padding: 66px 79px;
			font-size: 38px;
			span:nth-child(1) {
				color: #cc6633;
				font-weight: 900;
			}
			span:nth-child(2) {
				color: #1C5380;
				font-weight: 900;
			}
			p {
				font-size: 24px;
				font-weight: 100;
			}
		}
		.after-shfw{
			width: 1400px;
			height: 463px;
			margin: 0 auto;
			background: url(../assets/images/shfw-2.png) no-repeat center center / cover;
			display: flex;
			justify-content: space-between;
			div{
				width: 100%;
				height: 100%;
				font-size: 16px;
				text-align: left;
				padding: 1rem 0.8rem;
				line-height: 36px;
				p{
					color: #fff;
				}
			}
			
		}
		.after-liuc{
			width: 100%;
			.after-title{
				text-align: center;
				padding: 50px 0;
				font-size
				span{
					color: #1C5380;
					font-weight: 900;
				}
				p {
					font-size: 24px;
					font-weight: 100;
				}
			}
			div:nth-child(2){
				width: 1400px;
				margin: 0 auto;
				img{
					width: 100%;
				}
				margin-bottom: 2.811128rem;
			}
		}
	}
	// 质量承诺
	.zhil-main{
		width: 100%;
		.zhil-title{
			// 售后服务title
			min-width: 1400px;
			text-align: center;
			padding: 66px 79px;
			font-size: 38px;
			span:nth-child(1) {
				color: #cc6633;
				font-weight: 900;
			}
			span:nth-child(2) {
				color: #1C5380;
				font-weight: 900;
			}
			p {
				font-size: 24px;
				font-weight: 100;
			}
		}
		.zhil-shfw{
			width: 1400px;
			margin: 0 auto;
			p{
				color: #333;
				margin: 0 auto;
				text-align: left;
				font-size: 18px;
				line-height: 36px;
				/deep/ img{
					margin: 94px auto 239px;
					display: block !important;
				}
			}
			/deep/ p ol {
				padding:0 0.5rem;
			}
		}
	}
	// 常见问题
	.question{
		width: 100%;
		.ques-title{
			min-width: 1400px;
			// 售后服务title
			text-align: center;
			padding: 66px 79px;
			font-size: 38px;
			span:nth-child(1) {
				color: #cc6633;
				font-weight: 900;
			}
			span:nth-child(2) {
				color: #1C5380;
				font-weight: 900;
			}
			p {
				font-size: 24px;
				font-weight: 100;
			}
		}
		.ques-list{
			width: 1031px;
			margin: 0 auto;
			.ques-item{
				padding: 70px 0;
				margin: 0 auto;
				border-top: 1px solid #e3e3e3;
				border-bottom: 1px solid #e3e3e3;
				margin-top: -1px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				cursor: pointer;
				.ques-item-l{
					flex: 1;
					height: 100%;
					display: flex;
					flex-direction: column;
					justify-content: center;
					P{
						overflow : hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
					}
					&>p:nth-child(1){
						font-size: 24px;
						margin-bottom: 10px;
					}
					div {
						position: relative;
						p{
							color: #666;
							font-size: 14px;
							line-height: 18px;
						}
						.xiangqing{
							width: 150px;
							color: #CC6633;
							font-size: 14px;
							font-weight: 700;
							position: absolute;
							right: 0;
							bottom: 1px;
							background: #fff;
							span{
								color: #666;
							}
						}
					}	
				}
				.ques-item-r{
					margin-left: 105px;
					padding-left: 26px;
					text-align: center;
					border-left: 1px solid #e3e3e3;
					p:nth-child(1){
						font-size: 48px;
						font-weight: 900;
					}
					p:nth-child(2){
						font-size: 16px;
						font-weight: normal;
						color: #999;
					}
				}
			}
		}
		.ques-yema{
			width: 100%;
			margin: 1.124451rem auto;
			display: flex;
			justify-content: center;
			margin-bottom: 2.811128rem;
			/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
			background-color: #cc6633;
			}
		}
	}
	
	// 用户反馈
	.user-feedback{
		width: 100%;
		.user-title{
			min-width: 1400px;
			// 售后服务title
			text-align: center;
			padding: 66px 79px;
			font-size: 38px;
			span:nth-child(1) {
				color: #cc6633;
				font-weight: 900;
			}
			span:nth-child(2) {
				color: #1C5380;
				font-weight: 900;
			}
			p {
				font-size: 24px;
				font-weight: 100;
			}
		}
		.user-table{
			width: 1400px;
			margin: 0 auto;
			text-align: center;
			.teble{
				display: flex;
				margin: 0 auto;
				.table-l{
					width: 50%;
					.el-form{
						/deep/ .el-input__inner{
							width: 550px;
							height: 48px;
							display: inline-block;
							background-color: #f7f7f7;
							border: none;
							border-radius: 0;
							margin-bottom: -10px;
						}
					}
				}
				.table-r{
					.resizeNone{
						/deep/ .el-textarea__inner{
							width: 550px;
							height: 302px;
							resize: none;
							background-color: #f7f7f7;
							border: none;
							border-radius: 0;
							margin-left: 10px; 
						 }
					}
				}
			}
			.table-btn{
				width: 100%;
				text-align: center;
				button{
					width: 120px;
					height: 36px;
					border-radius: 0;
					font-size: 0.18px;
					margin: 50px 20px;
					color: #fff;
					border: none;
					outline-style: none;
					background-color: #909090;
					cursor: pointer;
				}
				button:nth-child(1){
					background-color: #cc6633;
				}
			}
		}
	}
	.newsDetail{
		width: 70%;
		margin: 0 auto;
		p:nth-child(1){
			font-size: 30px;
			color: #333;
			text-align: center;
			margin: 20px ;
			margin-bottom: 36px;
		}
		p:nth-child(2){
			width: 90%;
			margin: 0 auto;
			font-size: 0.44rem;
			color: #999;
			text-align: center;
			height: 0.7rem;
			line-height: 0.7rem;
			margin: 0.6rem 0;
			display: flex;
			justify-content: space-evenly;
			border-bottom: 1px solid #eee;
			border-top: 1px solid #eee;
		}
		p:nth-child(3){
			font-size: 0.38rem;
			line-height: 0.76rem;
			color: #505050;
			text-indent: 2em;
		}
		.title{
			border-top: 1px solid #e6e6e6;
			border-bottom: 1px solid #e6e6e6;
			p{
				margin: 0;
				font-size: 14px;
				line-height: 46px;
				color: #999;
				img{
					vertical-align: middle;
					margin-right: 4px;
				}
			}
			.fenxiang{
				line-height: 46px;
				border: none;
				display: flex;
				align-items: center;
				font-size: 14px;
				color: #999;
				div{
					border: none;
				}
			}
		}
		.news-pic{
			width: 100%;
			// height: 10.55rem;
			overflow: hidden;
			margin-bottom: 1rem;
			margin-top: 0.5rem;
			img{
				height: 5.2rem;
				width: auto;
				margin-bottom: 0.18rem;
			}
		}
		.shang,.xia{
			height: 0.9rem;
			margin: 0.2rem 0;
			line-height: 0.9rem;
			border-top: 1px solid #eee;
			border-bottom: 1px solid #eee;
			font-size: 0.38rem;
			color: #999;
			white-space:nowrap;
			overflow:hidden;
			text-overflow:ellipsis;
		}
		.xia{
			margin-bottom: 1rem;
		}
		.shang{
			margin-top: 100px;
		}
		.newspictxt{
			margin-top: 39px;
			/deep/ p:nth-child(2),
			/deep/ p:nth-child(3){
				text-indent: 0;
				img{
					width: 100%;
					margin: 0.05rem 0;
				} 	
			}
			
		}
	}
	/deep/ .el-pagination.is-background .el-pager li{
		background: #fff;
		border: 1px solid #eee;
	}
	/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color: #cc6633;
	}
	/deep/ .el-pagination.is-background .el-pager li:not(.disabled).number {
		width: 48px;
		height: 48px;
		line-height: 48px;
	}
	/deep/ .el-pagination.is-background .btn-prev {
		width: 68px;
		height: 48px;
		line-height: 48px;
		background: #fff;
		border: 1px solid #eee;
		span{
			width: 68px;
			height: 48px;
			line-height: 48px;
		}
	}
	/deep/ .el-pagination.is-background .btn-next {
		width: 68px;
		height: 48px;
		line-height: 48px;
		background: #fff;
		border: 1px solid #eee;
		span{
			width: 68px;
			height: 48px;
			line-height: 48px;
		}
	}
	.fontFZ{
			font-family: fz;
			color: #666 !important;
		}
	/deep/ .bdsharebuttonbox{
		a{
			transform: scale(0.7);
			margin-right: 0;
		}
	}
</style>
