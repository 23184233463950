<template>
	<div id="prolist">
		<Nav></Nav>
		<div class="banner">
			<img src="../assets/images/2.png" class="wow animate__animated animate__slideInDown animate__delay-0.5s">
		</div>
		<!-- 产品中心 -->
		<div class="procenter">
			<!-- 产品中心标题和搜索框 -->
			<div class="pro-title">
				<p>产品中心</p>
				<div>
					<input class="inputinput" type="text" v-model="value" placeholder="请输入产品关键字" @input="input" @keydown="keyss($event)"/>
					<img src="../assets/images/ss.png" @click="onConfirm1"  >
				</div>
			</div>
			
			<!-- 商品分类 -->
			<div class="pro-cart">
				<!-- 左部分 -->
				<ul class="pro-cart-l" >
					<li :class="index == 1?'ulShow':''" v-for="(item,index) in procart" :key="index">{{item}}：</li>
				</ul>
				<!-- 右边 -->
				<div class="pro-cart-r">	
					<ul class="ul1">
						<li :class="{proactive:index==navBool[0]}" v-for="(item,index) in typeList.product" :key="index" @click="navTab(0,index,item.id,'categoryid')">{{item.name}}</li>
					</ul>
					<ul class="ul1 typeShow">
						<li :class="{proactive:index==navBool[1]}" v-for="(item,index) in typeList.caliber" :key="index" @click="navTab(1,index,item.name,'caliber')">{{item.name}}</li>
						<div class="typeBtn" @click="open">更多<img class="gengduoIcon" src="../assets/images/navYouu.png" alt=""></div>
					</ul>
					<ul class="ul1">
						<li :class="{proactive:index==navBool[2]}" v-for="(item,index) in typeList.pressure" :key="index" @click="navTab(2,index,item.name,'pressure')">{{item.name}}</li>
					</ul>
					<ul class="ul1">
						<li :class="{proactive:index==navBool[3]}" v-for="(item,index) in typeList.subject" :key="index" @click="navTab(3,index,item.name,'subject')">{{item.name}}</li>
					</ul>
					<ul class="ul1">
						<li :class="{proactive:index==navBool[4]}" v-for="(item,index) in typeList.link" :key="index" @click="navTab(4,index,item.name,'link')">{{item.name}}</li>
					</ul>
					<ul class="ul1">
						<li :class="{proactive:index==navBool[5]}" v-for="(item,index) in typeList.do" :key="index" @click="navTab(5,index,item.name,'do')">{{item.name}}</li>
					</ul>
					<ul class="ul1">
						<li :class="{proactive:index==navBool[6]}" v-for="(item,index) in typeList.app" :key="index" @click="navTab(6,index,item.name,'app')">{{item.name}}</li>
					</ul>
				</div>
				
			</div>
			
			<!-- 商品列表 -->
			<div class="pro-list" style="margin-top: 51px;">
				<div class="zanwu" v-if="productsList.length<=0">暂无产品信息...</div>
				<div class="item" v-for="(item,index) in productsList" :key="index">
					<router-link tag="div" :to="{path:'/prodsc',query: {id:item.id}}" class="pro-zzc">
						<div>
							<p>{{item.name}}</p>
						</div>
						
						<div class="chakan">
							<p>查看详情<span>&gt;</span></p>
						</div>
					</router-link>
					<div class="dingwei">
						<img :src="item.image" >
						<p>{{item.name}}</p>	
					</div>
					
				</div>
			</div>
			
			<!-- 页码 -->
			<div class="pro-yema">
				<el-pagination background layout="prev, pager,next"
				@current-change="pagesChange"
				:total="total"
				:current-page.sync="page"
				:page-size="pageSize"
				prev-text="首页" 
				next-text="尾页"
				@prev-click="toHead"
				@next-click="nextPage">
				</el-pagination>	
				
			</div>
			
		</div>
		
		<free class="wow animate__animated animate__slideInUp animate__delay-0.5s"></free>
		
		<!-- <advantage style="margin-bottom: 3.373354rem;"  class="wow animate__animated animate__slideInUp animate__delay-0.5s"></advantage> -->
		
		<Footer></Footer>
	</div>
</template>

<script>
	import Nav from "../components/nav.vue"
	import Footer from "../components/footer.vue"
	import free from "../components/free.vue"
	import WOW from 'wowjs'
	import advantage from "../components/advantage.vue"
	import $ from "jquery"
	export default{
		data(){
			return{
				navBool:[false,false,false,false,false,false,false],//分类选中
				httpArr:{},//请求参数对象
				typeList:'',//分类
				pageSize:24,
				page:1,
				total:0,
				res:[],
				currentPage: 1,
				productsList:[],// 产品列表
				procart:["产品分类","口径范围","压力等级","主体材料","连接方式","操作方式","应用行业"],
				product:[],// 产品分类
				caliber:[],// 口径分类
				pressure:[],// 压力等级
				subject:[],// 主题材料
				link:[],// 链接方式
				doing:[],// 操作方式
				value:'',
				lastPage:'',
			}
		},
		components: {
			Nav,
			Footer,
			free,
			advantage
		},
		watch:{
			$route(){
				var _this = this;
				_this.getProducts()
			},
		},	
		created() {
			var _this = this
			this.getProducts();
			this.getProductsList();
			let value = sessionStorage.getItem("value")
			if(value){
				_this.httpArr['keyword'] = value
				_this.value = value
				_this.producList(_this.httpArr)
			}
			window.addEventListener('beforeunload', e =>this.test(e))
			_this.$apis.get(_this.$apis.inside_banner).then(res=>{
				$('.banner').css('background','url('+res.data.data.Product+')')
				$('.banner').css('backgroundSize','100% 100%')
			})
		},
		methods:{
			open(){
				if($('.ulShow').height()>60){
					$('.ulShow').animate({height:"56px"});
					$('.typeShow').animate({height:"56px"});
					$('.gengduoIcon').css({'transform':'rotate(0deg)'})
				}else{
					$('.typeShow').animate({height:"200px"});
					$('.ulShow').animate({height:"200px"});
					$('.gengduoIcon').css({'transform':'rotate(90deg)'})
				}
			},
			test(e) {
				// ...
				sessionStorage.setItem("value",'')
			},
			getTypeList(){
				var _this = this
				_this.$apis.get(_this.$apis.gettype,{params:{type:'product'}}).then(res=>{
					res.data.data.unshift({
						id:false,
						name:"全部",
					})
					_this.productList = res.data.data
					for(let i=0;i<res.data.data.length;i++){
						if(_this.$route.query.id == res.data.data[i].id){
							_this.navBool[0] = i
							_this.httpArr['categoryid'] = _this.$route.query.id
						}
					}
				})
			},
			keyss(e){
				var _this = this;
			   if(e.keyCode == 13){
					let value = sessionStorage.getItem("value")
					if(value){
						this.httpArr.keyword = value
						_this.value = value
					}else{
						this.httpArr.keyword = _this.value
					}
					this.producList(this.httpArr)
			   }
			},
			input(){
				sessionStorage.setItem('value',this.value)
			},
			onConfirm1() {
				let value = sessionStorage.getItem("value")
				this.httpArr.keyword = value
				// this.$refs.item1.toggle();
				this.producList(this.httpArr)
			},
			getProducts(){
				var _this = this
				this.$http.get('https://admin.ruikefamen.com/api/products/term_list')
				.then(res=>{
					for(var key in res.data.data){
						res.data.data[key].unshift({
							id:false,
							name:"全部",
						})
					}
					_this.$apis.get(_this.$apis.gettype,{params:{type:'product'}}).then(ress=>{
						ress.data.data.unshift({
							id:false,
							name:"全部",
						})
						res.data.data['product'] = ress.data.data
						for(let i=0;i<ress.data.data.length;i++){
							if(_this.$route.query.id == ress.data.data[i].id){
								_this.navBool[0] = i
								_this.httpArr['categoryid'] = _this.$route.query.id
							}
						}
						this.typeList = res.data.data
						_this.$forceUpdate()
					})
				})
			},
			navTab(type,index,value,key){
				this.navBool[type] = index
				if(index == 0){
					this.httpArr[key] = ""
				}else{
					this.httpArr[key] = value
				}
				this.$forceUpdate();
				this.producList(this.httpArr)
				sessionStorage.setItem('secah',JSON.stringify(this.httpArr))
			},
			producList(data){
				this.$http.get('https://admin.ruikefamen.com/api/products/list',
				{params:data}).then(res=>{
				  if(res.data.code == 1){
					  console.log(res.data.data.data,'搜索内容')
					this.productsList = res.data.data.data
					this.total = res.data.data.total
					this.res = res.data.data
				  }
				})
			},
			pagesChange(){
				this.getProductsList()
				 $("html,body").animate({"scrollTop":"900"},500)
			},
			toHead(){
				this.page = 1;
			},
			nextPage(){
				this.page = this.lastPage;
			},
			getProductsList(){// 产品列表
				this.$http.get('https://admin.ruikefamen.com/api/products/list',
				{params:{
					// product:this.proIndexName,
					page:this.page,
					caliber:this.proIndexName1,
					pressure:this.proIndexName2,
					subject:this.proIndexName3,
					link:this.proIndexName4,
					do:this.proIndexName5,
				}}).then(res=>{
				  if(res.data.code == 1){
					this.productsList = res.data.data.data
					this.total = res.data.data.total
					this.lastPage = res.data.data.last_page
				  }
				})
			},
			
		},
		mounted() {
			new WOW.WOW().init();
		}
	}
</script>

<style lang="less" scoped="scoped">
	#prolist{
		width: 100%;
		min-width: 1400px;
		.banner{
			position: relative;
			width: 100%;
			height: 500px;
			text-align: center;
			// background: url(../assets/images/ProductCenter.png) no-repeat center center / cover;
			img{
				height: 90px;
				width: auto;
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				top: 100px;
				margin: auto;
			}
		}
		.procenter{
			width: 1400px;
			margin: 0 auto;
			margin-top: 26px;
			.pro-title{
				display: flex;
				justify-content: space-between;
				margin-bottom: 28px;
				>p{
					font-size: 26px;
					font-weight: 900;	
				}
				div{
					display: flex;
					input{
						width: 340px;
						height: 40px;
						border: none;
						outline-style: none;
						border: 1px solid #999;
						text-indent: 1em;
					}
					img{
						width: 45px;
						background-color: #194e7a;
						display: block;
						padding: 0.281112rem 0.337335rem;
						cursor: pointer;
					}	
				}
				
			}
			.pro-cart{
				display: flex;
				border: 1px solid #e6e6e6;
				border-bottom:none;
				.pro-cart-l{
					background-color: #f3f3f3;
					font-size: 16px;
					li{
						width: 128px;
						height: 57px;
						line-height: 57px;
						text-align: center;
						font-weight: 600;
						border-bottom: 1px solid #e6e6e6;
					}
					li:last-child{
						border-bottom: none;
					}
				}
				.pro-cart-r{
					flex: 1;
					.ul1{
						width: 100%;
						height: 57px;
						line-height: 57px;
						display: flex;
						font-size: 16px;
						border-bottom: 1px solid #e6e6e6;
						li{
							cursor: pointer;
							padding: 0 20px;
							text-align: center;
						}
					}
					.proactive{
						color: #cc6633;
						font-weight: 900;
					}
				}
			}
			.pro-list{
				// 商品列表
				width: 100%;
				// height: 200px;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				text-align: center;
				.zanwu{
					width: 100%;
					color: #666;
					font-size: 18px;
					text-align: center;
					line-height: 70px;
				}
				.item{
					position: relative;
					width: 335px;
					height: 301px;
					margin-bottom: 20px;
					border: 1px solid #e6e6e6;
					display: flex;
					flex-direction: column;
					align-items: center;
					.pro-zzc{
						// 遮罩层
						cursor: pointer;
						width: 100%;
						height: 301px;
						margin-bottom: 30px;
						background-color: rgba(204,102,51,0.88);
						text-align: left;
						position: absolute;
						top: 0;
						display: flex;
						transform-origin: top;
						transform: scaleY(0);
						opacity: 0;
						flex-direction: column;
						justify-content: space-between;
						padding: 20px 43px;
						padding-top: 58px;
						padding-bottom: 61px;
						div{
							display: flex;
							justify-content: space-between;
							flex-direction: column;
							p{
								font-weight: 900;
								font-size:26px;
								color: #fff;	
							}
						}
						.chakan{
							p{
								padding-bottom: 6px;
								color: #fff;
								border-bottom: 1px solid #fff;
								font-size: 16px;
								span{
									color: #fff;
									font-family: "SimSun";
									float: right;
								}
							}
						}
					}
					p{
						font-size: 18px;
					}
				}
				.item:hover{
					.pro-zzc{
						transform: scaleY(1);
						opacity: 1;
						transition: 0.5s;
						// display: block;
					}
				}
				.item:nth-child(4n){
					margin-right: 0;
				}
			}
			// 页码
			.pro-yema{
				margin: 90px auto;
				display: flex;
				justify-content: center;
				/deep/ .el-pagination.is-background .el-pager li{
					background: #fff;
					border: 1px solid #eee;
				}
				/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
					background-color: #cc6633;
				}
				/deep/ .el-pagination.is-background .el-pager li:not(.disabled).number {
					width: 48px;
					height: 48px;
					line-height: 48px;
				}
				/deep/ .el-pagination.is-background .btn-prev {
					width: 68px;
					height: 48px;
					line-height: 48px;
					background: #fff;
					border: 1px solid #eee;
					span{
						width: 68px;
						height: 48px;
						line-height: 48px;
					}
				}
				/deep/ .el-pagination.is-background .btn-next {
					width: 68px;
					height: 48px;
					line-height: 48px;
					background: #fff;
					border: 1px solid #eee;
					span{
						width: 68px;
						height: 48px;
						line-height: 48px;
					}
				}
			}
		}
	}
	.dingwei{
		position: relative;
		z-index: -1;
		img{
			display: block;
			width: 285px;
			height: 252px;
			margin: 0 auto;
		}
		p{
			width: 100%;
			text-align: center;
		}
	}
	.inputinput{
		color: #333;
	}
	.ulShow{
		height: 56px;
	}
	.typeShow{
		height: 56px;
		overflow: hidden;
		line-height: 56px !important;
		padding-right: 70px;
		position: relative;
		flex-wrap: wrap;
		align-content: flex-start;
		overflow: hidden;
		li{
			height: 56px;
		}
		.typeBtn{
			color: #cc6633;
			cursor: pointer;
			-moz-user-select:none;
			-webkit-user-select:none;
			user-select:none;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			position: absolute;
			top: 0;
			right: 0;
			img{
				width: 16px;
				height: 16px;
				margin-left: 5px;
				transition: 0.5s;
			}
		}
	}
</style>
