<template>
	<div id="conpanyDsc">
		<Nav></Nav>
		<div class="banner">
			<img src="../assets/images/aboutruiketxt.png" alt="" class="wow animate__animated animate__slideInDown animate__delay-0.5s">
		</div>
		
		<div style="width: 100%;background-color: #f6f8f9;">
		<div class="nav">
			<div class="nav-cart wow animate__animated animate__slideInDown animate__delay-0.5s">
				<!-- 产品分类 -->
				<div :class="{active:index==cartIndex}" class="nav-cart-item" v-for="(item,index) in cartList"
					@click="cartShow(index)" :key='index'>
					<p>{{item.name}}</p>
				</div>
			</div>
		</div>
		</div>
	
		<!-- 新闻动态 part -->
		<div v-if="cartIndex == 4">
			<div class="news ">
				<!-- 新闻小导航 -->
				<div class="news-l ">
					<div class="news-l-list" >
						<p style="margin-bottom: 36px;">{{newsDel.title}}</p>
						<div style="display: flex;align-items: center;justify-content: center;">
							<p>
								发布日期：{{newsDel.dataTime[0]}}-{{newsDel.dataTime[1]}}-{{newsDel.dataTime[2]}}&nbsp;{{newsDel.dataTime[3]}}&emsp;点击率：{{newsDel.click}}&emsp;
							</p>
							<div class="fenxiang">
								<div style="margin-right: 5px;">分享</div>
								<vshare></vshare>
							</div>
						</div>
						<p v-html="newsDel.content">{{newsDel.content}}</p>
						
						<div class="news-l-next">
							<div style="text-align: left;" @click="qiehuan(newsDel.up.id)"><span>上一篇：</span>{{newsDel.up?newsDel.up.title:'没有上一篇了'}}</div>
							<div class="xian"></div>
							<div style="text-align: right;" @click="qiehuan(newsDel.down.id)"><span>下一篇：</span>{{newsDel.down?newsDel.down.title:'没有下一篇了'}}</div>
						</div>
					</div>
					

					
				</div>
				<!-- 产品推荐 -->
				<div class="news-r">
					<div class="news-r-nav wow animate__animated animate__slideInUp animate__delay-0.5s">
						<p>
							<img src="../assets/images/heng.png" >
							&ensp;产品推荐&ensp;
							<img src="../assets/images/heng.png" >
						</p>
					</div>
					
					<!-- 推荐商品列表 -->
					<div class="news-item wow animate__animated animate__slideInUp animate__delay-0.5s" v-for="(item,index) in hotList" :key="index" @click="gochanpin(item.id)">
						<p><img :src="item.image" ></p>
						<p align="center">{{item.name}}</p>
					</div>
					
					<!-- 联系我们 -->
					<div class="news-r-nav wow animate__animated animate__slideInUp animate__delay-0.5s" style="margin-top: 20px;background-color: #266193;">
						<p>
							<img src="../assets/images/heng.png" >
							<span>&ensp;联系我们&ensp;</span>
							<img src="../assets/images/heng.png" >
						</p>
					</div>
					
					<div class="lianxi  wow animate__animated animate__slideInUp animate__delay-0.5s">
						<p :title="index.steamAddress">地址：{{index.steamAddress}}</p>
						<p>电话：<span>{{index.steamHotline}}</span></p>
						<p>传真：{{index.steamFax}}</p>
						<p>邮编：{{index.steamPostcode}}</p>
						<p>网址：{{index.steamUrl}}</p>
						<p>邮箱：{{index.steamEmail}}</p>
					</div>
					
					
				</div>
				
			</div>
		</div>
		
		<Footer></Footer>
	</div>
</template>

<script>
	import Swiper from "../components/swiper.vue"
	import Nav from "../components/nav.vue"
	import Footer from "../components/footer.vue"
	import Free from "../components/free.vue"
	import Advant from "../components/advantage.vue"
	export default{
		data(){
			return{
				cartIndex: 4,
				res:[],
				newsList:[],// 新闻分类
				newsDel:[],
				cartList: [{name:'公司简介'},{name:'组织架构'},{name:'公司荣誉'},{name:'企业文化'},{name:'新闻动态'}],
				rukeData: [
					{num: 10,txt1: '10余年阀门经验',dsc: '诚实守信 永续经营'},
					{num: 30,txt1: '专利技术',dsc: '自主专利 彰显实力'},
					{num: 100,txt1: '加工检测装备',dsc: '装备齐全 奠定基础'},
					{num: 2000,txt1: '合作客户',dsc: '客户满意 长期合作'}
				],
				hotList:'',
			}
		},
		components: {
			Nav,
			Footer,
			Free,
			Advant,
			Swiper
		},
		beforeCreate(){
			window._bd_share_main = ""; 
		},
		created() {
			this.getNewsDel();
		},
		mounted(){
			this.getHotList()
			this.getIndex();
		},
		methods: {
			getIndex(){
				this.$http.get("https://admin.ruikefamen.com/api/index/index")
				.then(res=>{
					if(res.data.code == 1){
						this.index = res.data.data
					}
				})
			},
			//获取推荐产品
			getHotList(){
				var _this = this;
				_this.$apis.get(_this.$apis.hot_list).then(res=>{
					_this.hotList = res.data.data
				})
			},
			//去产品详情
			gochanpin(id){
				var _this = this;
				_this.$router.push({
					path:'/prodsc',
					query:{
						id:id
					}
				})
			},
			qiehuan(id){
				this.getNewsDel(id)
				document.body.scrollTop = 0
            	document.documentElement.scrollTop = 0
			},
			cartShow(index) {
				this.$router.push({path:'/about',query:{i:index}});
			},
			newsShow(index) {
				// 新闻小导航
				this.newsIndex = index
			},
			toHead(){
				this.currentPage = 1;
			},
			getNewsDel(id){
				var _this = this;
				let regex = new RegExp('<img', 'gi')
				if(id){
					_this.$apis.get(_this.$apis.newsDel,
						{params:{id:id}}
					).then(res=>{
						res.data.data.content = res.data.data.content.replace(regex, `<img style="max-width: 100%;display:block;"`)
						res.data.data.dataTime = res.data.data.createtime.split('-')
						this.newsDel = res.data.data
					})
				}else{
					_this.$apis.get(_this.$apis.newsDel,
						{params:{id:_this.$route.query.id}}
					).then(res=>{
						res.data.data.content = res.data.data.content.replace(regex, `<img style="max-width: 100%;display:block;"`)
						res.data.data.dataTime = res.data.data.createtime.split('-')
						this.newsDel = res.data.data
					})
				}
			}
			
		},
	}
</script>

<style scoped="scoped" lang="less">
	.banner{
		position: relative;
		width: 100%;
		height: 500px;
		text-align: center;
		background: url(../assets/images/inbanner01.png) no-repeat center center / cover;
		img{
			width: 400px;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			top: 100px;
			margin: auto;
		}
	}
	.nav{
		width: 100%;
		height: 80px;
		margin: 0 auto;
		background-color: #f6f8f9;
		.nav-cart {
			width: 1250px;
			height: 80px;
			line-height: 80px;
			margin: 0 auto;
			display: flex;
			.nav-cart-item {
				background-color: #d1d1d1;
				width: 250px;
				height: 100%;
				font-size: 18px;
				text-align: center;
				cursor: pointer;
				transform: skew(-25deg);
				p {
					color: #666;
					transform: skew(25deg);
				}
				p:nth-child(3) {
					font-size: 0.337335rem;
				}
			}
			.active {
				background-color: #1c5380;
				p{
					color: #fff;
				}
			}
		}
	}

	// 新闻动态 part
	.news{
		width: 1400px;
		margin: 0 auto;
		margin-top: 1.742899rem;
		display: flex;
		justify-content: space-between;
		.news-r{
			width: 335px;
			.news-r-nav{
				background-color: #cc6633;
				display: flex;
				justify-content: center;
				p{
					font-size: 18px;
					color: #fff;
					font-weight: 900;
					line-height: 64px;
				}
				img{
					width: 27px;
					vertical-align: middle;
				}
			}
			// 右侧推荐商品
			.news-item{
				width: 335px;
				height: 301px;
				margin-top: 20px;
				background-color: #fff;
				position: relative;
				overflow: hidden;
				border : 1px solid #E6E6E6;
				cursor:pointer;
				img{
					display: block;
					width: 285px;
					height: 252px;
					margin: 0 auto;
				}
				p:nth-child(2){
					width: 335px;
					padding: 0 30px;
					font-size: 18px;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}
			.lianxi{
				width: 100%;
				background-color: #fff;
				border : 1px solid #E3E3E3;
				line-height: 0.843338rem;
				padding: 0.618448rem;
				margin-top: 0.421669rem;
				color: #666;
				p{
					font-size: 14px;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				}
				p span{
					font-size: 22px;
					color: #666;
				}
			}
			
		}
		.news-l-list{
			width: 1063px;
			text-align: center;
			padding-right: 37px;
			>p{
				font-size: 30px;
			}
			>p:nth-child(3){
				font-size: 18px;
				line-height: 38px;
				text-align: left;
				display: flex;
				justify-content: center;
				flex-direction: column;
				text-indent: 1em;
				margin-top: 39px;
			}
			.fenxiang{
				line-height: 46px;
				border: none;
				display: flex;
				align-items: center;
				font-size: 14px;
				color: #999;
				div{
					border: none;
				}
			}
			div{
				border-top: 1px solid #e6e6e6;
				border-bottom: 1px solid #e6e6e6;
				p{
					font-size: 14px;
					line-height: 46px;
					color: #999;
					img{
						vertical-align: middle;
						margin-right: 4px;
					}
				}
			}
		}
		.news-l-pic{
			img{
				margin-top: 40px;
			}
		}
		.news-l-next{
			height: 50px;
			display: flex;
			margin: 50px 0;
			font-size: 16px;
			justify-content: space-between;
			border-top: 1px solid #e6e6e6;
			border-bottom: 1px solid #e6e6e6;
			line-height: 50px;
			color: #333;
			div{
				width: 600px;
				cursor:pointer;
				border: none;
			}
			.xian{
				width: 1px;
				height: 100%;
				background: #e6e6e6;
			}
			div span{
				color: #999;
			}
		}
	}
	/deep/ .bdsharebuttonbox{
		a{
			transform: scale(0.7);
			margin-right: 0;
		}
	}
</style>
