<template>
	<div id="prolist">
		<Nav></Nav>
		<div class="banner">
			<img src="../assets/images/5.png" class="patent-title wow animate__animated animate__slideInDown animate__delay-0.5s">
		</div>
		<!-- 产品中心 -->
		
		<div class="pupop2">
			<div class="inputDiv">
				<img class="guanbi" @click="guanbi()" src="../assets/images/guanbiWhite.png" alt="">
				<img :src="pupopImg" alt="">
			</div>
		</div>
		<div class="procenter">
			<div class="patent-title wow animate__animated animate__slideInUp animate__delay-0.5s">
				<span>资质</span>
				<span>专利</span>
				<p class="fontFZ">QUALIFICATION PATENT</p>
			</div>
			
			<!-- 列表 -->
			<div class="pro-list" style="margin-top: 1.1rem;">
				<div class="item" @click='openBig(index,item.image)' v-for="(item,index) in patents" :key='patents[index].id'>
					<router-link to="" tag="div" class="pro-zzc">
						<div><p>{{patents[index].title}}</p></div>
						<div class="chakan">
							<p>查看详情<span>&gt;</span></p>
						</div>
						
					</router-link>	
					<div style="overflow: hidden;" class="imgdiv">
						<img :src="patents[index].slimage" >
					</div>	
				</div>
			</div>
			
			<!-- 页码 -->
			<div class="pro-yema">
				<el-pagination background layout="prev, pager,next" 						
				@current-change="pagesChange"
				:total="total"
				:current-page.sync="page"
				:page-size="pageSize"
				prev-text="首页" 
				next-text="尾页"
				@prev-click="toHead"
				@next-click="nextPage"
				>
				</el-pagination>	
			</div>
		</div>
		
		<free class="wow animate__animated animate__slideInUp animate__delay-0.5s"></free>
		
		<Footer></Footer>
	</div>
</template>

<script>
	import Nav from "../components/nav.vue"
	import Footer from "../components/footer.vue"
	import free from "../components/free.vue"
	import advantage from "../components/advantage.vue"
	import WOW from 'wowjs'
	import $ from "jquery"
	export default{
		data(){
			return{
				dialogVisible: false,
				page:1,// 当前页数
				total:0,// 总条数
				pageSize:12,// 显示条数
				currentPage: 1,
				patents:[],// 资质专利数据

				pupopImg:'',
			}
		},
		components: {
			Nav,
			Footer,
			free,
			advantage
		},
		created() {
			var _this = this
			this.getHonor();
			_this.$apis.get(_this.$apis.inside_banner).then(res=>{
				$('.banner').css('background','url('+res.data.data.Patent+')')
				$('.banner').css('backgroundSize','100% 100%')
			})
		},
		methods:{
			openBig(index,url){// 查看大图
				this.pupopImg = url
				$('.pupop2').fadeToggle()
				var top=$(document).scrollTop();
					$(document).on('scroll.unable',function (e){
					$(document).scrollTop(top);
				})
			},
			guanbi(){
				$('.pupop2').fadeToggle()
				$(document).unbind("scroll.unable");
			},
			handleClose(done) {
			this.$confirm('确认关闭？')
			  .then(_ => {
				done();
			  })
			  .catch(_ => {});
			},
			pagesChange(){
				this.getHonor(this.page)
				$("html,body").animate({"scrollTop":"600"},500)
			},
			toHead(){
				this.page = 1;
			},
			nextPage(){
				this.page = this.lastPage;
			},
			proAct(index) {
				this.proIndex = index;
			},
			getHonor(i){
				this.$http.get('https://admin.ruikefamen.com/api/patents/list',
				{params:{page:i?i:this.page}})
				.then(res=>{
					this.patents = res.data.data.data
					this.total = res.data.data.total
					this.pageSize = res.data.data.per_page
				})
			},
			// getHonor1(i){
			// 	this.$http.get('/api/patents/list',
			// 	{params:{page:i?i:this.page}})
			// 	.then(res=>{
			// 		var honor1 = res.data.data.data
			// 		var Arryhonor = this.honor
			// 		Arryhonor.push(honor1[0])
			// 	})
			// },
		},
		mounted() {
			new WOW.WOW().init();
		}
	}
</script>

<style lang="less" scoped="scoped">
.pupop2{
	width: 100vw;
	height: 100vh;
	display: none;
	background-color: rgba(0, 0, 0, 0.65);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	.inputDiv{
		margin: 0 auto;
		text-align: center;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		.guanbi{
			width: 30px;
			position: absolute;
			top: 10px;
			right: 10px;
			cursor: pointer;
		}
	}
}
	#prolist{
		width: 100%;
		.banner{
			position: relative;
			width: 100%;
			height: 500px;
			text-align: center;
			// background: url(../assets/images/Qualificationpatent.png) no-repeat center center / cover;
			img{
				height: 90px;
				width: auto;
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				top: 100px;
				margin: auto;
			}
		}
		// 查看专利大图
		.chakanbox{
			position: fixed;
			top: 0;
			width: 100vw;
			height: 100vh;
			z-index: 99999;
			background-color: rgba(0,0,0,0.7);
			display: none;
			.chakanzz{
				position: fixed;
				top: 13%;
				left: 0;
				right: 0;
				margin: 0 auto; 
				width: 14rem;
				height: 14rem;
				background-color: pink;
				z-index: 99;
				.el-icon-close{
					position: absolute;
					top: 0.22rem;
					right: 0.3rem;
					cursor: pointer;
				}
			}	
		}
		.procenter{
			width: 95%;
			margin: 0 auto;
			.patent-title{
				// 售后服务title
				min-width: 1400px;
				text-align: center;
				padding: 66px 0 73px;
				font-size: 38px;
				span:nth-child(1) {
					color: #cc6633;
					font-weight: 900;
				}
				span:nth-child(2) {
					color: #1C5380;
					font-weight: 900;
				}
				p {
					font-size: 24px;
					font-weight: 100;
				}
			}
			.pro-list::after{
				content: "";
				width: 335px;
			}
			.pro-list{
				// 商品列表
				width: 1400px;
				margin: 0 auto;
				.item:nth-child(4n){
					margin-right: 0;
				}
				.item{
					display: inline-block;
					position: relative;
					width: 335px;
					height: 301px;
					margin-bottom: 20px;
					margin-right: 20px;
					border: 1px solid #e6e6e6;
					.pro-zzc{
						// 遮罩层
						cursor: pointer;
						width: 335px;
						height: 301px;
						background-color: rgba(204,102,51,0.88);
						text-align: left;
						position: absolute;
						top: 0;
						display: flex;
						transform-origin: top;
						transform: scaleY(0);
						opacity: 0;
						flex-direction: column;
						justify-content: space-between;
						padding: 20px 43px;
						padding-top: 58px;
						padding-bottom: 61px;
						div{
							display: flex;
							justify-content: space-between;
							flex-direction: column;
							p{
								font-weight: 900;
								font-size: 24px;
								color: #fff;	
							}
						}
						.chakan{
							p{
								color: #fff;
								height: 0.787116rem;
								border-bottom: 1px solid #fff;
								font-size: 16px;
								span{
									color: #fff;
									font-family: "SimSun";
									float: right;
								}
							}
						}
					}
					.imgdiv{
						width: 100%;
						display: flex;
						flex-direction: column;
						align-items: center;
					}
					
				}
				.item:hover{
					.pro-zzc{
						transform: scaleY(1);
						opacity: 1;
						transition: 0.5s;
						// display: block;
					}
				}
			}
			//页码
			.pro-yema{
				margin: 90px auto;
				display: flex;
				justify-content: center;
				/deep/ .el-pagination.is-background .el-pager li{
					background: #fff;
					border: 1px solid #eee;
				}
				/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
					background-color: #cc6633;
				}
				/deep/ .el-pagination.is-background .el-pager li:not(.disabled).number {
					width: 48px;
					height: 48px;
					line-height: 48px;
				}
				/deep/ .el-pagination.is-background .btn-prev {
					width: 68px;
					height: 48px;
					line-height: 48px;
					background: #fff;
					border: 1px solid #eee;
					span{
						width: 68px;
						height: 48px;
						line-height: 48px;
					}
				}
				/deep/ .el-pagination.is-background .btn-next {
					width: 68px;
					height: 48px;
					line-height: 48px;
					background: #fff;
					border: 1px solid #eee;
					span{
						width: 68px;
						height: 48px;
						line-height: 48px;
					}
				}
			}
		}
	}
	.fontFZ{
		font-family: fz;
		color: #666 !important;
	}
</style>
