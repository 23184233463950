import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import 'lib-flexible'
import less from 'less'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import vshare from 'vshare'
import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
Vue.use(VideoPlayer)
import axios from 'axios'
import apis from './api/api.js'
import http from './api/http.js'
import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'



Vue.prototype.$http = http
Vue.prototype.$apis = apis

// 百度地图
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: 'Y58f7kBjBmDgTIIxDy3p81W51Z6yBid3'
})
// 动画库
import wow from 'wowjs'
import 'animate.css'
Vue.prototype.$wow = wow

Vue.use(less)
Vue.use(ElementUI);
Vue.use(vshare)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
