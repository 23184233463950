<template>
	<div class="swiper" v-if="contactList">
		<img :src="contactList.steamImages[0]" alt="">
		<!-- 轮播图 -->
		<div class="swiperContent">
			<el-carousel :interval="5000" arrow="hover" style="height: 100%;" @change="swiper">
				<el-carousel-item style="height: 100%;" v-for="(item,index) in contactList.steamImages" :key="index">
					 <div v-if="item.indexOf('.mp4') > 0" class='demo'>
						<video-player class="video-player vjs-custom-skin"
							:ref="'videoPlayer'+index"
							:playsinline="true"
							:options="vadioArr[index]"
							@play="onPlayerPlay($event)"
							@pause="onPlayerPause($event)"
							@ended="onPlayerEnded($event)"
							@waiting="onPlayerWaiting($event)"
							@playing="onPlayerPlaying($event)"
							@loadeddata="onPlayerLoadeddata($event)"
							@timeupdate="onPlayerTimeupdate($event)"
							@canplay="onPlayerCanplay($event)"
							@canplaythrough="onPlayerCanplaythrough($event)"
							@statechanged="playerStateChanged($event)"
							@ready="playerReadied"
							>
						</video-player>
					</div>
					<img v-else class="img" style="display: block;height: 100vh;" :src="item">
				</el-carousel-item>
			</el-carousel>
		</div>
		<div class="xxhd" @click="goDown">
			<div>向下滚动</div>
			<img src="../assets/images/xiangxia.png" alt="">
			<!-- <img src="../assets/images/xia.png" alt="" @click="goDown"> -->
		</div>
		<!-- <el-carousel :interval="5000" arrow="never" class="swiper-container">
		    <el-carousel-item v-for="(item,index) in contactList.steamImages" :key="index">
				<img class="img" :src="contactList.steamImages[index]">
		    </el-carousel-item>
		  </el-carousel> -->
		<!-- 向下滑动 -->
		
	</div>
</template>

<script>
	import $ from "jquery"
	export default{
		data () {
			return {
				contactList:[],
				isplay:true,
				myvadio:'',
				vadioArr:[],
			};
		},
		computed:{
			player(){
				this.vadioArr[0].muted = false
				return this.$refs.videoPlayer0.player;
			}
		},
		mounted(){
			this.myvadio = this.$refs.vadio
			this.$nextTick(()=>{
				console.log('加载完成')
			});
		},
		created() {
			this.getContact();
		},
		methods:{
			swiper(i){
				for(let item in this.$refs){
					if(item == 'videoPlayer' + i){
						this.$refs[item][0].player.play()
					}else{
						this.$refs[item][0].player.pause()
					}
				}
			},
			onPlayerPlay(p){
			},
			playVadio(i){
				this.myvadio.play()
			},
			goDown(){
			   // 轮播图滚上上去
			   // document.documentElement.scrollTop = document.querySelector(".el-carousel").clientHeight
			   $("html,body").animate({"scrollTop":"800"},800)
			},
			getContact(){
				this.$http.get("https://admin.ruikefamen.com/api/index/index")
				.then(res=>{
					this.contactList = res.data.data
					console.log(res.data.data.steamImages.length,'轮播列表')
					var swiperList = res.data.data.steamImages
					for(let i=0;i<swiperList.length;i++){
						if(swiperList[i].indexOf('.mp4')>0){
							this.vadioArr.push({
								//播放速度
								_idplay:i,
								playbackRates: [0.5, 1.0, 1.5, 2.0], 
								//如果true,浏览器准备好时开始回放。
								autoplay: true, 
								// 默认情况下将会消除任何音频。
								muted: true, 
								// 导致视频一结束就重新开始。
								loop: true, 
								// 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
								preload: 'auto', 
								language: 'zh-CN',
								// 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
								aspectRatio: '16:9',
								// 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
								fluid: true,
								sources: [{
									//类型
									type: "video/mp4",
									//url地址
									src: swiperList[i]
								}],
								//你的封面地址
								poster: '', 
								//允许覆盖Video.js无法播放媒体源时显示的默认信息。
								notSupportedMessage: '此视频暂无法播放，请稍后再试',
								controlBar: {
									timeDivider: true,
									durationDisplay: true,
									remainingTimeDisplay: false,
									//全屏按钮
									fullscreenToggle: true  
								}
							})
						}else{
							this.vadioArr.push(1)
						}
					}
					console.log(this.vadioArr,'vadio')
				})
			},
			// 播放回调
			onPlayerPlay(player) {
				console.log('player play!', player)
			},
			// 暂停回调
			onPlayerPause(player) {
				console.log('player pause!', player)
			},
			// 视频播完回调
			onPlayerEnded($event) {
			},
			// DOM元素上的readyState更改导致播放停止
			onPlayerWaiting($event) {
			},
			// 已开始播放回调
			onPlayerPlaying($event) {
			},
			// 当播放器在当前播放位置下载数据时触发
			onPlayerLoadeddata($event) {
			},
			// 当前播放位置发生变化时触发。
			onPlayerTimeupdate($event) {
			},
			//媒体的readyState为HAVE_FUTURE_DATA或更高
			onPlayerCanplay(player) {
				// console.log('player Canplay!', player)
			},
			//媒体的readyState为HAVE_ENOUGH_DATA或更高。这意味着可以在不缓冲的情况下播放整个媒体文件。
			onPlayerCanplaythrough(player) {
				// console.log('player Canplaythrough!', player)
			},
			//播放状态改变回调
			playerStateChanged(playerCurrentState) {
				// console.log('player current update state', playerCurrentState)
			},
			//将侦听器绑定到组件的就绪状态。与事件监听器的不同之处在于，如果ready事件已经发生，它将立即触发该函数。。
			playerReadied(player) {
				console.log('example player 1 readied', player);
				for(let item in this.$refs){
					console.log(this.$refs[item][0].player)
				}
			},
		},
		mounted(){
		},
	}
</script>

<style lang="less">
	// 轮播图
	// video::-webkit-media-controls{
    //     display:none !important;
    // }
	.swiperContent{
		.img{
			width: 100%;
			height: 100%;
			background: black;
		}
		.play{
			color: #fff;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
		}
	}
	img{
		width: 100%;
	}
	.el-carousel__container{
		height: 100% !important;
	}
	.swiper{
		min-width: 1450px;
		min-height: 100vh;
		position: relative;
		.xxhd{
			color: #fff;
			font-size: 14px;
			position: absolute;
			bottom: 30px;
			left: 50%;
			transform: translateX(-50%);
			z-index: 9;
			cursor:pointer;
			img{
				width: 16px;
				margin-top: 8px;
			}
		}
		.swiperContent{
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
	.xxhd{
		display: block;
		z-index: 999;
		width: 80px;
		position: absolute;
		margin: 0 auto;
		bottom: 1rem;
		left: 0.2rem;
		text-align: center;
	}
</style>
