<template>
	<div id="advantage">
		<!-- 企业优势 -->
		<div class="company">
			<div style="width: 35.555555rem;margin: 0 auto;">
				<el-row type="flex" justify="center">
					<!-- gutter栅格距离  默认为0 -->
					<div>
						<div class="company-title">
							<span>企业</span>
							<span>优势</span>
							<p style="font-family:fz;color:#666;">COMPANY ADVANTAGES</p>
						</div>
					</div>
				</el-row>
			</div>
			
			<!-- 四个方块 -->
			<div class="company-main wow animate__animated animate__slideInUp animate__delay-0.5s">
				<div class="company-main-box" v-for="(item,index) in goodness" :key="index">
					<div>
						<img :src="goodness[index].image">
					</div>
					<div class="content">
						<p>{{goodness[index].title}}</p>
						<p v-for="(item,index) in goodness[index].tags_text" :key="index">
							<img src="../assets/images/dui.png" alt="">
							<span>{{item}}</span>
						</p>
					</div>
				</div>
			</div>
			
			<div class="company-dsc">
				<div class="company-dsc-l">
					<p>{{res.advantSlogan}}</p>
					<p>咨询热线：<b>{{res.advantHotline}}</b></p>
				</div>
				<div class="company-dsc-r">
					<div class="button" @click="gochanpin" type="button"><img src="../assets/images/pro1.png" >我要买阀门</div>
					<div class="button" @click="gochanpin1" type="button"><img src="../assets/images/anliIcon.png" >我要定制阀门</div>
				</div>
			</div>
			
		</div>
	</div>
</template>

<script>
	import WOW from 'wowjs'
	import $ from 'jquery'
	export default{
		data(){
			return{
				res:[],
				goodness:[],
			}
		},
		mounted() {
			new WOW.WOW().init();
		},
		created() {
			this.getIndex();
		},
		methods:{
			gochanpin(){
				this.$router.push({
					path:'/product',
				})
			},
			gochanpin1(){
				$("html,body").animate({"scrollTop":"5600"},800)
			},
			getIndex(){
				var _this = this
				_this.$apis.get(_this.$apis.index)
				.then(res=>{
				  if(res.data.code == 1){
					  _this.goodness = res.data.data.goodness
					  _this.res = res.data.data
				  }
				})
			}
		}
	}
	
</script>

<style scoped="scoped" lang="less">
	.company{
		min-width: 1400px;
		padding-bottom: 42px;
		margin: 0 auto;
		background: url(../assets/images/qiyebg.png) no-repeat center center / cover;
		.company-title {
			text-align: center;
			padding: 52px 0 45px 0;
			font-size: 38px;
			span:nth-child(1) {
				color: #cc6633;
				font-weight: 900;
			}
			span:nth-child(2) {
				color: #1C5380;
				font-weight: 900;
			}
			p {
				font-size: 24px;
				font-weight: 100;
			}
			
		}
		.company-main{
			width: 1400px;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			.company-main-box{
				width: 335px;
				background-color: #fff;
				div{
					img{
						width: 100%;
						height: 100%;
					}
				}
				.content{
					padding: 5px 19px 19px 20px;
					line-height: 30px;
					p{
						font-size: 15px;
						img{
							width: 17px;
							height: 17px;
							margin-right: 11px;
							vertical-align: middle;
						}
					}
					&>p:first-child{
						font-size: 22px;
						font-weight: 700;
						margin-bottom: 10px;
					}
				}
			}
			.company-main-box:hover{
				transform: scale(1.05);
				transition: 0.5s;
			}
		}
		
		// 下面的标语
		.company-dsc{
			width: 1400px;
			margin: 0 auto;
			margin-top: 43px;
			display: flex;
			justify-content: space-between;
			.company-dsc-l{
				font-size: 30px;
				line-height: 45px;
				p:nth-child(1){
					color: #1c5380;
					font-weight: 900;
				}
				p:nth-child(2),
				p:nth-child(2) b{
					color: #d3642c;
					font-size: 24px;
				}	
			}
			.company-dsc-r{
				width: 673px;
				display: flex;
				justify-content: center;
				&>.button:first-child{
					line-height: 54px;
					border: 3px solid #ffffff;
				}
				.button{
					font-size: 20px;
					height: 60px;
					padding: 0 20px;
					text-align: center;
					line-height: 60px;
					outline-style: none;
					color: #fff;
					cursor: pointer;
					img{
						width: 30px;
						height: 30px;
						margin-right: 10px;
						vertical-align: middle;
					}
				}
				.button:nth-child(1){
					background-color: #d3642c;
				}
				.button:nth-child(2){
					margin-left: 20px;
					background-color: #1c5380;
					border: none;
				}
			}
		}
	}
</style>
