<template>
	<div class="footer">
		<div class="footer-box">
			<div class="footer-box1">
				<ul class="footer-box-l">
					<li>
						<img src="../assets/images/LOGO-bai.png" >
					</li>
					<li>地址：{{contactList.steamAddress}}</li>
					<li>电话：<span style="font-size: 28px;color: #a9a9a9;">{{contactList.steamHotline}}</span></li>
					<li>传真：{{contactList.steamFax}}</li>
					<li>邮编：{{contactList.steamPostcode}}</li>
					<li>网址：{{contactList.steamUrl}}</li>
					<li>邮箱：{{contactList.steamEmail}}</li>
				</ul>
				<div class="footer-box-c">
					<ul class="ul1">
						<li><a href='' style="color: #fff;font-size: 16px;">走进瑞科</a></li>
						<li @click="go(0)"><a >公司简介</a></li> 
						<li @click="go(1)"><a>组织架构</a></li>
						<li @click="go(2)"><a >公司荣誉</a></li> 
						<li @click="go(3)"><a>企业文化</a></li> 
						<li @click="go(4)"><a>新闻动态</a></li> 
					</ul>
					
					<ul class="ul2">
						<li><a style="color: #fff;font-size: 16px;">产品中心</a></li>
						<li v-for="(item,index) in chanpin" :key="index" @click="gochanpin(item.id)"><a>{{item.name}}</a></li>
					</ul>
					
					<ul class="ul3">
						<li><a style="color: #fff;font-size: 16px;">工程案例</a></li>
						<li v-for="(item,index) in anli" :key="index" @click="goanli(item.id)"><a>{{item.name}}</a></li>
					</ul>
				</div>
				<div class="footer-box-r">
					<p><img src="../assets/images/erweima.png" ></p>
					<p style="color: #a9a9a9;">扫一扫关注瑞科阀门</p>
					<div class="linkList">
						<img v-for="(item,i) in link" :key="i" @click="qu(item.url)" :src="item.image" :title="item.title">
					</div>
				</div>
				
			</div>
			<div class="footer-box2">
				<!-- 彩旗 -->
				<div v-for="(item,i) in languageArr" :key="i" @click="qu(item.url)" style="cursor:pointer">
					<img :src="item.image" alt="">
					<span>{{item.title}}</span>
				</div>
			</div>
			<hr width="100%" color="#3a3a3a" style="border-bottom: 1px solid#242424;">
			<div class="footer-box4">
				<!-- 版号 -->
				Copyright © 2011-2021 开封瑞科阀门有限公司  All rights reserved  豫ICP备14017486号-1
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				contactList:[],
				anli:'',
				chanpin:'',
				languageArr:'',
				link:'',
			}
		},
		created() {
			this.getContact();
			this.getData()
			this.getLanguage()
			this.getLink()
		},
		methods:{
			qu(url){
				window.open(url);
			},
			getLanguage(){
				this.$http.get("https://admin.ruikefamen.com/api/index/index_language")
				.then(res=>{
					if(res.data.code == 1){
						this.languageArr = res.data.data
					}
				})
			},
			getLink(){
				this.$http.get("https://admin.ruikefamen.com/api/index/index_share")
				.then(res=>{
					if(res.data.code == 1){
						this.link = res.data.data
					}
				})
			},
			go(i){
				this.$router.push({
					path:'/about',
					query:{
						i:i
					}
				})	
			},
			gochanpin(id){
				this.$router.push({
					path:'/product',
					query:{
						id:id
					}
				})
			},
			goanli(id){
				this.$router.push({
					path:'/case',
					query:{
						id:id
					}
				})
			},
			getContact(){
				this.$http.get("https://admin.ruikefamen.com/api/index/index")
				.then(res=>{
					this.contactList = res.data.data
				})
			},
			getData(){
				this.$http.get("https://admin.ruikefamen.com/api/index/index_data")
				.then(res=>{
					this.anli = res.data.data.case_type
					this.chanpin = res.data.data.category_product
				})
			},
		}
	}
</script>

<style scoped="scoped" lang="less">
.linkList{
	margin-top: 30px;
	img{
		width: 30px;
		margin: 0 1.5px;
		cursor: pointer;
	}
}
.ul2,.ul3{
	li{
		cursor:pointer
	}
}
	.footer{
		width: 100%;
		min-width: 1400px;
		height: 492px;
		background-color: #242424;
		.footer-box{
			width: 1400px;
			height: 100%;
			margin: 0 auto;
			padding: 40px 0;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			.footer-box1{
				font-size: 14px;
				width: 100%;
				display: flex;
				justify-content: space-between;
				ul{
					li{
						margin-bottom: 20px;
						color: #a9a9a9;
						img{
							width: 191px;
						}
					}
				}
			}
			.footer-box2{
				width: 970px;
				padding-bottom: 27px;
				text-align: center;
				display: flex;
				align-items: center;
				justify-content: center;
				div{
					margin: 0 5px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 14px;
					color: #fff;
					img{
						height: 21px;
						width: auto;
						margin-right: 8px;
					}
				}
			}
			.footer-box4{
				color: #666;
				line-height: 60px;
				font-size: 14px;
			}
			.footer-box-l,.footer-box-c,.footer-box-r{
				ul>li{
					cursor:pointer
				}
				ul>li a{
					color: #a9a9a9;
				}
			}
			.footer-box-c{
				padding-top: 20px;
				width: 55%;
				display: flex;
				justify-content: space-evenly;
				ul li:nth-child(1){
					font-size: 0.44978rem;
					
				}
				
			}
			.footer-box-r{
				width: 18%;
				padding-top: 20px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				p{
					margin-top: 10px;
				}
				ul{
					margin-top: 42px;
					li{
						float: left;
					}
				}
			}
		}
	}
	.smallLogo{
		padding-left: 18px;
		img{
			width: 226px !important;
		}
	}
</style>
