<template>
	<div id="free">
		<div class="free">
			<div>
				<div type="flex" justify="center">
					<!-- gutter栅格距离  默认为0 -->
					<div>
						<div class="free-title">
							<span>免费获取</span>
							<span>产品报价</span>
							<p class="fontFZ">FREE PRODUCT QUOTATION</p>
							<p>留下您的联系方式，我们会及时与您联系</p>
						</div>
					</div>
				</div>
			</div>
			<div class="free-table">
				<div class="free-table-one">
					<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
					  <el-form-item prop="name" class="inputDiv">
						  <div class="xingxing">*</div>
					    <el-input v-model="ruleForm.name" placeholder="您的称呼:"></el-input>
					  </el-form-item>
					  
					  <el-form-item prop="phone" class="inputDiv">
						<div class="xingxing">*</div>
					    <el-input v-model="ruleForm.phone" placeholder="您的电话:"></el-input>
					  </el-form-item>
					  
					  <el-form-item prop="email">
					    <el-input v-model="ruleForm.email" placeholder="您的邮箱:"></el-input>
					  </el-form-item>
					  
					  <el-form-item >
					      <el-input type="textarea" v-model="ruleForm.content" placeholder="留言内容" class="resizeNone"></el-input>
					  </el-form-item>
					  
					   <el-form-item class="button" style="text-align: center;width: 100%;">
					      <el-button type="primary" @click="tijiao">立即留言</el-button>
					      <el-button @click="resetForm('ruleForm')">重置信息</el-button>
					  </el-form-item>
					  
					</el-form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		name:'free',
		data(){
			return{
				ruleForm: {
					name: '',
					phone:'',
					content:'',
					email:''
				},
				rules: {
					name: [
						{ required: true, message: '请输入您的称呼', trigger: 'blur' },
						{ min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
				    ],
					phone: [
						{ required: true, message: '请输入联系方式', trigger: 'blur' },
						{ min: 3, max: 11, message: '长度在 3 到 11 个字符', trigger: 'blur' }
				    ],
				}
				}
	
		},
		created() {
			// this.getCommit();
		},
		methods:{
			tijiao(formName) {
				var _this = this;
				// this.$refs[formName].validate((valid) => {
				//   if (valid) {
				// 	  this.getCommit();
				// 	alert("留言成功");
				//   } else {
				// 	this.resetForm();
				// 	return false;
				//   }
				// });
					this.$apis.post(this.$apis.commit,_this.ruleForm).then(res=>{
						if(res.data.code == 1){
							this.$message({
								message: "留言成功",
								type: 'success'
							});
							this.ruleForm={
								name: '',
								phone:'',
								content:'',
								email:''
							}
						}else{
							this.$message({
								message: res.data.msg,
								type: 'warning'
							});
						}
					})
			  },
			resetForm(formName) {
				this.$refs[formName].resetFields();
				this.ruleForm.desc='';
			},
			getCommit(){
				var _this = this;
				_this.$apis.post(_this.$apis.commit,{
					name:_this.ruleForm.name,
					phone:_this.ruleForm.tel,
					email:_this.ruleForm.email,
					content:_this.ruleForm.desc,
				}).then(function(res) {
				  alert("提交成功");
				  if(alert==true){
					this.resetForm();
					_this.ruleForm.name='';
					_this.ruleForm.tel='';
					_this.ruleForm.email='';
					_this.ruleForm.desc='';
				  }
				})
			},
		}
	}
</script>

<style scoped="scoped" lang="less">
/deep/ .el-form-item__error{
	padding-left: 10px;
	color: #333;
}
	#free{
		min-width: 1400px;
		margin: 0 auto;
		background: url(../assets/images/free.png) no-repeat;
		background-size: 100%;
		.free{
			width: 1400px;
			margin: 0 auto;
			padding-bottom: 146px;
			// 企业优势title
			.free-title {
				text-align: center;
				font-size: 38px;
				padding: 60px 65px;
				span:nth-child(1) {
					color: #cc6633;
					font-weight: 900;
				}
				span:nth-child(2) {
					color: #fff;
					font-weight: 900;
				}
				p {
					font-size: 24px;
					font-weight: 100;
				}
				p:nth-child(3){
					color: #999999;
					// font-size: 24px;
				}
				p:nth-child(4){
					color: #fff;
					font-size: 16px;
				}
			}
			.free-table{
				width: 1400px;
				height: 367px;
				background-color: #cc6633;
				margin: 0 auto;
				padding: 25px;
				// 表单
				.free-table-one{
					.el-form{
						// margin: 0;
						.el-form-item{
							float: left;
							margin-right:28px;
							/deep/ .el-input__inner{
								border-radius: 0;
							}
						}
						.el-form-item:nth-child(3){
							margin-right: 0;
						}
						.el-form-item:nth-child(4){
							width: 100%;
							.resizeNone{
								/deep/ .el-textarea__inner{
									font-size: 18px;
									height: 155px;
									padding: 20px;
									resize: none;
									border-radius: 0;
								 }
							}
						}
						/deep/ .el-input__inner{
							width: 431px;
							height: 54px;
							font-size: 18px;
							display: inline-block;
						}	
					}
					
				}
				.button{
					display: flex;
					justify-content: center;
					.el-button{
						width: 170px;
						height: 60px;
						border-radius: 0;
						font-size: 24px;
						font-weight: 100;
					}
					.el-button:nth-child(1){
						background-color: #1c5380;
						border: 3px solid #1c5380;
						/deep/ span{
							color: #fff !important;
						}
					}
					.el-button:nth-child(2){
						background-color: #cc6633;
						border: 3px solid #fff;
						margin-left: 51px;
						/deep/ span{
							color: #fff !important;
						}
					}
				}
			}
		}
	}
		/deep/input::-webkit-input-placeholder {
			color: #999;
			font-size: 18px;
		}

		/deep/input::-moz-input-placeholder {
			color: #999;
			font-size: 18px;
		}

		/deep/input::-ms-input-placeholder {
			color: #999;
			font-size: 18px;
		}
		.inputDiv{
			position: relative;
			.xingxing{
				color: #CC6633;
				position: absolute;
				left: 17px;
				top: 50%;
				transform: translateY(-50%);
				z-index: 999;
			}
			/deep/input{
				padding: 0 28px;
			}
		}
		.fontFZ{
			font-family: fz;
			color: #999999 !important;
		}
</style>
