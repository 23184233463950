<template>
	<div id="app">
		<router-link class="col" to="/"></router-link>
		
		<!-- 路由占位符 -->
		<router-view></router-view>
	</div>
	
</template>

<script>
import $ from 'jquery'
	// 屏幕自适应
	(function(doc, win) {
	    setRem();
	    var resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
	        recalc = function() {
	            setRem();
	        };
	    if (!doc.addEventListener)
	        return;
	    win.addEventListener(resizeEvt, recalc, false);
	    // doc.addEventListener('DOMContentLoaded', recalc, false);
        // $.get("", function(result){
        //     $("div").html(result);
        // });
	})(document, window);
	 
	function setRem() {
	    var docEl = document.documentElement;
	    var clientWidth = docEl.clientWidth;
	    if (!clientWidth) {
	        return;
	    }
	    docEl.style.fontSize = 50 * (clientWidth / 1920) + 'px';
	}
	
	
	var sUserAgent=navigator.userAgent;
	var mobileAgents=['Android','iPhone','Symbian','WindowsPhone','iPod','BlackBerry','Windows CE'];
	var goUrl = 0;
	for( var i=0;i<mobileAgents.length;i++){
		if(sUserAgent.indexOf(mobileAgents[i]) > -1){
			goUrl = 1;
			break;
		}
	}
	if (goUrl == 1){
		location ='https://m.ruikefamen.com';
	}
export default {
	name:'app',
	data(){
		return {
			index:'',
		}
	},
	mounted(){
		this.$http.get("https://admin.ruikefamen.com/api/index/index").then(res=>{
			if(res.data.code == 1){
				this.index = res.data.data
				// steamLogo
				document.title = res.data.data.site_name
				console.log(res.data.data.wapIcon,res.data.data.site_name)
				$('head').append("<meta name='keywords' content="+res.data.data.keyword+">");
				$('head').append("<link rel='icon' href="+res.data.data.wapIcon+">");
			}
		})
	}
}
</script>

<style>
	*{margin: 0;padding: 0;box-sizing: border-box;}
	ul{list-style: none;}
	a{text-decoration: none;}
	@font-face {
		font-family: fz;
		src: url("./assets/fonts/fz_GBK.TTF");
	}
	@font-face {
		font-family: tccm;
		src: url("./assets/fonts/TCCM.TTF");
	}
	@font-face {
		font-family: ms;
		src: url("./assets/fonts/ms1.ttf");
	}
	@font-face {
		font-family: ar;
		src: url("./assets/fonts/ms.ttf");
	}
	@font-face {
		font-family: arr;
		src: url("./assets/fonts/ARIAL.TTF");
	}
	.el-dialog{
		background: none !important;
		box-shadow: none !important;
	}	
	.el-dialog__close{
		font-size: 30px;
		color: #fff !important;
	}
	.mouse-cover-canvas{
		top: 595px !important;
		left: 800px !important;
	}
</style>
